/* eslint func-names: 'off' */
/* eslint global-require: 'off' */
import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { AppStatus } from './AppStatus'
import { Session } from './Session'

import { Settings } from '@/app'

const allReducers = combineReducers({
  AppStatus,
  Session,
})

const reducers = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {}
  }
  return allReducers(state, action)
}

const middleware = __DEV__ && Settings.WARN_ILLEGAL_STATE_MUTATIONS ?
  [require('redux-immutable-state-invariant').default(), thunk] :
  [thunk]

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)))

export default store
