import { React, Text, View, Image, Icon } from '@/lib'
import 'react-multi-carousel/lib/styles.css'
import ItemsSlider from '@/components/ItemsSlider'
import { useCallback } from 'react'
import { Theme } from '@/app'
import ReactStars from 'react-stars'
import { Assets } from '@/assets'

export type ReviewsItem = {
  background: string
  items: {
    stars: number
    title: string
    description: string
    author: string
  }[]
}

type ReviewsProps = {
  data: ReviewsItem
}

const Reviews = (props: ReviewsProps) => {

  if (!props.data) return null

  const Avatar = () => {
    return (
      <View style={styles.avatar}>
        <Icon path={Assets.Icons.User} />
      </View>
    )
  }

  const renderItem = useCallback((item) => {
    return (
      <View style={styles.cardWrapper}>
        <View variant={'row marginBottom:3 fullWidth justifySpaceBetween'}>
          <Avatar />
          <Text variant={`p1 neutral4`} text={item.author}/>
        </View>
        <ReactStars
          count={item.stars}
          edit={false}
          color1={'#FFAA46'}
          size={24}
        />
        <Text variant={`h5 bold white marginTop:2 marginBottom:2`} text={item.title} />
        <Text variant={`p1 neutral4`} text={item.description} />
      </View>
    )
  }, [])

  return (
    <View style={styles.wrapper}>
      <Image source={props?.data?.background} style={styles.image}/>
      <View style={styles.contentWrapper}>
        <Text variant={`h2 white`} style={styles.title} text={'Reviews'}/>
        <ItemsSlider data={props?.data?.items} renderItem={renderItem} />
      </View>
    </View>
  )
}

export default Reviews

const styles = {
  wrapper: {
    width: '100%',
    height: '110vh',
    position: 'relative',
    [Theme.media.down('mid')]: {
      height: '80vh',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  contentWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: Theme.spacing(3),
  },
  cardWrapper: {
    borderRadius: Theme.values.borderRadius.small,
    marginRight: 0,
    padding: Theme.spacing(5),
    backgroundColor: '#1F1F1F',
  },
  avatar: {
    borderRadius: Theme.values.borderRadius.smallish,
    backgroundColor: Theme.colors.graniteGrey,
    maxWidth: 40,
    height: 40,
    ...Theme.flex,
    ...Theme.center,
  },
}
