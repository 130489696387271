import { React, View } from '@/lib'
import { Theme } from '@/app'

export const BaseWrapper = (props) => {
  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        {props.children}
      </View>
    </View>
  )
}

export const styles = {
  wrapper: {
    backgroundColor: Theme.colors.black,
  },
  contentWrapper: {
    marginBottom: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
}
