/* eslint-disable no-confusing-arrow */
import Img from 'gatsby-image'
import { React, View, Text, Touchable } from '@/lib'
import { useEffect } from '@codeleap/common'
import { Tilt } from '@jdion/tilt-react'
import { Theme, TextStyles } from '@/app'
import authorMeta from './authorMeta'

export const BlogItem = (props) => {
  let isBrowser = false // prevents gatsby from trying to build with inexisting stuff
  const isMobile = Theme.hooks.down('mid')
  useEffect(() => {
    isBrowser = true
  }, [])
  const light = props.light
  const meta = props.node.frontmatter
  const excerpt = props.node.excerpt
  const author = authorMeta[meta.author]
  const titleElementId = `blogTitle_${meta.title}`
  const height =
    isBrowser &&
    document.getElementById(titleElementId) &&
    document.getElementById(titleElementId).offsetHeight
  const titleHeightLines = height ? height / 42 : 1 // 42 (or forty-two), is the Answer to the Ultimate Question of Life, the Universe and Everything.
  const maxLen = 270 - 70 * titleHeightLines
  const truncate = (input) => input.length > maxLen ? `${input.substring(0, maxLen)}...` : input
  const preview = truncate(excerpt)
  const CardWrapper = isMobile ? View : Tilt
  const cardStyles = props.slider
    ? styles.sliderCard
    : light
      ? styles.lightCard
      : styles.tiltCard
  return (
    <View style={styles.cardWrapper}>
      <CardWrapper
        style={cardStyles}
        options={{ scale: 1.05, max: 6, speed: 2000 }}
      >
        <Touchable
          gaLabel={'Blog'}
          style={[styles.clickable, styles.stretch]}
          to={`/${props.node.fields.slug}`}
        >
          <Img
            fluid={meta.image.childImageSharp.fluid}
            style={styles.image}
            alt={meta.title}
          />
          <View style={[styles.content, styles.contentTop]}>
            <Text
              variant={`h4 bold ${!light ? 'lightish' : ''}`}
              text={meta.title}
            />
            <Text
              variant={`p1 ${!light ? 'lightGrey' : ''}`}
              style={styles.previewSubText}
              text={preview}
            />
          </View>
          <View style={[styles.content, styles.contentBottom]}>
            <View variant={'row'}>
              <Text variant={'p2 primary uppercase'} text={'Read more'}/>
              <Arrow/>
            </View>
          </View>
        </Touchable>
      </CardWrapper>
    </View>
  )
}

const Arrow = () => {
  return (
    <View style={styles.arrowWrapper}>
      <View style={[styles.arrowLine, styles.arrowHorizontal]}></View>
      <View style={[styles.arrowLine, styles.arrowTop]}></View>
      <View style={[styles.arrowLine, styles.arrowBottom]}></View>
    </View>
  )
}

const styles = {
  cardWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
  },
  stretch: {
    height: '100%',
  },
  tiltCard: {
    flex: 1,
    display: 'flex',
    backgroundColor: Theme.colors.darkest,
    borderRadius: Theme.values.borderRadius.small,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  lightCard: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'white',
    ...Theme.elevated,
    borderRadius: Theme.values.borderRadius.small,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  sliderCard: {
    flex: 1,
    display: 'flex',
    backgroundColor: Theme.colors.darkest,
    borderRadius: Theme.values.borderRadius.small,
    overflow: 'hidden',
    cursor: 'pointer',
    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(2),
      minHeight: 450,
    },
  },
  metaRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: Theme.spacing(1),
    marginBottom: Theme.spacing(1),
    alignItems: 'center',
  },
  previewTitleText: {
    fontSize: 28,
    fontWeight: 700,
    background: Theme.colors.gradient,
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    color: 'transparent',
  },
  image: {
    height: 160,
  },
  clickable: {
    flex: 1,
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentTop: {
    maxWidth: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: Theme.spacing(4),
    [Theme.media.down('xlarge')]: {
      padding: Theme.spacing(3),
    },
    [Theme.media.down('large')]: {
      padding: Theme.spacing(2),
    },
    paddingBottom: 0,
  },
  contentBottom: {
    justifyContent: 'flex-end',
    flex: 1,
    display: 'flex',
    marginBottom: Theme.spacing(4),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(0),
    },
  },
  previewSubText: {
    marginTop: Theme.spacing(2),
    [Theme.media.down('large')]: {
      marginTop: Theme.spacing(1),
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    flex: 1.0,
  },
  arrowWrapper: {
    display: 'block',
    transform: 'scale(0.4)',
    marginLeft: -4,
    marginBottom: 3,
  },
  arrowLine: {
    background: Theme.colors.primary,
    position: 'relative',
    borderRadius: Theme.values.borderRadius.tiny,
  },
  arrowHorizontal: {
    left: 0,
    height: 5,
    width: 60,
    top: 'calc(50% - 2px)',
  },
  arrowTop: {
    background: Theme.colors.primarySolid,
    height: 5,
    width: 22,
    left: 42,
    transform: 'rotate(45deg)',
    top: 'calc(50% - 14px)',
  },
  arrowBottom: {
    top: 'calc(50% - 6px)',
    left: 42,
    transform: 'rotate(-45deg)',
    height: 5,
    width: 22,
  },
  authorMetaRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Theme.spacing(2),
  },
  profileImage: {
    marginRight: Theme.spacing(1),
  },
  previewInfoText: {
    fontSize: 14,
    color: '#777',
  },
  backgroundImage: {
    width: '100vw',
    height: 400,
    objectFit: 'cover',
  },
  profileText: {
    ...TextStyles.text.default,
    ...TextStyles.compatibility.h6,
    ...TextStyles.text.h6,
    marginBottom: Theme.spacing(0.5),
    flexDirection: 'column',
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: Theme.values.borderRadius.round,
    marginRight: Theme.spacing(2),
  },
}
