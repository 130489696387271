import {
  View,
  React,
} from '@/lib'
import { ShowcaseProps } from '.'
import { MobileComponents, PortfolioCards } from '@/components'

const NextGenShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <PortfolioCards.Wrapper>
      <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
      <PortfolioCards.BeconMobile onSelect={() => setSelected('becon-mobile')}/>
      <PortfolioCards.AWSCloudroom onSelect={() => setSelected('cloudroom-web')}/>
      <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
    </PortfolioCards.Wrapper>
  )
}
export default NextGenShowcase
