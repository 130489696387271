import { Assets } from '@/assets'

export default {
  Victor: {
    image: Assets.Team.Victor[3],
    name: 'Victor Gimael',
  },
  Vivian: {
    image: Assets.Team.Vivian[3],
    name: 'Vivian Rothberg',
  },
}
