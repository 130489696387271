import {
  React,
  View,
  CenterWrapper,
  Image,
  AnalyticsView,
} from '@/lib'
import { Theme } from '@/app'
import tweenProps from '@/components/Reveal/tweenProps'
import { DemoDevice, Reveal } from '@/components'
import { Tools } from '@/lib'
import { styles } from './styles'

export const AppRow = (props) => {

  const { elemName, children, background, wrapperStyle } = props

  const { width, height } = Theme.hooks.size()
  const limitedPerformance = Tools.useLimitedPerformance()
  const demoHeight = DemoDevice.getDemoImageScale(width) * DemoDevice.height
  const appRowStyles = { minHeight: demoHeight }

  return (
    <>
      <span id={elemName}/>
      <CenterWrapper style={styles.appRowCenterWrapper}>
        <View style={[styles.backgroundImageWrapper, wrapperStyle]}>
          <Reveal
            variant={'fixedBackground'}
            offset={height * -0.125}
            duration={height * 1.05}
            disabled={limitedPerformance > 0}
            fixedBackgroundOpacity={tweenProps.fixedBackgroundFullOpacity}
          >
            <View style={styles.imageInnerWrapper} className='willChange'>
              <Image
                alt={elemName}
                loading={'eager'}
                style={[styles.backgroundImage]}
                objectFit={'cover'}
                source={background}
                debug={props.debug}
              />
            </View>
          </Reveal>
        </View>
        <AnalyticsView
          gaLabel={'Portfolio'}
          gaAction={elemName}
          style={[
            styles.appRow,
            props.position == 'left' && styles.appRowLeft,
            appRowStyles,
          ]}
        >
          {children}
        </AnalyticsView>
      </CenterWrapper>
    </>
  )
}
