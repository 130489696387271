import ReactGA from 'react-ga'
import moment from 'moment'
import { faker } from '@faker-js/faker'
import { Session } from '@/actions'
import { Cookies, Logger } from '@/lib'
import ABTestManager from './ABTestManager'

const debug = false
const showLogs = true

function init(analyticsId, testMode = false, skipPost = false) {
  const initOptions = {
    testMode,
    debug,
  }
  ReactGA.initialize(analyticsId, initOptions)
  ABTestManager.init()
  const [userAnalyticsId, returningUser] = getUserAnalyticsId()
  if (showLogs) Logger.logger.purple('Analytics', { analyticsId, userAnalyticsId, initOptions })
  ReactGA.set({ userAnalyticsId })
  Session.setUserAnalyticsId(userAnalyticsId, returningUser, skipPost)
}

const cap = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function getCurrentUrl() {
  return window && window.location.href
}

function getUserAnalyticsId() {
  const storedUserAnalyticsId = Cookies.get('Session.userAnalyticsId')
  if (storedUserAnalyticsId) {
    return [storedUserAnalyticsId, true]
  } else {
    const dateStr = moment().format('YYYYMMDDHHmmss')
    const color = faker.color.human()
    const animalType = faker.animal.type()
    const animalName = faker.animal[animalType]()
    const userAnalyticsid = `${cap(color)}_${cap(animalName).replace(
      / /g,
      '_',
    )}_${cap(animalType)}_${dateStr}`
    Cookies.set('Session.userAnalyticsId', userAnalyticsid)
    Cookies.set('Session.firstSeen', new Date())
    Cookies.set('Session.firstUrl', getCurrentUrl())
    return [userAnalyticsid, false]
  }
}

function event(props) {
  const { category, action, label, nonInteraction = false } = props

  const params = {
    category,
    action,
    label,
    nonInteraction,
  }

  if (props.value) {
    params.value = props.value
  }

  if (showLogs) Logger.logger.purple('Analytics', params)

  if (!category) Logger.logger.red('Missing category on analytics!')
  if (!label) Logger.logger.red('Missing label on analytics!')
  if (!action) Logger.logger.red('Missing action on analytics!')

  ReactGA.event(params)
}

function click(props) {
  const { action, label } = props
  const params = {
    category: 'Click',
    action,
    label,
  }
  event(params)
}

function hover(props) {
  const { action, label, value } = props
  const params = {
    category: 'Hover',
    action,
    label,
    value,
  }
  event(params)
}

function swipe(props) {
  const { action, label } = props
  const params = {
    category: 'Swipe',
    action,
    label,
  }
  event(params)
}

function enterViewport(props) {
  const { action, label } = props
  const params = {
    category: 'Enter viewport',
    action,
    label,
    nonInteraction: true,
  }
  event(params)
}

function formInput(props) {
  const { action, label } = props
  const params = {
    category: 'Form input',
    action,
    label,
  }
  event(params)
}

function sectionView(props) {
  const { action, label, value } = props
  const params = {
    category: 'View element',
    action,
    label,
    value,
    nonInteraction: true,
  }
  event(params)
}

function pageView(path) {
  if (showLogs) Logger.logger.purple('Analytics', { path })
  ReactGA.pageview(path)
}

export const Analytics = {
  init,
  event,
  click,
  hover,
  swipe,
  enterViewport,
  sectionView,
  formInput,
  pageView,
}

export default Analytics
