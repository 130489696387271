/* eslint-disable */
const IS_DEVELOPMENT = process.env.NODE_ENV !== 'production'
global.__DEV__ = IS_DEVELOPMENT
const IS_BROWSER = typeof window !== 'undefined'


const LOG_LEVEL = 'log' // 'log' (default), 'info' (shows everything) or 'warn' (only shows warnings)
const REMOTE_DEBUGGER = (typeof atob !== 'undefined') // true if remote debugger is available so the logger knows what to do
const WARN_ILLEGAL_STATE_MUTATIONS = false // can have a performance impact, enable only when necessary - automatically disabled in production
const WARN_WHY_DID_YOU_RENDER = false // can have a performance impact, enable only when necessary - automatically disabled in production
const DEBUG_PERFORMANCE = false // can have a performance impact, enable only when necessary - automatically disabled in production

const IS_BETA = IS_BROWSER ? window.location.href.includes('beta') : true
const PROD_BASE_URL = 'https://vqeczjvr0j.execute-api.eu-west-2.amazonaws.com/production/api/'
const DEV_BASE_URL = 'https://msg3twi6xj.execute-api.eu-west-2.amazonaws.com/dev/api/'
const BASE_URL = IS_DEVELOPMENT || IS_BETA  ? DEV_BASE_URL : PROD_BASE_URL

const NOTION_PUBLIC_WIKI = 'https://codeleap.notion.site/Public-Wiki-3c1f41f06f93437a889b2a4b18ca34e0'
const FACEBOOK_URL = 'https://www.facebook.com/codeleapuk/'
const LINKEDIN_URL = 'https://www.linkedin.com/company/codeleap-uk'
const CONTACT_EMAIL = 'hello@codeleap.co.uk'
const SUPPORT_EMAIL = 'support@codeleap.co.uk'
const CONTACT_PHONE = '+44 (0) 333 050 9420'

const GOOGLE_ANALYTICS_ID = 'UA-139196834-1'

const UK_NDA_TEMPLATE = 'https://www.gov.uk/government/publications/non-disclosure-agreements'

export default {
  LOG_LEVEL,
  REMOTE_DEBUGGER,
  WARN_ILLEGAL_STATE_MUTATIONS,
  WARN_WHY_DID_YOU_RENDER,
  CONTACT_EMAIL,
  SUPPORT_EMAIL,
  CONTACT_PHONE,
  BASE_URL,
  IS_DEVELOPMENT,
  IS_BROWSER,
  GOOGLE_ANALYTICS_ID,
  DEBUG_PERFORMANCE,
  FACEBOOK_URL,
  LINKEDIN_URL,
  NOTION_PUBLIC_WIKI,
  UK_NDA_TEMPLATE
}
