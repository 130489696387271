import { React, View, Touchable } from '@/lib'
import { useState, useInterval } from '@codeleap/common'
import { IoIosArrowDown } from 'react-icons/io'

const BOUNCE_INTERVAL = 1500

type ScrollDownProps = {
  color?: string
  gaLabel?: string
  href?: string
}

export const ScrollDown = (props: ScrollDownProps) => {

  const [up, setUp] = useState(true)
  const [visible, setVisible] = useState(false)

  useInterval(() => {
    setUp(!up)
    if (!visible) setVisible(true)
  }, BOUNCE_INTERVAL)

  const color = props.hasOwnProperty('color') ? props.color : 'white'

  return (
    <View style={[styles.wrapper, visible && styles.wrapperVisible]}>
      <Touchable gaLabel={props.gaLabel} href={props.href}>
        <View style={[styles.innerWrapper, up && styles.innerWrapperUp]} pointerEvents={'none'}>
          <IoIosArrowDown
            color={color}
            size={32}
          />
        </View>
      </Touchable>
    </View>
  )
}

export default ScrollDown

const styles = {
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    opacity: 0,
  },
  wrapperVisible: {
    opacity: 1,
    transition: `${BOUNCE_INTERVAL}ms`,
  },
  innerWrapper: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transform: 'translate3d(0, -8px, 0)',
    transition: `${BOUNCE_INTERVAL}ms`,
  },
  innerWrapperUp: {
    transform: 'translate3d(0, 8px, 0)',
  },
  text: {
    fontSize: 16,
  },
}
