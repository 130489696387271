import { React, Page } from '@/lib'
import Landing, { LandingItem } from './Landing'
import Challenge, { ChallengeItem } from './Challenge'
import Solution, { SolutionItem } from './Solution'
import Testimonial, { TestimonialItem } from './Testimonial'
import Reviews, { ReviewsItem } from './Reviews'
import Features, { FeatureItem } from './Features'
import TechStack, { TechStackItem } from './TechStack'
import Others, { OthersItem } from './Others'
import { Banner, BannerItem } from './Banner'
import { ContactUs } from '@/scenes'

export type CaseStudyItem = {
  landing: LandingItem
  challenge: ChallengeItem
  solution: SolutionItem
  testimonial: TestimonialItem
  reviews: ReviewsItem
  features: FeatureItem
  banner: BannerItem
  'tech-stack': TechStackItem
  others: OthersItem
}

type CaseStudyProps = {
  data: CaseStudyItem
}

export const CaseStudy = (props: CaseStudyProps) => {
  return (
    <Page centerWrapperProps={{ centerContent: false }}>
      <Landing data={props?.data?.landing} />
      <Features data={props?.data?.features}/>
      <Challenge data={props?.data?.challenge} />
      <Solution data={props?.data?.solution}/>
      <Reviews data={props?.data?.reviews}/>
      <TechStack data={props.data?.['tech-stack']}/>
      <Banner data={props?.data?.banner} />
      <Others data={props?.data?.others}/>
      <Testimonial data={props?.data?.testimonial}/>
      <ContactUs/>
    </Page>
  )
}

export default CaseStudy
