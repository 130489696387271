import { Theme } from '@/app'
import { Device } from '@/components/Device'
import { apps, DemoDevice } from '@/components/Portfolio'
import Reveal from '@/components/Reveal'
import { React, View } from '@/lib'

type MobileDisplayProps = {
    devices: string[]
    app: keyof typeof apps
}

export const MobileDisplay = (props: MobileDisplayProps) => {

  const isMobile = Theme.hooks.down('mid')

  const { height } = Theme.hooks.size()

  if (isMobile) {
    const data = apps[props?.app]
    return <DemoDevice.DemoRow data={data} />
  }

  return (
    <View style={{ width: '100%', height: '100%', position: 'relative' }}>

      <View style={styles.firstDeviceWrapper}>
        <Reveal
          variant={'parallaxBackground'}
          amount={'10vh'}
          offset={height * - 0.15}
          duration={height * 1.1}
        >
          <div className='willChangeTransform'>
            <Device device={props?.devices[1]} scale={0.5}/>
          </div>
        </Reveal>
      </View>

      <View style={styles.secondDeviceWrapper}>
        <Device device={props?.devices[0]} scale={0.6}/>
      </View>

    </View>
  )
}

const styles = {
  firstDeviceWrapper: {
    position: 'absolute',
    top: '50%',
    left: '30%',
    transform: 'translate(-30%, -50%)',
    width: DemoDevice.width * 0.5,
    height: DemoDevice.height * 0.5,
    [Theme.media.down('xxlarge')]: {
      left: '20%',
      transform: 'translate(-20%, -50%)',
    },
    [Theme.media.down('xlarge')]: {
      left: '10%',
      transform: 'translate(-10%, -50%)',
    },
    [Theme.media.down('large')]: {
      left: '5%',
      transform: 'translate(-5%, -50%)',
    },
  },
  secondDeviceWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: DemoDevice.width * 0.6,
    height: DemoDevice.height * 0.6,
  },
}
