import { Theme } from '../Theme'

const wrapper = {
  default: {
    maxWidth: 64,
    maxHeight: 64,
    minWidth: 64,
    minHeight: 64,
    backgroundColor: '#E8E8E8',
    borderRadius: Theme.values.borderRadius.small,
    marginBottom: Theme.spacing(4),
    ...Theme.flex,
    ...Theme.center,
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  black: {
    maxWidth: 64,
    maxHeight: 64,
    minWidth: 64,
    minHeight: 64,
    backgroundColor: '#262635',
    borderRadius: Theme.values.borderRadius.small,
    marginBottom: Theme.spacing(4),
    ...Theme.flex,
    ...Theme.center,
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  'the-frida-project-orange': {
    maxWidth: 64,
    maxHeight: 64,
    minWidth: 64,
    minHeight: 64,
    backgroundColor: '#EE7F39',
    borderRadius: Theme.values.borderRadius.small,
    marginBottom: Theme.spacing(4),
    ...Theme.flex,
    ...Theme.center,
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  'cloudroom-sky': {
    maxWidth: 64,
    maxHeight: 64,
    minWidth: 64,
    minHeight: 64,
    backgroundColor: '#183177',
    borderRadius: Theme.values.borderRadius.small,
    marginBottom: Theme.spacing(4),
    ...Theme.flex,
    ...Theme.center,
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
}

const icon = {
  default: {
    color: 'white',
  },
  black: {
    color: '#FFF',
  },
}

export default {
  wrapper,
  icon,
}
