import { View, Text, CenterWrapper, React, Image, Link, Touchable, AnalyticsView } from '@/lib'
import { Settings, Theme } from '@/app'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { Assets } from '@/assets'
import moment from 'moment'

const linksA = [
  { text: 'HOME', to: '/#' },
  { text: 'SERVICES', to: '/#services' },
  { text: 'PORTFOLIO', to: '/#portfolio' },
  { text: 'PRICING', to: '/#pricing' },
]

const linksB = [
  { text: 'ABOUT', to: '/about' },
  { text: 'BLOG', to: '/blog' },
  { text: 'CAREERS', to: '/careers' },
  { text: 'CONTACT', to: '/#contact' },
]

const renderLinkCol = (arr) => {
  const links = arr.map(({ to, text }, index) => {
    return <Link gaLabel={'Footer'} key={index} style={[styles.footerNavText, styles.link]} to={to} text={text}/>
  })
  return (
    <View style={styles.linkCol}>
      {links}
    </View>
  )
}

const Menu = () => {
  return (
    <View>
      <View style={styles.footerLinkWrapper}>
        {renderLinkCol(linksA)}
        {renderLinkCol(linksB)}
      </View>
    </View>
  )
}

const Disclaimers = () => {
  return (
    <View style={styles.disclaimersWrapper}>
      <Text style={styles.regDetailsText}>
        Visit us in Camden, London, UK.
      </Text>
      <Text style={styles.regDetailsText}>
        CodeLeap Ltd. is registered in England&nbsp;&&nbsp;Wales, company&nbsp;no.&nbsp;11967804.
      </Text>
      <Text variant={'inline'} style={styles.regDetailsText}>
        This website uses&thinsp;<Link openNewTab style={styles.link} gaLabel={'Footer'} to='/terms/licence'>third-party assets and software</Link>.
        By using our website you agree to the&thinsp;<Link openNewTab style={styles.link} gaLabel={'Footer'} to='/terms/website'>terms of use</Link>.
      </Text>
    </View>
  )
}

const Contact = () => {
  const year = moment().format('YYYY')
  return (
    <View style={styles.contactWrapper}>
      <Link
        gaLabel={'Footer'}
        gaAction={'codeleap contact email'}
        text={Settings.CONTACT_EMAIL}
        href={`mailto:${Settings.CONTACT_EMAIL}`}
        style={[styles.footerNavText, styles.link]}
      />
      <Link
        gaLabel={'Footer'}
        gaAction={'codeleap contact phone'}
        text={Settings.CONTACT_PHONE}
        href={`tel:${Settings.CONTACT_PHONE}`}
        style={[styles.footerNavText, styles.link]}
      />
      <Text style={styles.footerNavText} text={'Made with ♥️ in London, UK'}/>
      <Text style={styles.footerNavText} text={`©${year} CodeLeap Ltd.`}/>
    </View>
  )
}

const SocialMedia = () => {
  return (
    <View style={styles.socialMediaWrapper}>
      <Touchable gaLabel={'Footer'} style={styles.socialMediaIconWrapper} to={Settings.FACEBOOK_URL}>
        <FaFacebookSquare size={28} color={'white'}/>
      </Touchable>
      <Touchable gaLabel={'Footer'} style={styles.socialMediaIconWrapper} to={Settings.LINKEDIN_URL}>
        <FaLinkedin size={28} color={'white'}/>
      </Touchable>
    </View>
  )
}

export const Footer = () => {
  return (
    <View style={styles.wrapper}>
      <CenterWrapper>
        <AnalyticsView gaLabel={'Footer'} style={styles.innerWrapper} reportOnEnter>
          <Image src={Assets.Logos.CodeleapLogoWhite} alt='CodeLeap' style={styles.logoImage}/>
          <View up={'mid'}>
            <View style={styles.innerWrapperRow}>
              <Menu/>
              <Contact/>
            </View>
            <View style={styles.separator}></View>
            <View style={styles.bottomRow}>
              <Disclaimers/>
              <SocialMedia/>
            </View>
          </View>
          <View down={'mid'} style={styles.innerWrapperColumn}>
            <Menu/>
            <View style={styles.separator}></View>
            <Contact/>
            <SocialMedia/>
            <Disclaimers/>
          </View>
        </AnalyticsView>
      </CenterWrapper>
    </View>
  )
}

const styles = {
  wrapper: {
    zIndex: 10,
    display: 'flex',
    color: 'white',
    minHeight: Theme.values.footerHeight,
    background: Theme.colors.primary,
    [Theme.media.down('small')]: {
      paddingTop: Theme.spacing(4),
      paddingBottom: Theme.spacing(4),
    },
  },
  footerLinkWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    [Theme.media.down('mid')]: {
      ...Theme.center,
      ...Theme.flex,
    },
  },
  linkCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginRight: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      ...Theme.center,
      ...Theme.flex,
      marginRight: Theme.spacing(2),
      marginLeft: Theme.spacing(2),
    },
  },
  link: {
    fontWeight: 'inherit',
    textDecorationLine: 'none',
    textDecorationColor: '#ffffff !important',
    '&:hover': {
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      opacity: 1,
    },
  },
  innerWrapper: {
    paddingVertical: Theme.spacing(4),
    flex: 1,
    [Theme.media.down('mid')]: {
      ...Theme.flex,
      ...Theme.center,
      flexDirection: 'column',
    },
  },
  innerWrapperRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
  },
  innerWrapperColumn: {
    ...Theme.flex,
    ...Theme.center,
    flexDirection: 'column',
  },
  center: {
    ...Theme.center,
  },
  right: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  text: {
    fontSize: 16,
  },
  regDetailsText: {
    color: '#fff',
    opacity: 0.7,
    fontSize: 14,
    marginTop: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      textAlign: 'center',
    },
  },
  inherit: {
    margin: 'inherit',
  },
  footerNavText: {
    marginTop: Theme.spacing(1),
    marginBottom: Theme.spacing(1),
    color: 'white',
  },
  logoImage: {
    width: 150,
    marginBottom: Theme.spacing(3),
  },
  mobileDisclaimers: {
    flex: 1,
    marginBottom: Theme.spacing(2),
  },
  contactWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    [Theme.media.down('mid')]: {
      ...Theme.center,
    },
  },
  disclaimersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    [Theme.media.down('mid')]: {
      ...Theme.center,
    },
  },
  separator: {
    backgroundColor: 'white',
    height: 1,
    marginTop: Theme.spacing(3),
    marginBottom: Theme.spacing(1),
    width: '100%',
  },
  bottomRow: {
    ...Theme.row,
    justifyContent: 'space-between',
  },
  socialMediaWrapper: {
    marginTop: Theme.spacing(2),
  },
  socialMediaIconWrapper: {
    marginLeft: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(1),
      marginRight: Theme.spacing(1),
    },
  },
}

export default Footer
