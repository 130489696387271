const BASE_URL = 'assets/portfolio'

const Skamper = {
  background: `${BASE_URL}/skamper/skamper-background.jpg`,
  logo: `${BASE_URL}/skamper/skamper-logo.png`,
  mobile: {
    1: `${BASE_URL}/skamper/mobile/skamper1.png`,
    2: `${BASE_URL}/skamper/mobile/skamper2.png`,
    3: `${BASE_URL}/skamper/mobile/skamper3.png`,
  },
}

const Bearable = {
  1: `${BASE_URL}/bearable/bearable1.jpg`,
  2: `${BASE_URL}/bearable/bearable2.jpg`,
  3: `${BASE_URL}/bearable/bearable3.jpg`,
  4: `${BASE_URL}/bearable/bearable4.png`,
  background: `${BASE_URL}/bearable/bearable-background.jpg`,
  'logo-1': `${BASE_URL}/bearable/logo-1.png`,
  'logo-2': `${BASE_URL}/bearable/logo-2.png`,
}

const Autoimaging = {
  square: `${BASE_URL}/autoimaging/background-square.png`,
  tablet: `${BASE_URL}/autoimaging/background-tablet.png`,
  background: `${BASE_URL}/autoimaging/background.png`,
  mobile: `${BASE_URL}/autoimaging/mobile.png`,
  logo: `${BASE_URL}/autoimaging/logo.png`,
}

const AWSCloudroom = {
  1: `${BASE_URL}/aws-cloudroom/device-1.png`,
  2: `${BASE_URL}/aws-cloudroom/device-2.png`,
  3: `${BASE_URL}/aws-cloudroom/device-3.png`,
  mockup: `${BASE_URL}/aws-cloudroom/mockup.png`,
  background: `${BASE_URL}/aws-cloudroom/aws-background.jpg`,
  'background-tablet': `${BASE_URL}/aws-cloudroom/background-tablet.png`,
}

const AWSGirlsTechSeries = {
  1: `${BASE_URL}/aws-girls-tech-series/device-1.png`,
  2: `${BASE_URL}/aws-girls-tech-series/device-2.png`,
  3: `${BASE_URL}/aws-girls-tech-series/device-3.png`,
  mockup: `${BASE_URL}/aws-girls-tech-series/mockup.png`,
  background: `${BASE_URL}/aws-girls-tech-series/background.png`,
  'background-tablet-1': `${BASE_URL}/aws-girls-tech-series/background-tablet-1.png`,
  'background-tablet-2': `${BASE_URL}/aws-girls-tech-series/background-tablet-2.png`,
}

const Becon = {
  background: `${BASE_URL}/becon/becon-background.jpg`,
  logo: `${BASE_URL}/becon/logo.png`,
  'transparent-logo': `${BASE_URL}/becon/transparent-logo.png`,
  mobile: {
    1: `${BASE_URL}/becon/mobile/becon1.png`,
    2: `${BASE_URL}/becon/mobile/becon2.png`,
    3: `${BASE_URL}/becon/mobile/becon3.png`,
  },
  web: {
    1: `${BASE_URL}/becon/web/device-1.png`,
    2: `${BASE_URL}/becon/web/device-2.png`,
    3: `${BASE_URL}/becon/web/device-3.png`,
    mockup: `${BASE_URL}/becon/web/mockup.png`,
    background: `${BASE_URL}/becon/web/background.png`,
    'background-tablet-1': `${BASE_URL}/becon/web/background-tablet-1.png`,
    'background-tablet-2': `${BASE_URL}/becon/web/background-tablet-2.png`,
  },
}

const Cherry = {
  background: `${BASE_URL}/cherry/background.png`,
  'logo-1': `${BASE_URL}/cherry/logo-1.png`,
  'logo-2': `${BASE_URL}/cherry/logo-2.png`,
  mobile: {
    1: `${BASE_URL}/cherry/mobile/cherry1.png`,
    2: `${BASE_URL}/cherry/mobile/cherry2.png`,
    3: `${BASE_URL}/cherry/mobile/cherry3.png`,
  },
}

const GoQuest = {
  background: `${BASE_URL}/go-quest/background.jpg`,
  logo: `${BASE_URL}/go-quest/logo.png`,
  mobile: {
    1: `${BASE_URL}/go-quest/mobile/goQuest1.png`,
    2: `${BASE_URL}/go-quest/mobile/goQuest2.png`,
    3: `${BASE_URL}/go-quest/mobile/goQuest3.png`,
  },
  web: {
    1: `${BASE_URL}/go-quest/web/device-1.png`,
    2: `${BASE_URL}/go-quest/web/device-2.png`,
    3: `${BASE_URL}/go-quest/web/device-3.png`,
    mockup: `${BASE_URL}/go-quest/web/mockup.png`,
    background: `${BASE_URL}/go-quest/web/background.png`,
    'background-tablet-1': `${BASE_URL}/go-quest/web/background-tablet-1.png`,
    'background-tablet-2': `${BASE_URL}/go-quest/web/background-tablet-2.png`,
  },
}

const Kite = {
  background: `${BASE_URL}/kite/background.png`,
  logo: `${BASE_URL}/kite/logo.png`,
  mobile: {
    1: `${BASE_URL}/kite/mobile/kite1.png`,
    2: `${BASE_URL}/kite/mobile/kite2.png`,
    3: `${BASE_URL}/kite/mobile/kite3.png`,
  },
  web: {
    1: `${BASE_URL}/kite/web/device-1.png`,
    2: `${BASE_URL}/kite/web/device-2.png`,
    3: `${BASE_URL}/kite/web/device-3.png`,
    mockup: `${BASE_URL}/kite/web/mockup.png`,
    'background-square': `${BASE_URL}/kite/web/background-square.png`,
    'background-tablet-1': `${BASE_URL}/kite/web/background-tablet-1.png`,
    'background-tablet-2': `${BASE_URL}/kite/web/background-tablet-2.png`,
  },
}

const SimplePT = {
  1: `${BASE_URL}/simple-pt/simplePT1.jpg`,
  2: `${BASE_URL}/simple-pt/simplePT2.jpg`,
  3: `${BASE_URL}/simple-pt/simplePT3.jpg`,
  4: `${BASE_URL}/simple-pt/simplePT4.jpg`,
  background: `${BASE_URL}/simple-pt/background.jpg`,
}

const Neurometrics = {
  logo: `${BASE_URL}/neurometrics/logo.png`,
  mobile: {
    1: `${BASE_URL}/neurometrics/mobile/neurometrics1.jpg`,
    2: `${BASE_URL}/neurometrics/mobile/neurometrics2.jpg`,
    3: `${BASE_URL}/neurometrics/mobile/neurometrics3.jpg`,
    4: `${BASE_URL}/neurometrics/mobile/neurometrics4.jpg`,
    5: `${BASE_URL}/neurometrics/mobile/neurometrics5.jpg`,
  },
}

const Frida = {
  1: `${BASE_URL}/frida/frida.jpg`,
  2: `${BASE_URL}/frida/frida.jpg`,
  3: `${BASE_URL}/frida/frida.jpg`,
  background: `${BASE_URL}/frida/frida-background.jpg`,
  logo: `${BASE_URL}/frida/logo.png`,
}

const GlocalX = {
  logo: `${BASE_URL}/glocal-x/logo.png`,
}

export const Portfolio = {
  Autoimaging,
  AWSCloudroom,
  AWSGirlsTechSeries,
  Bearable,
  Becon,
  Cherry,
  GlocalX,
  Frida,
  GoQuest,
  Kite,
  SimplePT,
  Skamper,
  Neurometrics,
}
