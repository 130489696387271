import { React, View } from '@/lib'

type WebDisplayProps = {
    devices: string[]
}

export const WebDisplay = (props: WebDisplayProps) => {
  return (
    <View>

    </View>
  )
}
