/* eslint-disable no-console */
import { React, Touchable, View, Tools } from '@/lib'
import { useState, useEffect, useCallback, useRef } from '@codeleap/common'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useScroll, useTransform, motion } from 'framer-motion'
import { Theme } from '@/app'

type ItemProps = {
  item: any
  index: number
  selected: number
}

type OnPressProps = {
  cardWidth: number
  firstItemAlignment: number
}

type CarouselItemProps = {
  item: any
  index: number
  renderItem: (props: ItemProps) => void
  onPress: (props: OnPressProps) => void
  centerWrapperPadding: number
  selected: number
  setSelected: any
  large: boolean
  maxItemScale: number
  cardMargin: number
  scaledCardMargin: number
  itemWidth: number
  bgColor: string
  bgSelectedColor: string
  shouldCenterItem: boolean
}

const CarouselItemComponent = (props: CarouselItemProps, ref) => {

  const {
    item,
    index,
    renderItem,
    onPress,
    centerWrapperPadding,
    selected,
    setSelected,
    large,
    maxItemScale,
    cardMargin,
    scaledCardMargin,
    itemWidth,
    bgColor,
    bgSelectedColor,
    shouldCenterItem,
  } = props

  let itemScale = null
  let backgroundColor = null
  let offset = null

  const itemRef = useRef()

  const xxlarge = Theme.hooks.down('xxlarge')

  const isCardSelected = selected === index
  const isFirstCard = index === 0
  const itemStyles = {} as Partial<HTMLElement['style']>

  const shouldAnimateFirstCard = isFirstCard && isCardSelected

  const scaledItemWidth = itemWidth * maxItemScale

  const firstItemAlignment = shouldAnimateFirstCard ? (scaledItemWidth - itemWidth) / 2 : 0
  const firstItemPadding = isFirstCard ? centerWrapperPadding : 0

  offset = large && selected !== 0 ? ['start', 'start center'] : ['center', index === 0 ? 'start' : 'center start']

  const { scrollXProgress: scrollItemProgress } = useScroll({
    layoutEffect: false,
    container: ref,
    target: itemRef,
    axis: 'x',
    offset,
  })

  itemScale = useTransform(scrollItemProgress, [0, 0.4, 0.7, 1], [1, maxItemScale, maxItemScale, 1])

  if (bgColor && bgSelectedColor) {
    backgroundColor = useTransform(
      scrollItemProgress, [0, 0.4, 0.7, 1],
      [
        bgColor,
        bgSelectedColor,
        shouldCenterItem && xxlarge ? bgSelectedColor : bgColor,
        bgColor,
      ],
    )
  }

  scrollItemProgress.on('change', (progress) => {

    const isItemSelected = progress >= 0.1 && progress <= 0.8

    if (!large && ref?.current?.scrollLeft < itemWidth) {
      setSelected(0)
    }

    if (isItemSelected) {
      setSelected(index)
    }

  })

  const onPressCard = () => {
    onPress({
      cardWidth: itemRef?.current?.offsetWidth,
      firstItemAlignment,
    })
  }

  if (isCardSelected) {
    itemStyles.marginRight = Theme.spacing(scaledCardMargin + 1)
    itemStyles.marginLeft = Theme.spacing(0)
    if (!isFirstCard) {
      itemStyles.marginInline = Theme.spacing(scaledCardMargin)
    }
  } else {
    itemStyles.marginInline = Theme.spacing(cardMargin)
  }

  const firstItemExtraStyles = {
    transform: `scale(${shouldAnimateFirstCard ? maxItemScale : '1'})`,
    transition: '1150ms',
    marginLeft: firstItemAlignment,
    backgroundColor: bgColor && bgSelectedColor ? (shouldAnimateFirstCard ? bgSelectedColor : (index === 0 ? bgColor : null)) : null,
    borderRadius: Theme.values.borderRadius.medium,
  }

  return (
    <Touchable onPress={onPressCard} ref={itemRef} key={`${index}-card`}>
      <motion.div
        style={{
          ...styles.cardWrapper,
          ...itemStyles,
          scale: isCardSelected ? itemScale : 1,
          paddingLeft: firstItemPadding,
          backgroundColor: index !== 0 ? backgroundColor : null,
          borderRadius: Theme.values.borderRadius.medium,
        }}
      >
        <View style={firstItemExtraStyles }>
          {renderItem({ item, index, selected })}
        </View>
      </motion.div>
    </Touchable>
  )
}

const CarouselItem = React.forwardRef(CarouselItemComponent)

export const Carousel = ({
  data,
  infinite = true,
  ...props
}) => {

  const ref = useRef()

  const { padding } = Tools.getMaxContentWidth()

  const uphuge = Theme.hooks.up('huge')

  const large = Theme.hooks.down('large')
  const small = Theme.hooks.down('small')

  const shouldCenterItem = large

  const [items, setItems] = useState([...data])
  const [itemWidth, setItemWidth] = useState(null)
  const [selected, setSelected] = useState(0)
  const [shouldNotSnapScroll, setShouldNotSnapScroll] = useState(false)

  const { scrollXProgress: wrapperScrollProgress } = useScroll({
    container: ref,
  })

  useEffect(() => {
    if (ref?.current && !itemWidth) {
      setItemWidth(ref?.current?.children[1].children[0].offsetWidth)
    }
  }, [ref])

  const maxItemScale = uphuge ? 1.3 : 1.2

  const cardMargin = large ? (small ? 1.5 : 3) : 4
  const scaledCardMargin = (cardMargin * maxItemScale) + cardMargin + (uphuge ? 2 : 0)

  wrapperScrollProgress.on('change', (latest) => {

    const scrollProgressPercentage = latest * 100

    const onEndReachedThresholdPercentage = 85
    const onStartReachedThresholdPercentage = 2.5

    const isScrollAtStart = scrollProgressPercentage <= onStartReachedThresholdPercentage
    const isScrollAtEnd = scrollProgressPercentage >= onEndReachedThresholdPercentage

    const hasArrayExpanded = data.length !== items.length
    const shouldResetDataArray = isScrollAtStart && hasArrayExpanded

    const shouldDuplicateArray = isScrollAtEnd && infinite

    if (shouldResetDataArray) {
      setItems(data)
    }

    if (shouldDuplicateArray) {
      setItems([...items, ...items])
    }

  })

  const onScrollToItem = ({ index, cardWidth, firstItemAlignment = 0 }) => {

    setSelected(index)

    const shouldAlignItemToLeft = !shouldCenterItem && index !== 0

    const { offsetWidth: containerWidth } = ref.current
    const centerAlignment = shouldCenterItem ? (containerWidth - cardWidth) / 3 : 0

    const itemsLeftAlignment = shouldAlignItemToLeft ? Theme.spacing(scaledCardMargin / 2) : 0

    const scrollTo = index * cardWidth - (centerAlignment + firstItemAlignment) + itemsLeftAlignment

    ref.current.scrollTo({
      left: scrollTo,
      top: 0,
      behavior: 'smooth',
    })

  }

  const onEndScroll = () => {
    const scrollLeft = ref?.current?.scrollLeft
    if (!shouldNotSnapScroll) {
      const { offsetWidth: cardWidth } = ref.current.children[selected + 1]
      const index = Math.round(scrollLeft / cardWidth)
      onScrollToItem({ index, cardWidth })
      setShouldNotSnapScroll(true)
    } else {
      setShouldNotSnapScroll(false)
    }
  }

  const renderCarouselItem = useCallback(({ item, index }) => {

    const onPressCard = (params) => {
      const isCardDiffFromSelected = selected !== index
      if (isCardDiffFromSelected) {
        setShouldNotSnapScroll(true)
        onScrollToItem({
          index,
          shouldNotSnapScroll,
          ...params,
        })
      }
    }

    return (
      <CarouselItem
        item={item}
        index={index}
        onPress={onPressCard}
        centerWrapperPadding={padding}
        ref={ref}
        selected={selected}
        setSelected={setSelected}
        large={large}
        itemWidth={itemWidth}
        maxItemScale={maxItemScale}
        cardMargin={cardMargin}
        scaledCardMargin={scaledCardMargin}
        shouldCenterItem={shouldCenterItem}
        {...props}
      />
    )
  }, [
    padding,
    ref,
    selected,
    large,
    maxItemScale,
    cardMargin,
    scaledCardMargin,
    itemWidth,
    shouldCenterItem,
  ])

  return (
    <ScrollContainer
      innerRef={ref}
      vertical={false}
      onEndScroll={onEndScroll}
      style={styles.carouselWrapper}
    >
      {items.map((item, index) => renderCarouselItem({ item, index }))}
    </ScrollContainer>
  )
}

const styles = {
  carouselWrapper: {
    ...Theme.flex,
    ...Theme.row,
    overflowX: 'scroll',
    ...Theme.paddingVertical(10),
  },
  cardWrapper: {
    transition: '800ms',
  },
}

export default Carousel
