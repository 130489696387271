import { Theme } from '@/app'
import { Assets } from '@/assets'
import { React, Image, View, Text, CenterWrapper, Touchable, Icon } from '@/lib'
import Reveal from '../Reveal'
import { Tilt } from '@jdion/tilt-react'

export type BannerItem = {
    image: string
    title: string
    subtitle?: string
    description: string
    downloadOn: ('PlayStore' | 'AppStore')[]
    appStoreLink?: string
    playStoreLink?: string
    bottomContent?: () => JSX.Element
}

type BannerProps = {
  data: BannerItem
}

const AppStoreDownloadButton = () => {
  return (
    <Touchable style={styles.button} onPress={() => () => log(`touch`)}>
      <Icon path={Assets.Icons.AlertCircle}/>

      <View variant='marginLeft:1'>
        <Text variant={`p3 white`} text={`Download on`} />
        <Text variant={`p1 white`} text={`App Store`} style={{ lineHeight: 0.8 }}/>
      </View>
    </Touchable>
  )
}

const PlayStoreDownloadButton = () => {
  return (
    <Touchable style={styles.button} onPress={() => () => log(`touch`)} variant='marginLeft:2'>
      <Icon path={Assets.Icons.AlertCircle}/>

      <View variant='marginLeft:1'>
        <Text variant={`p3 white uppercase`} text={`Get it on`} />
        <Text variant={`p1 white`} text={`Play Store`} style={{ lineHeight: 0.8 }}/>
      </View>
    </Touchable>
  )
}

export const Banner = (props: BannerProps) => {

  const { height } = Theme.hooks.size()

  const isMobile = Theme.hooks.down('mid')

  if (!props.data) return null

  const downloadButtons = () => (
    <View style={styles.buttonsWrapper}>
      {props?.data?.bottomContent ? (
        <props.data.bottomContent />
      ) : (
        props?.data?.downloadOn?.map?.((item) => {
          switch (item) {
            case 'AppStore':
              return <AppStoreDownloadButton />
            case 'PlayStore':
              return <PlayStoreDownloadButton />
            default:
              return null
          }
        })
      )}
    </View>
  )

  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>

      <View style={styles.textAreaWrapper}>
        <Reveal
          variant={'unveal'}
          offset={height * -0.5}
          duration={height * 1.5}
          disabled={isMobile}
        >
          <div className='willChangeTransform'>
            <View style={styles.textAreaInnerWrapper}>
              <View>
                <Text variant={`h6 white marginBottom:2`} text={props?.data?.title} style={styles.title}/>
                <Text variant={`h5 white marginBottom:2`} text={props?.data?.subtitle} style={styles.subtitle} />
                <Text variant={`p1 white`} text={props?.data?.description} style={styles.description}/>
              </View>
              {downloadButtons()}
            </View>
          </div>
        </Reveal>
      </View>

      <View style={styles.imageWrapper}>
        <Tilt options={{ scale: 1.01, max: 5, speed: 5000 }}>
          <Image source={props?.data?.image} style={styles.image}/>
        </Tilt>
      </View>

    </CenterWrapper>
  )
}

const styles = {
  wrapper: {
    paddingTop: Theme.spacing(20),
    paddingBottom: Theme.spacing(20),
    [Theme.media.down('largeish')]: {
      paddingTop: Theme.spacing(5),
      paddingBottom: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      paddingBottom: Theme.spacing(0),
    },
  },
  innerWrapper: {
    [Theme.media.down('mid')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  textAreaWrapper: {
    width: '50%',
    marginRight: Theme.spacing(5),
    [Theme.media.down('mid')]: {
      width: '100%',
    },
  },
  textAreaInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    justifyContent: 'space-between',
    [Theme.media.down('mid')]: {
      height: 'unset',
    },
  },
  buttonsWrapper: {
    paddingBottom: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      paddingBottom: Theme.spacing(0),
      marginTop: Theme.spacing(4),
    },
  },
  title: {
    maxWidth: '80%',
    fontWeight: '700',
  },
  description: {
    color: Theme.colors.neutral6,
    fontWeight: '400',
    maxWidth: '90%',
  },
  imageWrapper: {
    width: '50%',
    height: 400,
    [Theme.media.down('mid')]: {
      width: '100%',
      marginTop: Theme.spacing(4),
    },
  },
  image: {
    width: '100%',
    height: 400,
    objectFit: 'cover',
    borderRadius: Theme.values.borderRadius.smallish,
  },
  button: {
    maxWidth: 140,
    border: '2px solid white',
    height: 32,
    ...Theme.paddingHorizontal(1.5),
    ...Theme.paddingVertical(0.5),
    paddingBottom: Theme.spacing(1),
  },
}
