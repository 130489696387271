import React from 'react'
import { Tools, View } from '@/lib'
import { DemoDevice, GeneralCardProps, Reveal } from '@/components'
import { Theme } from '@/app'
import { Assets } from '@/assets'
import { apps } from '../data'
import CardComponents from './components'

const BearableCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position = 'left',
  } = props

  const isLargeish = Theme.hooks.down('largeish')
  const isMobile = Theme.hooks.down('mid')
  const upLarge = Theme.hooks.up('large')

  const { height } = Theme.hooks.size()

  const limitedPerformance = Tools.useLimitedPerformance()

  const data = apps['bearable-mobile']

  return (
    <CardComponents.AppRow
      elemName={'bearable'}
      background={data.background}
    >
      <CardComponents.AppDescription
        data={data}
        style={styles.appDescriptionBearable}
        position={position}
      />

      {isLargeish ? (
        <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
      ) : (
        <>
          <View style={styles.demoRowWrapper} variant={!isMobile ? 'marginLeft:3' : null}>
            <View style={styles.imagesWrapper}>
              <View
                style={[styles.demoRow, styles.demoRowLeft, styles.bearableRow]}
                up={'largeish'}
              >
                <Reveal
                  variant={'parallaxBackground'}
                  amount={'15vh'}
                  offset={height * -0.1}
                  duration={height * 1.2}
                  stopProgressAt={upLarge ? 0.3 : 0.15}
                  disabled={isMobile || limitedPerformance > 0}
                >
                  <div className={'willChangeTransform'}>
                    <DemoDevice.Demo
                      alt={`${data.title} demo 1`}
                      image={data.images[0]}
                      scale={0.5}
                    />
                  </div>
                </Reveal>
                <Reveal
                  variant={'parallaxReverse'}
                  amount={'5vh'}
                  offset={height * (upLarge ? 0.1 : -0.4)}
                  duration={height * 1.2}
                  disabled={isMobile || limitedPerformance > 0}
                >
                  <div className={'willChangeTransform'}>
                    <DemoDevice.Demo
                      alt={`${data.title} demo 2`}
                      image={Assets.Portfolio.Bearable[4]}
                      style={styles.bearableLandscape}
                      position={'landscape'}
                      scale={0.5}
                    />
                  </div>
                </Reveal>
              </View>
            </View>
          </View>
        </>
      )}
    </CardComponents.AppRow>
  )
}

export default BearableCard

const styles = {
  appDescriptionBearable: {
    paddingRight: Theme.spacing(9),
    [Theme.media.down('large')]: {
      paddingRight: Theme.spacing(6),
    },
    [Theme.media.down('largeish')]: {
      paddingRight: Theme.spacing(2),
      paddingLeft: 0,
    },
    [Theme.media.down('mid')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  demoRowWrapper: {
    ...Theme.flex,
    ...Theme.center,
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  imagesWrapper: {
    position: 'absolute',
    left: -30,
    display: 'flex',
    alignItems: 'center',
    [Theme.media.down('large')]: {
      left: -15,
    },
  },
  demoRow: {
    ...Theme.row,
    position: 'absolute',
    left: 0,
    [Theme.media.down('mid')]: {
      position: 'unset',
      ...Theme.center,
      flexDirection: 'column',
    },
  },
  demoRowLeft: {
    position: 'absolute',
    left: 'unset',
    right: 0,
    [Theme.media.down('mid')]: {
      position: 'unset',
    },
  },
  bearableRow: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '95%',
    [Theme.media.down('large')]: {
      transform: 'scale(0.9)',
      transformOrigin: 'left',
    },
    [Theme.media.down('largeish')]: {
      transform: 'scale(0.9)',
      transformOrigin: 'right',
    },
    [Theme.media.down('mid')]: {
      transform: 'inherit',
      width: 'unset',
    },
  },
  bearableLandscape: {
    position: 'relative',
    left: '-120px',
    top: '180px',
    zIndex: 100,
    [Theme.media.down('xlarge')]: {
      left: '-140px',
    },
    [Theme.media.down('large')]: {
      left: '-220px',
    },
  },
}
