import AutoImagingCard from './Autoimaging'
import AWSCloudroomCard from './AWSCloudroom'
import AWSGirlsTechSeriesCard from './AWSGirlsTechSeries'
import { BaseWrapper } from './BaseWrapper'
import BearableCard from './BearableMobile'
import BeconMobileCard from './BeconMobile'
import BeconWebCard from './BeconWeb'
import CherryMobileCard from './CherryMobile'
import GoQuestMobileCard from './GoQuestMobile'
import GoQuestWebCard from './GoQuestWeb'
import KiteGeneralCard from './KiteGeneral'
import KiteMobileCard from './KiteMobile'
import KiteWebCard from './KiteWeb'
import SkamperMobileCard from './SkamperMobile'

export type GeneralCardProps = {
    onSelect?: () => void
    position?: string
}

export const PortfolioCards = {
  Wrapper: BaseWrapper,
  Bearable: BearableCard,
  AWSCloudroom: AWSCloudroomCard,
  AWSGirlsTechSeries: AWSGirlsTechSeriesCard,
  BeconMobile: BeconMobileCard,
  BeconWeb: BeconWebCard,
  CherryMobile: CherryMobileCard,
  GoQuestMobile: GoQuestMobileCard,
  GoQuestWeb: GoQuestWebCard,
  KiteMobile: KiteMobileCard,
  KiteWeb: KiteWebCard,
  KiteGeneral: KiteGeneralCard,
  SkamperMobile: SkamperMobileCard,
  AutoImaging: AutoImagingCard,
}

export default PortfolioCards
