import { Assets } from '@/assets'

type TechStackCategory = 'Front-end' | 'Back-end' | 'Services'

type TechStackItem = {
  name: string;
  icon: string;
}

export type TechStackData = {
  [key in TechStackCategory]: TechStackItem[];
}

export const DefaultTechStackData: TechStackData = {
  'Front-end': [
    {
      name: 'React-Native',
      icon: Assets.Icons.TechStack['Front-end'].ReactNative,
    },
    {
      name: 'JavaScript',
      icon: Assets.Icons.TechStack['Front-end'].JavaScript,
    },
    {
      name: 'Objective-C',
      icon: Assets.Icons.TechStack['Front-end'].ObjectiveC,
    },
    {
      name: 'Java',
      icon: Assets.Icons.TechStack['Front-end'].Java,
    },
  ],
  'Back-end': [
    {
      name: 'Python',
      icon: Assets.Icons.TechStack['Back-end'].Python,
    },
    {
      name: 'Django',
      icon: Assets.Icons.TechStack['Back-end'].Django,
    },
    {
      name: 'PostgreSQL',
      icon: Assets.Icons.TechStack['Back-end'].Database,
    },
  ],
  Services: [
    {
      name: 'AWS',
      icon: Assets.Icons.TechStack.Services.Cloud,
    },
    {
      name: 'Firebase',
      icon: Assets.Icons.TechStack.Services.Firebase,
    },
    {
      name: 'Sentry',
      icon: Assets.Icons.TechStack.Services.Sentry,
    },
  ],
}
