import { Theme } from '@/app'
import { React, Image, View } from '@/lib'

export const TabletDisplay = (props) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.tabletWrapper}>
        <Image
          loading={'eager'}
          src={props?.device}
          style={styles.image}
        />
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.flex,
    ...Theme.alignCenter,
    ...Theme.justifyEnd,
    height: '100%',
    [Theme.media.down('mid')]: {
      ...Theme.justifyCenter,
    },
  },
  tabletWrapper: {
    width: `calc(0.35 * ${Theme.values.portfolioCard.width})`,
    height: `calc(0.7 * ${Theme.values.portfolioCard.height})`,
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    [Theme.media.down('large')]: {
      width: `calc(0.4 * ${Theme.values.portfolioCard.width})`,
    },
    [Theme.media.down('mid')]: {
      width: `calc(0.8 * ${Theme.values.portfolioCard.width})`,
    },
    [Theme.media.down('small')]: {
      width: `calc(0.75 * ${Theme.values.portfolioCard.width})`,
    },
  },
  image: {
    objectFit: 'fill',
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.large / 2,
  },
}
