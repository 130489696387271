import { React, Image, Text, View, Tools } from '@/lib'
import { BaseCard } from './BaseCard'
import { Theme } from '@/app'
import { DemoDevice, Device, IconContainer } from '@/components'

const DemoDeviceScales = {
  xlarge: 0.9,
  large: 0.85,
  largeish: 0.75,
  mid: 0.95,
  small: 0.6,
}

const CustomDeviceWidths = {
  normal: `calc(0.35 * ${Theme.values.portfolioCard.width})`,
  large: `calc(0.4 * ${Theme.values.portfolioCard.width})`,
  mid: `calc(0.8 * ${Theme.values.portfolioCard.width})`,
}

const getImageScale = (width, adjust = 1.1) => {
  let actualScale = adjust

  if (width <= Theme.breakpoints.small) {
    actualScale = adjust * DemoDeviceScales.small
  } else if (width <= Theme.breakpoints.mid) {
    actualScale = adjust * DemoDeviceScales.mid
  } else if (width <= Theme.breakpoints.largeish) {
    actualScale = adjust * DemoDeviceScales.largeish
  } else if (width <= Theme.breakpoints.large) {
    actualScale = adjust * DemoDeviceScales.large
  } else if (width <= Theme.breakpoints.xlarge) {
    actualScale = adjust * DemoDeviceScales.xlarge
  }
  return actualScale
}

export const MainCard = (props) => {

  const { width } = Theme.hooks.size()

  const { padding } = Tools.getMaxContentWidth()

  const actualScale = getImageScale(width)

  const isMobile = Theme.hooks.down('mid')

  const deviceWrapperStyles = isMobile ? {} : (props?.item?.devicePosition === 'left' ? styles.leftDeviceWrapper : styles.rightDeviceWrapper)
  const customDeviceWrapperStyles = isMobile ? {} : (props?.item?.devicePosition === 'left' ? styles.leftCustomDeviceWrapper : styles.rightCustomDeviceWrapper)

  const textAreaStyles = isMobile ? {} : (props?.item?.devicePosition === 'left' ? styles.rightTextArea : styles.leftTextArea)

  const renderDevicePreview = () => {

    if (props?.item?.CustomDisplay) {
      return (
        <View style={[styles.customDeviceWrapper, { ...customDeviceWrapperStyles, bottom: 0 }]}>
          <View style={styles.customDeviceInnerWrapper}>
            <props.item.CustomDisplay {...props?.item} />
          </View>
        </View>
      )
    }

    return (
      <View style={[styles.deviceWrapper, props?.item?.devicePosition === 'left' ? { left: 0 } : { right: 0 }]}>
        <View style={[styles.deviceInnerWrapper, { ...deviceWrapperStyles }]}>
          <Device device={props?.item?.device} scale={actualScale} />
        </View>
      </View>
    )
  }

  return (
    <BaseCard>
      <Image source={props?.item?.image} style={styles.image}/>

      {renderDevicePreview()}

      <View style={[styles.textArea,
        {
          ...textAreaStyles,
          left: isMobile ? padding * 2 : textAreaStyles?.left,
          top: isMobile ? padding * 2 : textAreaStyles?.top,
        },
      ]}
      >
        <IconContainer icon={props?.item?.textArea?.icon} variant={props?.styles?.iconContainerVariant}/>
        <Text
          variant={`h3 marginBottom:2`}
          text={props?.item?.textArea?.title}
          {...props?.item?.textArea?.titleProps}
          style={[styles.title, { ...props?.item?.textArea?.titleProps?.style, color: props?.styles?.titleColor }]}
        />
        <Text
          variant={`p1 graniteGrey`}
          text={props?.item?.textArea?.description}
          {...props?.item?.textArea?.descriptionProps}
          style={[styles.description, { ...props?.item?.textArea?.descriptionProps?.style, color: props?.styles?.descriptionColor }]}
        />
      </View>
    </BaseCard>
  )
}

const styles = {
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: Theme.values.borderRadius.largeish,
    position: 'relative',
  },
  deviceWrapper: {
    height: '100%',
    width: '50%',
    position: 'absolute',
    top: 0,
    overflow: 'hidden',
    [Theme.media.down('mid')]: {
      left: 0,
      width: '100%',
    },
  },
  deviceInnerWrapper: {
    position: 'absolute',
    bottom: `calc(${Theme.values.portfolioCard.height} - 1000px)`,
    maxWidth: DemoDevice.width,
    [Theme.media.down('xlarge')]: {
      maxWidth: DemoDevice.width * DemoDeviceScales.xlarge,
    },
    [Theme.media.down('large')]: {
      maxWidth: DemoDevice.width * DemoDeviceScales.large,
    },
    [Theme.media.down('largeish')]: {
      maxWidth: DemoDevice.width * DemoDeviceScales.largeish,
    },
    [Theme.media.down('mid')]: {
      bottom: -(DemoDevice.height * DemoDeviceScales.mid) / 2,
      width: '100%',
      maxWidth: '100%',
      left: `calc(${Theme.values.portfolioCard.width} / 2 - ${(DemoDevice.width * DemoDeviceScales.mid) / 2}px - 35px)`,
    },
    [Theme.media.down('small')]: {
      bottom: -450,
      left: `calc(${Theme.values.portfolioCard.width} / 2 - ${(DemoDevice.width * DemoDeviceScales.small) / 2}px - 20px)`,
    },
  },
  customDeviceWrapper: {
    position: 'absolute',
    height: '100%',
    width: '50%',
    bottom: 0,
    [Theme.media.down('mid')]: {
      width: '100%',
      overflow: 'hidden',
    },
  },
  customDeviceInnerWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    ...Theme.flex,
    ...Theme.alignCenter,
    ...Theme.justifyStart,
    [Theme.media.down('mid')]: {
      height: '50%',
      bottom: -50,
    },
  },
  leftCustomDeviceWrapper: {
    [Theme.media.down('large')]: {
      left: 50,
    },
    [Theme.media.down('largeish')]: {
      left: 10,
    },
  },
  rightCustomDeviceWrapper: {
    right: `calc(((${Theme.values.portfolioCard.width} / 2) - (${CustomDeviceWidths.normal})) / 2)`,
    [Theme.media.down('large')]: {
      right: `calc(((${Theme.values.portfolioCard.width} / 2) - (${CustomDeviceWidths.large})) / 2)`,
    },
    [Theme.media.down('largeish')]: {
      right: `calc(((${Theme.values.portfolioCard.width} / 2) - (${CustomDeviceWidths.largeish})) / 2)`,
    },
    [Theme.media.down('mid')]: {
      right: `calc(((${Theme.values.portfolioCard.width} / 2) - (${CustomDeviceWidths.mid})) / 2)`,
    },
  },
  leftDeviceWrapper: {
    left: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width)}px) / 2 - 50px)`,
    [Theme.media.down('xlarge')]: {
      left: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.xlarge)}px) / 2 - 50px)`,
    },
    [Theme.media.down('large')]: {
      left: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.large)}px) / 2 - 30px)`,
    },
    [Theme.media.down('largeish')]: {
      left: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.largeish)}px) / 2 - 30px)`,
    },
  },
  rightDeviceWrapper: {
    right: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width)}px) / 2)`,
    [Theme.media.down('xlarge')]: {
      right: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.xlarge)}px) / 2)`,
    },
    [Theme.media.down('large')]: {
      right: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.large)}px) / 2)`,
    },
    [Theme.media.down('largeish')]: {
      right: `calc(((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.largeish)}px) / 2)`,
    },
  },
  textArea: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '50%',
    maxWidth: 600,
    ...Theme.flex,
    ...Theme.justifyCenter,
    ...Theme.column,
    [Theme.media.down('mid')]: {
      width: '100%',
      height: 500,
      ...Theme.justifyStart,
    },
  },
  leftTextArea: {
    left: `calc((((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width)}px) / 2) / 1.5)`,
    [Theme.media.down('xlarge')]: {
      left: `calc((((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.xlarge)}px) / 2) / 1.5)`,
    },
    [Theme.media.down('large')]: {
      left: `calc((((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.large)}px) / 2) / 1.5)`,
    },
    [Theme.media.down('largeish')]: {
      left: `calc((((${Theme.values.portfolioCard.width} / 2) - ${(DemoDevice.width * DemoDeviceScales.largeish)}px) / 2) / 1.5)`,
    },
  },
  rightTextArea: {
    right: -75,
  },
  title: {
    maxWidth: '70%',
  },
  description: {
    maxWidth: '70%',
  },
}
