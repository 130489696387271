/* eslint no-console: "off" */
import { Settings } from '@/app'
import callsites from 'callsites'
import { inspect } from 'util' // https://nodejs.org/api/util.html
import * as Sentry from '@sentry/gatsby'

const IGNORED_WARNINGS = [
  'Require cycle:',
  'Warning: Unsupported style property %s. Did you mean %s?%s @media screen',
]
if (Settings.WARN_INTERNATIONALIZATION == false) {
  IGNORED_WARNINGS.push('[React Intl]')
}

function callFuncWithArgs(logger, ...args) {
  logger(...args)
}

function customLogger(key, value, type, color?) {
  if (!value && key) {
    value = key
    if (typeof key == 'object') {
      const keys = Object.keys(key)
      const firstKey = keys[0]
      if (keys.length == 1) {
        value = key[firstKey]
        key = firstKey
      } else {
        key = ''
      }
    } else {
      key = ''
    }
  } else if (!key) {
    value = ''
    key = ''
  }
  if (Settings.IS_DEVELOPMENT && Settings.IS_BROWSER) {
    const callStack = callsites()
    const funcName = callStack.length > 2 ? callStack[2].getFunctionName() : null
    const caller = funcName && !funcName.includes('calle') ? funcName + '() ' : ''
    let title
    const titleStr = caller + key
    if (type == 'error' || type == 'warn') {
      const logger = console.warn
      if (type == 'error') {
        title = '[ERROR] ' + titleStr
        callFuncWithArgs(logger, title, value)
      } else if (type == 'warn') {
        title = '[WARN] ' + titleStr
        callFuncWithArgs(logger, title, value)
      }
      if (type == 'error') {
        throw title
      }
    } else {
      let titleStyle
      const logger = console.log
      if (type == 'log' && Settings.LOG_LEVEL != 'warn') {
        title = '%c[LOG] ' + titleStr
        titleStyle = `color: #fdcb6e; background: ${color}`
        callFuncWithArgs(logger, title, titleStyle, value)
      } else if (type == 'info' && Settings.LOG_LEVEL == 'info') {
        title = '%c[INFO] ' + titleStr
        titleStyle = `color: #74b9ff; background: ${color}`
        callFuncWithArgs(logger, title, titleStyle, value)
      }
    }
  } else if (Settings.IS_BROWSER) {
    captureBreadcrumb(key, value, type)
  }
}

function captureBreadcrumb(key, value, type) {
  const level = type == 'warn' ? 'warning' : type == 'log' ? 'debug' : type
  let message = ''
  if (typeof value == 'string') {
    message = value
  } else if (typeof value == 'number') {
    message = String(value)
  } else if (typeof value == 'object') {
    message = inspect(value, { showHidden: false, compact: false, depth: 2 })
  } else {
    message = `Unsupported object type ${typeof value}`
  }

  const uploadData = {
    category: key,
    message: message,
    level,
    data: message,
  }

  Sentry.addBreadcrumb(uploadData)
}

if (Settings.IS_DEVELOPMENT) {
  const oldConsoleWarn = console.warn
  const oldConsoleError = console.error
  const newConsole = (args, oldConsole) => {
    if (
      typeof args[0] === 'string' &&
      IGNORED_WARNINGS.some(ignoredWarning => args.join(' ').includes(ignoredWarning))
    ) {
      return
    }
    return oldConsole.apply(console, args)
  }
  console.warn = (...args) => newConsole(args, oldConsoleWarn)
  console.error = (...args) => newConsole(args, oldConsoleError)
}

global.warn = (key, value?) => {
  customLogger(key, value, 'warn')
}

const error = (key, value?) => {
  customLogger(key, value, 'error')
}

const info = (key, value?) => {
  customLogger(key, value, 'info')
}

const warn = (key, value?) => {
  customLogger(key, value, 'warn')
}

const log = (key, value?) => {
  customLogger(key, value, 'log')
}

const deb = {
  perf: (key, value?) => { if (Settings.DEBUG_PERFORMANCE) customLogger(`[PERF] ${key}`, value, 'log', 'brown') },
  green: (key, value?) => { customLogger(key, value, 'log', 'green') },
  blue: (key, value?) => { customLogger(key, value, 'log', 'blue') },
  red: (key, value?) => { customLogger(key, value, 'log', 'red') },
  yellow: (key, value?) => { customLogger(key, value, 'log', 'yellow') },
  cyan: (key, value?) => { customLogger(key, value, 'log', 'cyan') },
  purple: (key, value?) => { customLogger(key, value, 'log', 'purple') },
}

const logger = {
  green: (key, value?) => { customLogger(key, value, 'log', 'green') },
  blue: (key, value?) => { customLogger(key, value, 'log', 'blue') },
  red: (key, value?) => { customLogger(key, value, 'log', 'red') },
  yellow: (key, value?) => { customLogger(key, value, 'log', 'yellow') },
  cyan: (key, value?) => { customLogger(key, value, 'log', 'cyan') },
  purple: (key, value?) => { customLogger(key, value, 'log', 'purple') },
}

export const Logger = {
  error,
  info,
  warn,
  log,
  deb,
  logger,
}

export default Logger
