import {
  View,
  Touchable,
  CenterWrapper,
  React,
  Image,
  StyleSheet,
  AnalyticsView,
  Link,
  Text,
  Icon,
} from '@/lib'
import { navigate } from 'gatsby'
import { useState } from '@codeleap/common'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { FaBars, FaChevronUp, FaChevronDown } from 'react-icons/fa'
import { getButtonData } from './data'
import { Theme } from '@/app'
import posed from 'react-pose'

const transition = {
  duration: 250,
}

const ExpandingSection = posed.div({
  init: {
    height: 0,
    marginTop: 'inherit',
    transition,
  },
  show: {
    height: 'auto',
    transition,
  },
})

const MobileNavButton = (props) => {

  const { text, to, items } = props.button
  const textVariant = props.mobile ? 'h6' : 'p2'

  const [expanded, setExpanded] = React.useState(false)

  const ArrowIcon = expanded ? FaChevronDown : FaChevronUp

  const shouldDisplayOpenAccordion = items?.length

  const MobileButtonItem = ({ item }) => {
    return (
      <Touchable
        key={item?.title}
        to={item?.to}
        onClick={() => navigate(item?.to)}
        style={styles.dropdownButton}
        variant={'paddingHorizontal:3 paddingVertical:2 fullWidth'}
      >
        <Icon path={item?.icon} size={24} color={Theme.colors.neutral7}/>
        <View variant={'marginLeft:1'}>
          <Text variant={`p1 neutral3`} text={item?.title}/>
          <Text variant={`p3 neutral4`} text={item?.description}/>
        </View>
      </Touchable>
    )
  }

  return (
    <>
      <Touchable
        style={[
          styles.navButtonTextWrapper,
        ]}
        onPress={() => setExpanded(!expanded)}
      >
        <Link
          gaLabel={`HeaderNavButton`}
          variant={textVariant}
          to={!props?.isHomepage && to === '/#services' ? null : to}
          onPress={props.pressed}
          text={text}
          style={[
            styles.navButtonText,
          ]}
        />
        {shouldDisplayOpenAccordion ? <ArrowIcon color='white' style={styles.arrowIcon}/> : null}
      </Touchable>
      {shouldDisplayOpenAccordion ? (
        <ExpandingSection pose={expanded ? 'show' : 'init'} style={styles.expandingSection}>
          {items?.map((item) => <MobileButtonItem key={item} item={item}/>)}
        </ExpandingSection>
      ) : null}
    </>
  )
}

export const MobileDropdown = (props) => {

  const [state, setState] = useState({ isOpen: false })

  const buttons = getButtonData({ service: props?.service })

  return (
    <View>
      <Touchable
        onPress={() => setState({ isOpen: !state.isOpen })}
        gaLabel={`HeaderNavButton`}
        gaAction={`hamburger`}
      >
        <FaBars size={20} color={'white'}/>
      </Touchable>
      <SwipeableDrawer
        anchor='top'
        open={state.isOpen}
        onOpen={() => setState({ isOpen: true })}
        onClose={() => setState({ isOpen: false })}
        style={StyleSheet.flatten(styles.drawer)}
      >
        <CenterWrapper
          contentContainerStyle={[
            styles.centerWrapper,
            styles.drawerTopWrapper,
          ]}
        >
          <Touchable
            gaLabel={`HeaderNavButton`}
            gaAction={`logo image`}
            style={styles.logoWrapper}
            onPress={() => {
              navigate('/')
              setState({ isOpen: false })
            }}
          >
            <Image
              src={`assets/logos/codeleap_logo_white.png`}
              alt='CodeLeap'
              style={styles.logoImage}
            />
          </Touchable>
          <Touchable
            gaLabel={`HeaderNavButton`}
            gaAction={`hamburger`}
            onPress={() => setState({ isOpen: !state.isOpen })}
          >
            <FaBars size={20} color={'white'}/>
          </Touchable>
        </CenterWrapper>
        <AnalyticsView
          gaLabel={'MobileNavHeaderBar'}
          gaAction={'Hamburger menu'}
          style={styles.navButtonWrapperDrawer}
        >
          {buttons.map((button, idx) => {
            return (
              <MobileNavButton
                key={idx}
                button={button}
                mobile={true}
                pressed={() => setState({ isOpen: false })}
                isHomepage={props?.isHomepage}
              />
            )
          })}
        </AnalyticsView>
      </SwipeableDrawer>
    </View>
  )
}

const styles = {
  drawer: {
    zIndex: 11000,
  },
  centerWrapper: {
    ...Theme.flex,
    ...Theme.row,
    ...Theme.alignCenter,
    ...Theme.justifySpaceBetween,
    padding: 0,
    backgroundColor: Theme.colors.neutral5,
  },
  drawerTopWrapper: {
    height: Theme.values.headerMenuHeight,
    backgroundColor: Theme.colors.neutral5,
  },
  logoWrapper: {
    ...Theme.row,
    ...Theme.flex,
    ...Theme.alignCenter,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logoImage: {
    width: 'max(100px, min(calc(0px + 9vw), 150px))',
    height: '100%',
    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing(2),
    },
  },
  expandingSection: {
    overflow: 'hidden',
  },
  navButtonWrapperDrawer: {
    backgroundColor: Theme.colors.neutral5,
  },
  navButtonTextWrapper: {
    padding: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      ...Theme.flex,
      ...Theme.justifySpaceBetween,
      ...Theme.alignCenter,
    },
  },
  navButtonText: {
    zIndex: 'inherit',
    color: Theme.colors.white,
    padding: Theme.spacing(1),
    [Theme.media.is('small')]: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    textDecorationLine: 'none',
    fontWeight: '500',
    '&:hover': {
      opacity: 0.8,
    },
  },
  dropdownButton: {
    width: '100%',
    backgroundColor: Theme.colors.neutral8,
    ...Theme.alignCenter,
  },
  arrowIcon: {
    marginRight: Theme.spacing(2),
  },
}
