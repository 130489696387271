import AIShowcase from './AI'
import AppsShowcase from './Apps'
import DefaultShowcase from './Default'
import NextGenShowcase from './NextGen'
import WebShowcase from './Web'

export * from './Apps'
export * from './AI'
export * from './Web'
export * from './Default'

export type ShowcaseProps = {
    selected: string
    setSelected: (name: string) => void
    clear: () => void
}

export const PortfolioShowcases = {
  Apps: AppsShowcase,
  Web: WebShowcase,
  NextGen: NextGenShowcase,
  AI: AIShowcase,
  Default: DefaultShowcase,
}
