import { View, Text, CenterWrapper, React, Image, equals, Logger, Tools } from '@/lib'
import { Theme } from '@/app'
import { ContactForm, Reveal } from '@/components'
import { Assets } from '@/assets'

export const ContactUs = () => {

  Logger.deb.perf('render ContactUs')

  const limitedPerformance = Tools.useLimitedPerformance()

  const { height } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  return (
    <View style={styles.wrapper} id={'contact'}>
      <View style={styles.backgroundImageWrapper}>
        <Reveal
          variant={'parallaxBackground'}
          amount={'20vh'}
          offset={height * -0.6}
          duration={height * 2.4}
          disabled={limitedPerformance > 0 || isMobile}
        >
          <div>
            <Image alt={'London globe-map background'} source={Assets.Misc['world-map-bg']} style={styles.backgroundImage}/>
          </div>
        </Reveal>
      </View>
      <CenterWrapper style={styles.centerWrapper}>
        <View style={styles.innerWrapper}>
          <View style={styles.leftArea}>
            <View variant={'column'}>
              <Text variant={'h2 primary'} text={'Let’s work together!'}/>
              <Text variant={'h6 white marginTop:2'} text={`Our friendly team will love to hear from you.`}/>
            </View>
          </View>
          <View style={styles.rightArea}>
            <ContactForm />
          </View>
        </View>
      </CenterWrapper>
    </View>
  )
}

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(ContactUs, areEqual)

const styles = {
  wrapper: {
    position: 'relative',
    maxHeight: '100vh',
    height: '100vh',
    [Theme.media.down('mid')]: {
      maxHeight: 'unset',
      height: 'unset',
    },
  },
  centerWrapper: {
    height: '90vh',
    [Theme.media.down('mid')]: {
      height: 'unset',
    },
  },
  innerWrapper: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
    zIndex: 100,
    alignItems: 'center',
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
    },
  },
  leftArea: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    [Theme.media.down('large')]: {
      maxWidth: 280,
      padding: Theme.spacing(3),
      marginRight: Theme.spacing(12),
    },
    [Theme.media.down('mid')]: {
      maxWidth: 'unset',
      padding: Theme.spacing(2),
      marginRight: 0,
    },
  },
  rightArea: {
    display: 'flex',
    flex: 1,
    ...Theme.center,
    width: '100%',
  },
  formInfo: {
    ...Theme.center,
    textAlign: 'center',
  },
  backgroundImageWrapper: {
    position: 'absolute',
    width: '100vw',
    objectFit: 'cover',
    bottom: 0,
    zIndex: -1,
    overflow: 'visible',
  },
  backgroundImage: {
    [Theme.media.down('mid')]: {
      minWidth: '140%',
    },
    [Theme.media.down('small')]: {
      minWidth: '180%',
    },
  },
}
