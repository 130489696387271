import { View, React } from '@/lib'
import { ShowcaseProps } from '.'
import { MobileComponents, PortfolioCards } from '@/components'

const AIShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <PortfolioCards.Wrapper>
      {/* <PortfolioCards.AutoImaging onSelect={() => setSelected('autoimaging')}/> */}
      <PortfolioCards.CherryMobile onSelect={() => setSelected('cherry')}/>
      <PortfolioCards.Bearable onSelect={() => setSelected('bearable')}/>
      <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
    </PortfolioCards.Wrapper>
  )
}

export default AIShowcase
