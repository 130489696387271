import {
  View,
  Text,
  Image,
  React,
  Button,
} from '@/lib'
import { Theme } from '@/app'
import { Assets } from '@/assets'
import { styles } from './styles'

const appStoreLogos = [
  Assets.Logos.AppStore,
  Assets.Logos.PlayStore,
]

const renderParagraph = (i, k) => {
  const tiny = Theme.hooks.down('tiny')
  const variants = `${tiny ? 'p1:responsive marginBottom:2' : 'p1 marginBottom:3'} white`
  return <Text key={k} variant={variants} text={i}/>
}

const renderButton = (props) => {
  return (
    <View style={styles.buttonWrapper}>
      <props.button/>
    </View>
  )
}

export const AppDescription = (props) => {

  return (
    <View
      style={[
        styles.appDescriptionWrapper,
        props.position == 'left' && styles.appDescriptionWrapperLeft,
        props.style,
      ]}
    >
      <View up={'mid'}>
        <Image source={props.data.logo} style={[styles.appLogo, props?.data?.extraStyles?.logo]} alt={`${props.data.logo} app logo`}/>
      </View>
      <Text variant={'p1:responsive thin neutralWhite uppercase marginVertical:1'} text={props.data.subtitle}/>
      <Text variant={'h3 white marginVertical:2'} text={props.data.title}/>
      <View up={'mid'}>
        {props.data.body.map(renderParagraph)}
        {props?.button ? (
          renderButton(props)
        ) : (
          <Button
            gaLabel={'Portfolio'}
            variant={'inverted uppercase'}
            text={'Learn more'}
            to={`/case-studies/${props.data.slug}`}
          />
        )}
      </View>
      <View up={'mid'} variant={'flex marginTop:3'} style={styles.appStoreLogosWrapper}>
        {!props?.data?.doNotShowAppStoreLogos && appStoreLogos?.map((item) => (
          <Image
            source={item}
            key={item}
            style={styles.appStoreLogos}
            alt={`${item} logo`}
          />
        ))}
      </View>
      {props?.data?.companies && (
        <View up={'mid'}>
          <Text variant={'p2 white bold'} text={'Recommended by'}/>
          <View variant={'flex center'} style={{ maxHeight: 50, justifyContent: 'flex-start' }}>
            {props?.data?.companies?.map((item) => <Image source={item} key={item} style={styles.companiesLogo} alt={`${item} logo`}/>)}
          </View>
        </View>
      )}
    </View>
  )
}
