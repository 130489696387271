import { Icons } from '../icons'

const BASE_URL = `assets/case-studies`

const AWSCloudroom = {
  colors: {
    'aws-cloudroom-sky': '#4773C8',
    textColor: 'white',
  },
  landing: {
    background: `${BASE_URL}/aws-cloudroom/landing/background.png`,
    'device-1': `${BASE_URL}/aws-cloudroom/landing/device-1.png`,
    'device-2': `${BASE_URL}/aws-cloudroom/landing/device-2.png`,
  },
  solution: {
    image: `${BASE_URL}/aws-cloudroom/solution/cover.png`,
  },
  reviews: {
    background: `${BASE_URL}/aws-cloudroom/reviews/background.png`,
  },
  banner: `${BASE_URL}/aws-cloudroom/banner.png`,
  'tech-stack': {
    image: `${BASE_URL}/aws-cloudroom/tech-stack/image.png`,
  },
  features: {
    background: `${BASE_URL}/aws-cloudroom/features/background.png`,
    'first-card': {
      device: `${BASE_URL}/aws-cloudroom/features/device-1.png`,
      icon: Icons.ClipboardEdit,
    },
    'second-card': {
      device: `${BASE_URL}/aws-cloudroom/features/device-2.png`,
      icon: Icons.CodePen,
    },
    'third-card': {
      device: `${BASE_URL}/aws-cloudroom/features/device-3.png`,
      icon: Icons.Layers,
    },
    'fourth-card': {
      device: `${BASE_URL}/aws-cloudroom/features/device-4.png`,
      icon: Icons.Stack,
    },
  },
}

const AWSGirlsTechSeries = {

}

const Bearable = {
  features: {
    'first-card': {
      device: `${BASE_URL}/bearable/features/device-1.png`,
      icon: '',
    },
    'second-card': {
      device: `${BASE_URL}/bearable/features/device-2.png`,
      icon: '',
    },
    'full-width-card-devices': `${BASE_URL}/bearable/features/full-width-card-device.png`,
    'splitted-card-device': `${BASE_URL}/bearable/features/splitted-card-device.png`,
  },
  banner: `${BASE_URL}/bearable/banner/banner.png`,
  solution: {
    image: `${BASE_URL}/bearable/solution/solution-cover.png`,
  },
}

const Becon = {
  colors: {
    textColor: 'white',
  },
  landing: {
    background: `${BASE_URL}/becon/landing/background.png`,
    'device-1': `${BASE_URL}/becon/landing/device-1.png`,
    'device-2': `${BASE_URL}/becon/landing/device-2.png`,
  },
  banner: `${BASE_URL}/becon/banner.png`,
  reviews: {
    background: `${BASE_URL}/becon/background.png`,
  },
  features: {
    background: `${BASE_URL}/becon/features/background.png`,
    'first-card': {
      device: `${BASE_URL}/becon/features/device-1.png`,
      icon: Icons.Map,
    },
    'second-card': {
      device: `${BASE_URL}/becon/features/device-2.png`,
      icon: Icons.MapPin,
    },
    'third-card': {
      device: `${BASE_URL}/becon/features/device-3.png`,
      icon: Icons.Code,
    },
    'fourth-card': {
      device: `${BASE_URL}/becon/features/device-4.png`,
      icon: Icons.AlertCircle,
    },
    'fifth-card': {
      device: `${BASE_URL}/becon/features/device-5.png`,
      icon: Icons.MessageReport,
    },
  },
  solution: {
    image: `${BASE_URL}/becon/solution/cover.png`,
  },
}

const Cherry = {
  colors: {
    textColor: 'white',
  },
  background: `${BASE_URL}/cherry/background.png`,
  landing: {
    'device-1': `${BASE_URL}/cherry/landing/device-1.png`,
    'device-2': `${BASE_URL}/cherry/landing/device-2.png`,
  },
  banner: `${BASE_URL}/cherry/banner.png`,
  reviews: {
    background: `${BASE_URL}/cherry/background.png`,
  },
  features: {
    background: `${BASE_URL}/cherry/features/background.png`,
    'first-card': {
      device: `${BASE_URL}/cherry/features/device-1.png`,
      icon: Icons.Map,
    },
    'second-card': {
      device: `${BASE_URL}/cherry/features/device-2.png`,
      icon: Icons.MapPin,
    },
    'third-card': {
      device: `${BASE_URL}/cherry/features/device-3.png`,
      icon: Icons.Code,
    },
    'fourth-card': {
      device: `${BASE_URL}/cherry/features/device-4.png`,
      icon: Icons.AlertCircle,
    },
    'fifth-card': {
      device: `${BASE_URL}/cherry/features/device-5.png`,
      icon: Icons.MessageReport,
    },
  },
  solution: {
    image: `${BASE_URL}/cherry/solution/cover.png`,
  },
}

const GoQuest = {
  colors: {
    textColor: 'white',
  },
  background: `${BASE_URL}/go-quest-adventures/background.png`,
  landing: {
    'device-1': `${BASE_URL}/go-quest-adventures/landing/device-1.png`,
    'device-2': `${BASE_URL}/go-quest-adventures/landing/device-2.png`,
  },
  banner: `${BASE_URL}/go-quest-adventures/banner.png`,
  reviews: {
    background: `${BASE_URL}/go-quest-adventures/background.png`,
  },
  features: {
    background: `${BASE_URL}/go-quest-adventures/features/background.png`,
    'first-card': {
      device: `${BASE_URL}/go-quest-adventures/features/device-1.png`,
      icon: Icons.Map,
    },
    'second-card': {
      device: `${BASE_URL}/go-quest-adventures/features/device-2.png`,
      icon: Icons.MapPin,
    },
    'third-card': {
      device: `${BASE_URL}/go-quest-adventures/features/device-3.png`,
      icon: Icons.Code,
    },
    'fourth-card': {
      device: `${BASE_URL}/go-quest-adventures/features/device-4.png`,
      icon: Icons.AlertCircle,
    },
    'fifth-card': {
      device: `${BASE_URL}/go-quest-adventures/features/device-5.png`,
      icon: Icons.MessageReport,
    },
  },
  solution: {
    image: `${BASE_URL}/go-quest-adventures/solution/cover.png`,
  },
}

const Kite = {
  colors: {
    textColor: 'white',
  },
  background: `${BASE_URL}/kite/background.png`,
  landing: {
    'device-1': `${BASE_URL}/kite/landing/device-1.png`,
    'device-2': `${BASE_URL}/kite/landing/device-2.png`,
  },
  banner: `${BASE_URL}/kite/banner.png`,
  reviews: {
    background: `${BASE_URL}/kite/background.png`,
  },
  features: {
    background: `${BASE_URL}/kite/features/background.png`,
    'first-card': {
      device: `${BASE_URL}/kite/features/device-1.png`,
      icon: Icons.Map,
    },
    'second-card': {
      device: `${BASE_URL}/kite/features/device-2.png`,
      icon: Icons.MapPin,
    },
    'third-card': {
      device: `${BASE_URL}/kite/features/device-3.png`,
      icon: Icons.Code,
    },
    'fourth-card': {
      device: `${BASE_URL}/kite/features/device-4.png`,
      icon: Icons.AlertCircle,
    },
    'fifth-card': {
      device: `${BASE_URL}/kite/features/device-5.png`,
      icon: Icons.MessageReport,
    },
  },
  solution: {
    image: `${BASE_URL}/kite/solution/cover.png`,
  },
}

const Skamper = {
  colors: {
    textColor: 'white',
  },
  background: `${BASE_URL}/skamper/background.png`,
  landing: {
    'device-1': `${BASE_URL}/skamper/landing/device-1.png`,
    'device-2': `${BASE_URL}/skamper/landing/device-2.png`,
  },
  banner: `${BASE_URL}/skamper/banner.png`,
  reviews: {
    background: `${BASE_URL}/skamper/background.png`,
  },
  features: {
    background: `${BASE_URL}/skamper/features/background.png`,
    'first-card': {
      device: `${BASE_URL}/skamper/features/device-1.png`,
      icon: Icons.Map,
    },
    'second-card': {
      device: `${BASE_URL}/skamper/features/device-2.png`,
      icon: Icons.MapPin,
    },
    'third-card': {
      device: `${BASE_URL}/skamper/features/device-3.png`,
      icon: Icons.Code,
    },
    'fourth-card': {
      device: `${BASE_URL}/skamper/features/device-4.png`,
      icon: Icons.AlertCircle,
    },
    'fifth-card': {
      device: `${BASE_URL}/skamper/features/device-5.png`,
      icon: Icons.MessageReport,
    },
  },
  solution: {
    image: `${BASE_URL}/skamper/solution/cover.png`,
  },
}

const TheFridaProject = {
  colors: {
    'blue-2': '#81CDE1',
  },
  landing: {
    background: `${BASE_URL}/the-frida-project/landing/background.png`,
  },
  features: {
    'background-1': `${BASE_URL}/the-frida-project/features/background-1.png`,
    'background-2': `${BASE_URL}/the-frida-project/features/background-2.png`,
    'first-card': {
      device: `${BASE_URL}/the-frida-project/features/device-1.png`,
      icon: Icons.Pencil,
    },
    'second-card': {
      device: `${BASE_URL}/the-frida-project/features/device-2.png`,
      icon: Icons.Message,
    },
    'third-card': {
      device: `${BASE_URL}/the-frida-project/features/device-3.png`,
      icon: Icons.Users,
    },
    'fourth-card': {
      device: `${BASE_URL}/the-frida-project/features/device-4.png`,
      icon: Icons.Files,
    },
  },
  solution: {
    image: `${BASE_URL}/the-frida-project/solution/cover.png`,
  },
  banner: `${BASE_URL}/the-frida-project/banner.png`,
}

const Components = {
  others: {
    becon: `${BASE_URL}/components/others/becon.png`,
    frida: `${BASE_URL}/components/others/frida.png`,
    bearable: `${BASE_URL}/components/others/bearable.png`,
    cloudroom: `${BASE_URL}/components/others/cloudroom.png`,
  },
}

export const CaseStudies = {
  AWSCloudroom,
  AWSGirlsTechSeries,
  Bearable,
  Becon,
  Cherry,
  GoQuest,
  Kite,
  Skamper,
  Components,
  TheFridaProject,
}
