const initialState = {
  activity: 'loading',
  network: 'ok',
  fetchQueue: [],
}

export const AppStatus = (state = initialState, action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'SET_APP_ACTIVITY_STATUS':
      newState.activity = action.activity
      return newState
    case 'SET_NETWORK_STATUS':
      newState.network = action.network
      return newState
    case 'SET_NETWORK_QUEUE':
      newState.fetchQueue = action.fetchQueue
      return newState
    default:
      return state
  }
}
