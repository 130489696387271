/* eslint no-restricted-imports: 'off' */
import { Cookies } from '@/lib'

const initialState = {
  userAnalyticsId: null,
  cookiesAccepted: Cookies.get('Session.cookiesAccepted'),
}

export const Session = (state = initialState, action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'ACCEPT_COOKIES':
      newState.cookiesAccepted = 'true'
      return newState
    case 'SET_USER_ANALYTICS_ID':
      newState.userAnalyticsId = action.data.userAnalyticsId
      return newState
    default:
      return state
  }
}
