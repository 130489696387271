import { store } from '../reducers'

function setActivity(activity) {
  store.dispatch({ type: 'SET_APP_ACTIVITY_STATUS', activity })
}

export const AppStatus = {
  setActivity,
}

export default AppStatus
