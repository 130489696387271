/* eslint-disable no-console */
import {
  React,
  View,
  Button,
  Input,
  Validate,
  AnalyticsView,
  Analytics,
  Image,
  Text,
  Link,
  Logger,
} from '@/lib'
import { useState } from '@codeleap/common'
import { Theme, Settings } from '@/app'
import { ContactForm as ContactFormActions } from '@/actions'
import { faker } from '@faker-js/faker'
import { Assets } from '@/assets'

const rocket = Assets.Misc.rocket

const isDev = Settings.IS_DEVELOPMENT

export const ContactForm = () => {

  const initialState = {
    name: isDev ? `CodeLeap Tester` : '',
    email: isDev ? 'tester@codeleap.co.uk' : '',
    phone_number: isDev ? faker.phone.number() : '',
    message: isDev ? faker.lorem.sentences(4) : '',
    status: 'ready',
  }

  const [state, setState] = useState(initialState)
  const [reset, setReset] = useState(0)

  const LARGE_CARD_WIDTH = 440

  const statusDict = {
    ready: 'SUBMIT',
    sending: 'Sending...',
    done: 'Thank you! We will be in touch shortly',
    invalid: 'Oops! Please check above',
    error: 'Could not send message! Try again?',
  }

  const buttonText =
    state.status in statusDict ? statusDict[state.status] : statusDict.ready
  const edited = state.status == 'invalid'
  const loading = state.status == 'sending'

  function validateForm() {
    if (Validate.field(state.name)) {
      return false
    } else if (Validate.email(state.email)) {
      return false
    } else if (Boolean(state.message) && Validate.field(state.message)) {
      return false
    } else if (Validate.phone(state.phone_number)) {
      return false
    } else return true
  }

  function submit() {
    if (validateForm()) {
      setState({ ...state, status: 'sending' })
      const data = {
        name: state.name,
        email: state.email,
        telephone: state.phone_number,
        message: state.message,
      }
      const onSuccess = () => {
        setState({ ...initialState, status: 'done' })
        setTimeout(setReset(reset + 1), 1)
      }
      const onFailure = () => setState({ ...state, status: 'error' })
      ContactFormActions.submit(data, onSuccess, onFailure)
    } else {
      Logger.log('invalid form data')
      setState({ ...state, status: 'invalid' })
    }
  }

  const onChangeText = (e, key) => {
    const newState = Object.assign({}, state)
    newState[key] = e
    if (status != 'done' && status != 'sending' && validateForm()) {
      newState.status = 'ready'
    }
    setState(newState)
    Analytics.formInput({
      label: 'ContactForm typing',
      action: 'key input',
    })
  }

  return (
    <View style={[styles.card, { width: LARGE_CARD_WIDTH }]}>
      <AnalyticsView gaLabel={'ContactForm'} >
        <View style={styles.header}>
          <Image
            alt={'CodeLeap rocket'}
            src={rocket}
            style={styles.rocketImage}
          />
        </View>
        <View variant={'marginBottom:4'}>
          <Input
            gaLabel={'Contact form input'}
            variant={'inverted'}
            label='Name'
            validatorMessage={'Please provide us a contact name'}
            validate={Validate.field}
            onChangeText={(e) => onChangeText(e, 'name')}
            edited={edited}
            reset={reset}
            value={state.name}
            name={'name'}
          />
        </View>
        <View variant={'marginBottom:4'}>
          <Input
            gaLabel={'Contact form input'}
            variant={'inverted'}
            type='email'
            label='Email address'
            validatorMessage={'Please enter a valid email address'}
            validate={Validate.email}
            onChangeText={(e) => onChangeText(e, 'email')}
            edited={edited}
            reset={reset}
            value={state.email}
            name={'email'}
          />
        </View>
        <View variant={'marginBottom:4'}>
          <Input
            gaLabel={'Contact form input'}
            variant={'inverted'}
            type='tel'
            label='Phone number'
            validatorMessage={'Please enter a valid phone number'}
            validate={Validate.phone}
            onChangeText={(e) => onChangeText(e, 'phone_number')}
            edited={edited}
            reset={reset}
            value={state.phone_number}
            name={'phone-number'}
          />
        </View>
        <View variant={'marginBottom:5'}>
          <Input
            gaLabel={'Contact form input'}
            variant={'inverted'}
            multiline
            label='Message'
            validatorMessage={'Please enter a valid message'}
            validate={Validate.field}
            onChangeText={(e) => onChangeText(e, 'message')}
            edited={edited}
            reset={reset}
            textStyle={styles.multiline}
            value={state.message}
            name={'message'}
          />
        </View>
        <Button
          gaLabel={'Contact form'}
          gaAction={'Submit button'}
          variant={'center fullWidth'}
          onPress={submit}
          loading={loading}
          text={buttonText}
        />
        <Text
          variant={`p2 inline marginTop:4 textCenter center grey`}
        >
          Already know what you want?&nbsp;
          <Link
            openNewTab
            style={[styles.link]}
            gaLabel={'Contact Form'}
            to='/get-a-quote'
          >
            Click here
          </Link>
          &nbsp;to get a quote faster!
        </Text>
      </AnalyticsView>
    </View>
  )
}

const styles = {
  card: {
    backgroundColor: Theme.colors.darkest,
    padding: Theme.spacing(6),
    borderRadius: Theme.values.borderRadius.small,
    [Theme.media.down('mid')]: {
      padding: Theme.spacing(2),
      margin: Theme.spacing(2),
      marginBottom: Theme.spacing(4),
      width: 'unset',
      flex: 1,
    },
  },
  multiline: {
    minHeight: '150px',
    marginTop: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      minHeight: '88px',
    },
  },
  header: {
    position: 'relative',
    height: 10,
    width: '100%',
  },
  rocketImage: {
    position: 'absolute !important',
    height: 60,
    width: 60,
    right: -20,
    top: -20,
    [Theme.media.down('mid')]: {
      height: 50,
      width: 50,
      right: -10,
      top: -10,
    },
  },
  link: {
    fontWeight: 'inherit',
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline !important',
    textDecorationColor: 'inherit !important',
    '&:hover': {
      color: `${Theme.colors.primarySolid} !important`,
      textDecorationColor: `${Theme.colors.primarySolid} !important`,
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      opacity: 1,
    },
  },
}

export default ContactForm
