import { Theme } from '@/app'
import { DemoDevice, Reveal } from '@/components'
import tweenProps from '@/components/Reveal/tweenProps'
import { React, View } from '@/lib'

export const BaseCard = (props) => {

  const { height } = Theme.hooks.size()

  const isMobile = Theme.hooks.down('mid')

  return (
    <View style={styles.wrapper}>
      <Reveal
        variant={'fadeOut'}
        offset={height * -0.4}
        duration={height * 1.6}
        disabled={isMobile}
      >
        <div className='willChangeTransform'>
          <Reveal
            variant={'fixedBackground'}
            offset={height * -0.5}
            duration={height * 1.6}
            fixedBackgroundOpacity={tweenProps.fixedBackgroundFullOpacity}
            disabled={isMobile}
          >
            <View style={styles.innerWrapper}>
              {props.children}
            </View>
          </Reveal>
        </div>
      </Reveal>
    </View>
  )
}

const styles = {
  wrapper: {
    width: '100%',
  },
  innerWrapper: {
    height: Theme.values.portfolioCard.height,
    backgroundColor: '#F2F2F2',
    marginBottom: Theme.spacing(5),
    width: '100%',
    borderRadius: Theme.values.borderRadius.largeish,
    [Theme.media.down('mid')]: {
      height: 420 + DemoDevice.height / 2,
      marginBottom: Theme.spacing(2),
    },
  },
}
