import JSCookies from 'js-cookie'

const get = (key) => {
  return JSCookies.get(key)
}

const set = (key, value) => {
  return JSCookies.set(key, value, { expires: 365 })
}

const remove = (key) => {
  return JSCookies.remove(key)
}

export const Cookies = {
  get,
  set,
  remove,
}

export default Cookies
