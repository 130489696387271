import { Theme, Settings } from '@/app'
import callsites from 'callsites'
import { useUserAgent } from '@oieduardorabelo/use-user-agent'
import { detect } from 'detect-browser'
import Logger from './Logger'

function makeRandomClassId() {
  const length = 30
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return `element_${result}`
}

function convertRange(value, inRange, outRange) {
  const result = (value - inRange[0]) * (outRange[1] - outRange[0]) / (inRange[1] - inRange[0]) + outRange[0]
  if (result > outRange[1]) return outRange[1]
  else if (result < outRange[0]) return outRange[0]
  else return result
}

function flatten(object) {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(...Object.keys(o)
        .map(k => {
          if (isNaN(k) || o[k]) {
            return (typeof o[k] === 'object' ?
              _flatten(o[k]) :
              ({ [k]: o[k] }))
          }
        },
        ),
      )
    }(object)),
  )
}

function rejectProps(props) {
  let funcName = ''
  try {
    funcName = callsites()[1].getFunctionName()
  } catch (err) {
  }
  for (const k in props) {
    if (props[k]) {
      Logger.warn(`Prop "${k}" is not supported in <${funcName}/>`)
    }
  }
}

function getIntersection(arrayA = [], arrayB = []) {
  const intersect = arrayA.filter(item => arrayB.includes(item))
  return intersect
}

function parseSourceUrl(args) {
  let res = ''
  const address = args.source || args.src || (typeof args == 'string' && args) || null
  if (address && address?.startsWith?.('/media/')) {
    const tmp = address.substr(1, address.length)
    res = `${Settings.BASE_URL}${tmp}`
  } else if (address) {
    res = address
  } else {
    res = `https://picsum.photos/600?random=${Math.random() * 100}`
  }
  return res
}

function useLimitedPerformance() {
  const { width } = Theme.hooks.size()
  const initialBrowser = detect()
  let uastring = ''
  try {
    uastring = window.navigator.userAgent
  } catch (err) {
    uastring = ''
  }
  const userAgent = useUserAgent(uastring)
  if (width > 2560) {
    return 1
  } else if (width < 900) {
    const platformName = (userAgent?.os?.name || initialBrowser?.os)?.toLowerCase() || ''
    if (platformName.includes('ios')) {
      return 2
    } else {
      return 3
    }
  } else {
    return 0
  }
}

let timerId
function throttle(func, delay) {
  if (timerId) {
    return
  }

  timerId = setTimeout(function () {
    func()
    timerId = undefined
  }, delay)
}

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2 * t * t) + b
  t--
  return (-c / 2 * ((t * (t - 2)) - 1)) + b
}

function scrollTo(id, duration) {
  const element = document.getElementById(id)
  const to = element.getBoundingClientRect().bottom
  Logger.log({ element, to })
  const start = (element && element.scrollTop) || window.pageYOffset,
    change = to - start,
    increment = 20
  let currentTime = 0

  const animateScroll = () => {
    currentTime += increment
    const val = Math.easeInOutQuad(currentTime, start, change, duration)
    window.scrollTo(0, val)
    if (currentTime < duration) {
      window.setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

function loadScript(loadUrl, callback = () => null) {

  const script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) { //IE
    script.onreadystatechange = function () {
      if (script.readyState == 'loaded' ||
                  script.readyState == 'complete') {
        script.onreadystatechange = null
        return callback()
      }
    }
  } else {
    script.onload = function () {
      callback()
    }
  }

  script.src = loadUrl
  document.getElementsByTagName('head')[0].appendChild(script)
}

const debounceTimerId = []

function debounce(func, ref, delay) {
  if (debounceTimerId[ref]) {
    clearTimeout(debounceTimerId[ref])
  }
  debounceTimerId[ref] = setTimeout(function () {
    func()
    debounceTimerId[ref] = undefined
  }, delay)
}

function getMaxContentWidth() {
  const { width } = Theme.hooks.size()
  const safeHorizontalPaddings = Theme.safeHorizontalPaddings()
  const entries = Object.keys(safeHorizontalPaddings)
  const shouldApplyPadding = width < Theme.values.maxContentWidth + (safeHorizontalPaddings[entries[0]] * 2)
  let currentMedia = null
  let maxContentWidth = 0

  entries.forEach(item => {
    if (Theme.hooks.down(item, width)) {
      currentMedia = item
    }
  })

  if (!shouldApplyPadding) {
    maxContentWidth = Theme.values.maxContentWidth
  } else {
    maxContentWidth = width - (safeHorizontalPaddings[currentMedia] * 2)
  }

  const padding = !shouldApplyPadding ? (width - Theme.values.maxContentWidth) / 2 : safeHorizontalPaddings[currentMedia]

  return {
    width: `${maxContentWidth}px`,
    numberWidth: maxContentWidth,
    padding,
  }
}

export const Tools = {
  flatten,
  rejectProps,
  getIntersection,
  parseSourceUrl,
  makeRandomClassId,
  convertRange,
  useLimitedPerformance,
  throttle,
  scrollTo,
  loadScript,
  debounce,
  getMaxContentWidth,
}

export default Tools
