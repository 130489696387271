import { Icon, React, Image, Text, View, Touchable } from '@/lib'
import { Section } from './Section'
import { ItemsSlider } from '@/components'
import { Theme } from '@/app'
import { useCallback } from 'react'
import { Assets } from '@/assets'

export type OthersItem = {
  image: string
  title: string
  description: string
  to: string
  platforms?: ('apple' | 'android' | 'desktop')[]
}[]

type OthersProps = {
  data: OthersItem
}

const data = [
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '3',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '4',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '5',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '6',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '6',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '6',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
  {
    image: Assets.CaseStudies.Components.others.bearable,
    title: '6',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus, congue ullamcorper eleifend quis dictumst eget morbi quisque a. Tincidunt et sed vulputate interdum ut lectus. ',
    platforms: ['android', 'apple'],
    to: 'bearable',
  },
]

const Others = (props: OthersProps) => {

  if (!props.data) return null

  const renderItem = useCallback((item) => {
    return (
      <>
        <View style={styles.imageWrapper}>
          <Image source={item?.image} style={styles.image}/>
        </View>
        <View style={styles.cardContentWrapper}>
          <View variant='column'>
            <Text variant={`h5 white bold marginBottom:2`} text={item.title} />
            <Text variant={`p1 white`} text={item.description} />
          </View>
          <View variant={'row marginTop:5 justifySpaceBetween alignCenter'} style={styles.footerWrapper}>
            <Touchable variant={'row alignCenter'} to={`/case-studies/${item?.to}`} style={styles.readMore}>
              <Text variant={`p1 uppercase marginRight:2`} text={'Read More'} style={{ color: '#8183F8' }} />
              <Image source={Assets.Misc['primary-arrow-right']} style={{ width: 24, height: 24 }}/>
            </Touchable>
            <View variant={'alignCenter'}>
              <Icon path={Assets.Icons.Android} />
              <Icon path={Assets.Icons.Apple} style={{ marginLeft: 16, marginRight: 16 }} />
              <Icon path={Assets.Icons.Desktop} />
            </View>
          </View>
        </View>
      </>
    )
  }, [])

  return (
    <Section title='You may also like'>
      <ItemsSlider data={data} renderItem={renderItem} />
    </Section>
  )
}

export default Others

const styles = {
  wrapper: {
    width: '100%',
  },
  imageWrapper: {
    width: '100%',
    height: 200,
  },
  image: {
    borderTopLeftRadius: Theme.values.borderRadius.small,
    borderTopRightRadius: Theme.values.borderRadius.small,
    objectFit: 'cover',
    height: '100%',
  },
  cardContentWrapper: {
    backgroundColor: '#404040',
    padding: Theme.spacing(5),
    minHeight: 200,
    boxSizing: 'border-box',
    borderRadius: Theme.values.borderRadius.small,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    ...Theme.flex,
    ...Theme.column,
    ...Theme.justifySpaceBetween,
  },
  footerWrapper: {
    [Theme.media.down('xlarge')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
    [Theme.media.down('mid')]: {
      flexDirection: 'row',
    },
  },
  readMore: {
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(4),
    },
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(0),
    },
  },
}
