/* eslint-disable no-console */
import {
  View,
  Touchable,
  CenterWrapper,
  React,
  Image,
  Link,
  Text,
  Logger,
  Icon,
} from '@/lib'
import { Settings, Theme } from '@/app'
import useWindowScroll from 'beautiful-react-hooks/useWindowScroll'
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback'
import { getButtonData } from './data'
import { MobileDropdown } from './MobileDropdown'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { Assets } from '@/assets'

const NavButton = (props) => {

  const { text, to, pill, items } = props.button

  const [hover, setHover] = useState(false)

  const isMobile = props?.isMobile

  const textVariant = props.mobile ? 'h6' : 'p2'

  const shouldDisplayDropdown = !isMobile && items?.length

  const NavButtonItem = ({ item }) => {

    const [itemHover, setItemHover] = useState(false)

    const onPress = () => {
      const isNavigatingToSamePage = item.to === `/services/${props?.service}`
      if (!isNavigatingToSamePage) {
        setHover(false)
        navigate(item?.to)
      }
    }

    return (
      <Touchable
        key={item?.title}
        to={item?.to}
        onClick={onPress}
        style={[styles.dropdownButton, itemHover && styles.dropdownButtonHover, { minHeight: 85 }]}
        onHover={setItemHover}
      >
        <Icon path={item?.icon} size={24} color={Theme.colors[itemHover ? 'primarySolid' : 'neutral7']}/>
        <View variant={'marginTop:0.5 marginLeft:1 flex column'}>
          <Text variant={`p1 neutral2`} text={item?.title}/>
          <Text variant={`p3 white marginTop:1`} text={item?.description}/>
        </View>
      </Touchable>
    )
  }

  return (
    <View onHover={setHover}>
      <View
        style={[
          styles.navButtonTextWrapper,
          pill && styles.navButtonTextWrapperPill,
        ]}
      >
        <Link
          gaLabel={`HeaderNavButton`}
          variant={textVariant}
          to={!props?.isHomepage && to === '/#services' ? null : to}
          onPress={props.pressed}
          text={text}
          style={[
            styles.navButtonText,
            props.inverted && styles.navButtonTextTransparent,
            pill && styles.navButtonTextButton,
          ]}
        />
      </View>
      {shouldDisplayDropdown ? (
        <motion.div style={styles.dropdownWrapper} animate={{ display: hover ? 'grid' : 'none', opacity: hover ? 1 : 0 }}>
          {items?.map?.(item => <NavButtonItem item={item} key={item}/>)}
        </motion.div>
      ) : null}
    </View>
  )
}

type HeaderNavBarProps = {
  alwaysDark?: boolean
  service?: string
  isHomepage?: boolean
}

export const HeaderNavBar = (props: HeaderNavBarProps) => {

  const { alwaysDark, isHomepage, service } = props

  const onWindowScroll = useWindowScroll()

  const isMobile = Theme.hooks.down('mid')

  const [scrollY, setScrollY] = useState(Settings.IS_BROWSER && window.scrollY)

  const onWindowScrollHander = useThrottledCallback(() => {
    setScrollY(window.scrollY)
  }, [1000])

  onWindowScroll(onWindowScrollHander)

  const { height } = Theme.hooks.size()
  const nearTop = scrollY < height
  const inverted = true
  const showAppbar = true

  const buttons = getButtonData({ service })

  Logger.deb.perf('render HeaderNavBar', { scrollY, props })

  return (
    <View style={styles.wrapper}>
      <View
        style={[
          styles.appBar,
          !showAppbar && styles.appBarHidden,
          nearTop && !alwaysDark && styles.appBarTransparent,
        ]}
        className={'willChangeTransform'}
      >
        <CenterWrapper
          contentContainerStyle={styles.centerWrapper}
        >
          <Touchable
            gaLabel={`HeaderNavButton`}
            gaAction={`logo image`}
            style={styles.logoWrapper}
            to={'/'}
          >
            <Image
              src={Assets.Logos.CodeleapLogoWhite}
              alt='CodeLeap'
              style={styles.logoImage}
            />
          </Touchable>
          <View up={'mid'}>
            <View style={styles.navButtons}>
              {buttons.map((button, idx) => (
                <NavButton
                  key={idx}
                  button={button}
                  inverted={inverted}
                  isHomepage={isHomepage}
                  service={service}
                  isMobile={isMobile}
                />
              ))}
            </View>
          </View>
          <View down={'mid'}>
            <View style={styles.navButtonsMobile}>
              <MobileDropdown
                inverted={inverted}
                isHomepage={isHomepage}
                service={service}
              />
            </View>
          </View>
        </CenterWrapper>
      </View>
    </View>
  )
}

const DROPDOWN_BUTTON_SIZE = 24

const styles = {
  wrapper: {
    flex: 1,
    ...Theme.flex,
    zIndex: 10000,
    position: 'fixed',
  },
  logoWrapper: {
    ...Theme.row,
    ...Theme.flex,
    ...Theme.alignCenter,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  appBar: {
    top: Theme.values.headerBannerHeight,
    height: Theme.values.headerMenuHeight,
    [Theme.media.is('small')]: {
      top: 0,
    },
    left: 0,
    right: 0,
    position: 'fixed',
    flex: 1,
    ...Theme.flex,
    backgroundColor: Theme.colors.black55,
    backdropFilter: 'blur(4px)',
    transition: '300ms',
  },
  appBarHidden: {
    backgroundColor: Theme.colors.black55,
  },
  appBarTransparent: {
    backgroundColor: Theme.colors.black,
  },
  logoImage: {
    width: 'max(100px, min(calc(0px + 9vw), 150px))',
    height: '100%',
    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing(2),
    },
  },
  navButtons: {
    ...Theme.flex,
    ...Theme.row,
    ...Theme.alignCenter,
    paddingRight: Theme.spacing(2),
  },
  navButtonsMobile: {
    ...Theme.flex,
  },
  navButtonTextWrapper: {
    padding: Theme.spacing(1),
  },
  navButtonTextWrapperPill: {},
  navButtonTextButton: {
    borderRadius: Theme.values.borderRadius.small,
    backgroundColor: '#ffffff33',
    '&:hover': {
      textDecorationStyle: 'none !important',
      textDecorationLine: 'none !important',
    },
  },
  navButtonText: {
    zIndex: 'inherit',
    padding: Theme.spacing(1),
    [Theme.media.is('small')]: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    textDecorationLine: 'none',
    color: 'black',
    fontWeight: '500',
    '&:hover': {
      opacity: 0.8,
    },
  },
  navButtonTextTransparent: {
    color: Theme.colors.white,
  },
  centerWrapper: {
    ...Theme.flex,
    ...Theme.row,
    ...Theme.alignCenter,
    ...Theme.justifySpaceBetween,
    padding: 0,
  },
  expandingSection: {
    overflow: 'hidden',
  },
  dropdownWrapper: {
    display: 'grid',
    gridTemplateColumns: '210px 210px',
    gridColumnGap: Theme.spacing(4),
    ...Theme.alignCenter,
    position: 'fixed',
    minWidth: 420 + Theme.spacing(6),
    padding: Theme.spacing(2),
    paddingBottom: Theme.spacing(0),
    backgroundColor: Theme.colors.neutral5,
    borderRadius: Theme.values.borderRadius.small,
  },
  dropdownButton: {
    width: '100%',
    padding: Theme.spacing(1),
    paddingBottom: 0,
    marginBottom: Theme.spacing(2),
    backgroundColor: Theme.colors,
    borderRadius: Theme.values.borderRadius.small,
  },
  dropdownButtonHover: {
    backgroundColor: Theme.colors.neutral8,
  },
  dropdownButtonIcon: {
    width: DROPDOWN_BUTTON_SIZE,
    height: DROPDOWN_BUTTON_SIZE,
    marginTop: Theme.spacing(0.2),
    marginRight: Theme.spacing(1),
  },
  arrowIcon: {
    marginRight: Theme.spacing(2),
  },
}
