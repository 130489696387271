import { store } from '../reducers'
import { Cookies, Logger, url as parseUrl } from '@/lib'
import Fetch from './Fetch'
import moment from 'moment'
import UAParser from 'ua-parser-js'
import isbot from 'isbot'

//
// GETTERS AND SETTERS
//

const init = () => {
  Logger.log('init session')
}

const setUserAnalyticsId = async (userAnalyticsId, returningUser, skipPost) => {
  store.dispatch({ type: 'SET_USER_ANALYTICS_ID', data: { userAnalyticsId }})
  if (!skipPost) {
    const sessionData = await getSessionData(userAnalyticsId)
    await Fetch({
      url: `session`,
      method: 'POST',
      data: sessionData,
      options: {
        json: false,
      },
    })
  }
}

const acceptCookies = () => {
  store.dispatch({ type: 'ACCEPT_COOKIES' })
  Cookies.set('Session.cookiesAccepted', 'true')
}

const getVisits = () => {
  const visits = Cookies.get('Session.visits') || 1
  Cookies.set('Session.visits', parseInt(parseInt(visits) + 1))
  return visits
}

const getLastSeen = () => {
  const lastSeenCookie = Cookies.get('Session.lastSeen')
  Cookies.set('Session.lastSeen', new Date())
  const lastSeenMoment = moment(lastSeenCookie)
  const now = moment()
  const timeElapsed = moment.duration(now.diff(lastSeenMoment, 'minutes'), 'minutes').humanize()
  const lastSeenFormatted = lastSeenMoment.format('ddd, MMM Do YYYY HH:mm [GMT]ZZ')
  const lastSeenStr = `${lastSeenFormatted}, ${timeElapsed} ago`
  const lastSeen = lastSeenStr || ''
  return lastSeen
}

const getFirstSeen = () => {
  const firstSeenCookie = Cookies.get('Session.firstSeen')
  const firstSeenMoment = moment(firstSeenCookie)
  const now = moment()
  const timeElapsed = moment.duration(now.diff(firstSeenMoment, 'minutes'), 'minutes').humanize()
  const firstSeenFormatted = firstSeenMoment.format('ddd, MMM Do YYYY HH:mm [GMT]ZZ')
  const firstSeenStr = `${firstSeenFormatted}, ${timeElapsed} ago`
  const firstSeen = firstSeenStr || ''
  return firstSeen
}

const getABTestManager = () => {
  const manager = Cookies.get('ABTestManager.manager')
  if (manager) {
    try {
      return JSON.parse(manager)
    } catch (e) {
      return manager
    }
  }
}

const getFirstUrl = () => {
  const firstUrl = Cookies.get('Session.firstUrl') || ''
  return firstUrl
}

const getCurrentUrl = () => {
  const raw = window && window.location.href
  return raw
}

const getCurrentParsedUrl = () => {
  const raw = getCurrentUrl()
  const parsed = parseUrl(raw)
  const str = `${parsed.origin}${parsed.pathname}`
  return str
}

const getAdword = () => {
  const url = parseUrl(window.location.href, true)
  return url.query
}

const getSessionData = async (userAnalyticsId = null) => {
  if (!userAnalyticsId) {
    userAnalyticsId = store.getState().Session.userAnalyticsId
  }
  const [location, debugData] = await getIPInfo()
  const referrer = getReferrer()
  const currentUrl = getCurrentUrl()
  const currentParsedUrl = getCurrentParsedUrl()
  const adWord = getAdword()
  const lastSeen = getLastSeen()
  const ABTestManager = getABTestManager()
  const firstSeen = getFirstSeen()
  const firstUrl = getFirstUrl()
  const visits = getVisits()
  const isBot = getIsBot()
  const [userAgentRaw, userAgentParsed] = getUserAgent()
  const sessionData = {
    userAnalyticsId,
    referrer,
    currentUrl,
    currentParsedUrl,
    adWord,
    ABTestManager,
    firstSeen,
    firstUrl,
    lastSeen,
    visits,
    isBot,
    debugData,
    org: debugData.org,
  }

  if (userAgentRaw) sessionData.userAgentRaw = userAgentRaw
  if (userAgentParsed) sessionData.userAgentParsed = userAgentParsed
  if (location) sessionData.location = location

  return sessionData
}

//
// FETCH AND BROWSER STUFF
//

const getReferrer = () => {
  if (window) {
    if (document && document.referrer) {
      return document.referrer
    } else {
      return 'direct'
    }
  } else {
    return null
  }
}

const getUserAgent = () => {
  try {
    const uaString = window.navigator.userAgent
    const parsed = (new UAParser(uaString)).getResult()
    const parsedString = `${parsed?.browser?.name} ${parsed?.browser?.version?.split('.', 1)} on ${parsed?.os?.name} ${parsed?.os?.version}`
    return [uaString, parsedString]
  } catch (err) {
    Logger.warn(err)
    return ['N/A', 'Not available']
  }
}

const getIsBot = () => {
  const isBot = isbot(window.navigator.userAgent)
  return isBot
}

const failureData = {
  org: 'N/A',
  country_code: 'N/A',
}

const getIPInfo = () => {
  return new Promise((resolve) => {
    Fetch({
      url: 'https://ipapi.co/json/',
      method: 'GET',
      options: {
        json: true,
        full_url: true,
      },
      successCallback: (data) => {
        const string = `${data.city}, ${data.country_name}`
        resolve([string, data])
      },
      failureCallback: (err) => {
        Logger.warn(err)
        resolve(['Not available', failureData])
      },
    })
  })
}

export const Session = {
  setUserAnalyticsId,
  acceptCookies,
  init,
  getSessionData,
  manager: getABTestManager(),
}

export default Session
