import React from 'react'
import { DemoDevice, GeneralCardProps } from '@/components'
import { apps } from '../data'
import CardComponents from './components'

const GoQuestMobileCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position = 'left',
  } = props

  const data = apps['go-quest-mobile']

  return (
    <CardComponents.AppRow
      elemName={'goQuest'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      <DemoDevice.DemoRow
        data={data}
        onSelect={onSelect}
      />
    </CardComponents.AppRow>
  )
}

export default GoQuestMobileCard
