/* eslint-disable no-console */
import {
  React,
  View,
  Text,
  CenterWrapper,
  Button,
  Touchable,
  Link,
  equals,
  AnalyticsView,
  Logger,
} from '@/lib'
import { useState, onUpdate } from '@codeleap/common'
import { FaCheck } from 'react-icons/fa'
import { Tilt } from '@jdion/tilt-react'
import { Theme } from '@/app'
import posed from 'react-pose'
import Slider from 'react-slick'

/** @jsx jsx */
import { jsx } from '@emotion/react'

const SectionWrapper = (props) => {
  const { style, contentContainerStyle, children } = props
  return (
    <View style={[style]}>
      <View style={[styles.innerWrapper, contentContainerStyle]}>
        {children}
      </View>
    </View>
  )
}

const PricingTier = (props) => {

  const extraInnerStyles = { backgroundColor: props.meta.backgroundColor }
  const textVariants = `${props.meta.variants}`
  const iconColor =
      props.meta.variants == 'white' ? 'white' : Theme.colors.primarySolid
  const isMobile = Theme.hooks.down('mid')
  const [hover, onHover] = useState(false)

  const priceButtonText = hover ? 'Get a quote' : props.meta.range

  const items = props.meta.items.map((item, index) => {
    return (
      <View style={styles.tierItem} key={index}>
        <View style={styles.checkIconWrapper}>
          <FaCheck
            color={iconColor}
            size={'18'}
            className={'primary-gradient'}
          />
        </View>
        <Text variant={`${textVariants} p2 marginLeft:2`}>{item}</Text>
      </View>
    )
  })

  const renderTierContentDesktop = () => {
    return (
      <CardAnimation
        id={`pricingTierItem_${props.index}`}
        key={props.index}
        css={[styles.tierWrapper, props.index == 1 && styles.centerTier]}
      >
        <Tilt
          style={styles.tilt}
          options={{ scale: 1.04, max: 4, speed: 2000 }}
        >
          <Touchable
            gaLabel={'Pricing'}
            gaAction={`${props.meta.title} card`}
            variant={'plain'}
            style={[styles.tiltInner, extraInnerStyles]}
          >
            <View style={styles.headerWrapper}>
              <Text
                variant={`h4 semiBold ${
                  textVariants == 'white' ? 'white' : 'primary'
                }`}
                text={props.meta.title}
              />
              <Text
                variant={`p1 dark ${textVariants}`}
                text={props.meta.description}
                style={styles.descriptionText}
              />
            </View>
            <View
              style={[
                styles.separator,
                props.meta.variants == 'white' && styles.separatorWhite,
              ]}
            />
            <View style={styles.tierItemsWrapper}>{items}</View>
            <View style={styles.bottomWrapper}>
              <Button
                onHover={onHover}
                gaLabel={'Pricing'}
                gaAction={`/get-a-quote from ${props.meta.title} card button`}
                to={'/get-a-quote'}
                variant={`fullWidth ${
                  props.meta.variants == 'white' ? 'inverted' : 'primary'
                }`}
                text={priceButtonText}
              />
            </View>
          </Touchable>
        </Tilt>
      </CardAnimation>
    )
  }

  const renderTierContentMobile = () => {
    return (
      <Touchable
        gaLabel={'Pricing'}
        gaAction={`${props.meta.title} card`}
        variant={'plain'}
        style={styles.tilt}
        key={props.index}
        id={`pricingTierItem_${props.index}`}
      >
        <View style={[styles.tiltInner, extraInnerStyles]}>
          <View style={styles.headerWrapper}>
            <Text
              variant={`h4 semiBold ${
                textVariants == 'white' ? 'white' : 'primary'
              }`}
              text={props.meta.title}
            />
            <Text
              variant={`p1 dark ${textVariants}`}
              text={props.meta.description}
              style={styles.descriptionText}
            />
          </View>
          <View
            style={[
              styles.separator,
              props.meta.variants == 'white' && styles.separatorWhite,
            ]}
          />
          <View style={styles.tierItemsWrapper}>{items}</View>
          <View style={styles.bottomWrapper}>
            <Button
              gaLabel={'Pricing'}
              gaAction={`/get-a-quote from ${props.meta.title} card button`}
              to={'/get-a-quote'}
              variant={`fullWidth ${
                props.meta.variants == 'white' ? 'inverted' : 'primary'
              }`}
              text={props.meta.range}
            />
          </View>
        </View>
      </Touchable>
    )
  }
  if (isMobile) {
    return renderTierContentMobile()
  } else {
    return renderTierContentDesktop()
  }
}

export const Pricing = (props) => {

  Logger.deb.perf('render Pricing')

  const [selected, setSelected] = useState(1)
  const [cardHeight, setCardHeight] = useState(null)
  const { width } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  onUpdate(() => {
    const finalHeight =
        document.getElementById('pricingTierItem_1')?.clientHeight +
        Theme.spacing(6)
    if (finalHeight && finalHeight != cardHeight) {
      setCardHeight(finalHeight)
    }
  }, [width])

  const renderTierGroup = (data, index) => {
    const isSelected = selected == index
    const tiers = data?.items?.map?.((tier, idx) => (
      <PricingTier meta={tier} key={idx} index={idx}/>
    ))
    if (isMobile) {
      return (
        <View
          style={[
            styles.sliderWrapper,
            isSelected && styles.sliderWrapperVisible,
          ]}
        >
          <Slider style={styles.slider} {...sliderSettings}>
            {tiers}
          </Slider>
        </View>
      )
    } else {
      return (
        <TierAnimation pose={isSelected ? 'show' : 'hide'}>
          <View
            style={[
              styles.tiersWrapper,
              isSelected && styles.tiersWrapperSelected,
            ]}
          >
            {tiers}
          </View>
        </TierAnimation>
      )
    }
  }

  const renderTierButton = (name, index) => {
    const isSelected = selected == index
    return (
      <Touchable
        onPress={() => setSelected(index)}
        gaLabel={'Pricing'}
        gaAction={`${name} button`}
      >
        <View style={styles.tierButtonWrapper}>
          <Text variant={'bold h6'} text={name}/>
          <View
            style={[
              styles.selectedDecoration,
              isSelected && styles.selectedDecorationActive,
            ]}
          >
            <AnalyticsView
              gaLabel={'Pricing'}
              gaAction={name}
              isActive={isSelected}
            />
          </View>
        </View>
      </Touchable>
    )
  }

  const tierContainerWrapperStyles = {
    minHeight: cardHeight,
  }

  const WrapperComponent = isMobile ? View : CenterWrapper

  const isHomepage = props?.service === 'default'

  const contactPath = !isHomepage ? `/services/${props?.service}/#contact` : '#contact'

  return (
    <WrapperComponent style={[styles.wrapper, { paddingTop: Theme.spacing(isHomepage ? 0 : 10) }]} id={'pricing'}>
      <SectionWrapper
        style={styles.sectionWrapper}
        contentContainerStyle={styles.sectionContent}
      >
        <View variant={'flex center column marginBottom:8'}>
          <Text text={`Simple, transparent pricing`} variant={'h2 primary'}/>
        </View>
        <View style={styles.tierSelectWrapper}>
          {renderTierButton(props?.data?.project.title, 1)}
          {renderTierButton(props?.data?.monthly.title, 0)}
          <View style={styles.wideSeparator}/>
        </View>
        <View
          style={[styles.tiersContainerWrapper, tierContainerWrapperStyles]}
        >
          {renderTierGroup(props?.data?.project, 1)}
          {renderTierGroup(props?.data?.monthly, 0)}
        </View>
        <View>
          <View variant={`flex center column marginBottom:6 marginTop:${selected === 1 ? 6 : 10}`}>
            <Text variant={'p1'} text={'Want to know more?'}/>
            <View variant={'row'}>
              <Text variant={'p1'} text={'See our blog article '}/>
              <Link
                gaLabel={'Pricing'}
                gaAction={
                  '/blog/how-we-price/ from "See our blog article How we price!" button'
                }
                to={'/blog/how-we-price/'}
                variant={'space p1'}
                text={'How\u00A0we\u00A0price!'}
              />
            </View>
          </View>
          <View
            variant={'flex center column'}
            style={styles.customOfferWrapper}
          >
            <Text variant={'textCenter inline maxWidth:4 p2'}>
                If you can’t find a plan which meets all your requirements just{' '}
              <Link
                gaLabel={'Pricing'}
                gaAction={'/#contact from "drop us a line" button'}
                href={contactPath}
                className='underline'
              >
                  drop us a line
              </Link>{' '}
                and we’ll be happy to prepare a custom offer free of charge.
            </Text>
          </View>
        </View>
      </SectionWrapper>
    </WrapperComponent>
  )
}

const sliderSettings = {
  dots: false,
  adaptiveHeight: false,
  autoplay: false,
  slidesToShow: 1.3,
  infinite: false,
}

const TierAnimation = posed.div({
  show: {
    staggerChildren: 50,
  },
  hide: {
    staggerChildren: 50,
  },
})

const CardAnimation = posed.div({
  show: {
    opacity: 1,
    transform: 'translate3d(0, 0px, 0) scale(1)',
    transition: {
      duration: 500,
      delay: 200,
      ease: 'circOut',
    },
  },
  hide: {
    opacity: 0,
    transform: 'translate3d(0, 30px, 0) scale(0.96)',
    transition: {
      ease: 'circOut',
      duration: 350,
      delay: 0,
    },
  },
})

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Pricing, areEqual)

export const styles = {
  wrapper: {
    backgroundColor: 'white',
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tiersContainerWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
  },
  tiersWrapper: {
    position: 'absolute',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
  },
  tiersWrapperSelected: {
    pointerEvents: 'auto',
  },
  tierWrapper: {
    display: 'flex',
    flex: 1,
  },
  centerTier: {
    marginLeft: Theme.spacing(6),
    marginRight: Theme.spacing(6),
    [Theme.media.down('xlarge')]: {
      marginLeft: Theme.spacing(4),
      marginRight: Theme.spacing(4),
    },
    [Theme.media.down('large')]: {
      marginLeft: Theme.spacing(2),
      marginRight: Theme.spacing(2),
    },
  },
  tilt: {
    background: Theme.colors.primary,
    padding: 3,
    borderRadius: Theme.values.borderRadius.small,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
    zIndex: 10,
    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(2),
    },
  },
  tiltInner: {
    height: '100%',
    borderRadius: Theme.values.borderRadius.small - 2,
    display: 'flex',
    flexDirection: 'column',
    padding: Theme.spacing(4),
    [Theme.media.down('xlarge')]: {
      padding: Theme.spacing(3),
    },
    [Theme.media.down('large')]: {
      padding: Theme.spacing(2),
    },
    [Theme.media.down('mid')]: {
      minHeight: 480,
    },
  },
  separator: {
    height: 2,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: Theme.values.borderRadius.tiny,
    background: Theme.colors.primary,
    marginBottom: Theme.spacing(3),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(1),
    },
  },
  separatorWhite: {
    background: 'white',
  },
  tierItemsWrapper: {
    textAlign: 'left',
    marginBottom: Theme.spacing(4),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  tierItem: {
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
    display: 'flex',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'max(17vh, 150px)',
    [Theme.media.down('xlarge')]: {
      height: 'max(15vh, 140px)',
    },
  },
  bottomWrapper: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  descriptionText: {
    marginTop: '7%',
  },
  image: {
    width: 120,
    height: 120,
    overflow: 'visible',
    objectFit: 'contain',
    marginBottom: Theme.spacing(4),
    marginTop: Theme.spacing(4),
  },
  checkIconWrapper: {
    width: 20,
    height: 20,
    ...Theme.center,
  },
  tierSelectWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: Theme.spacing(6),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(4),
    },
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  tierButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: Theme.spacing(4),
    marginRight: Theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedDecoration: {
    background: Theme.colors.primary,
    marginTop: Theme.spacing(1),
    width: '120%',
    height: 6,
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2,
    zIndex: 1,
    transform: 'scaleX(0)',
    opacity: 0,
    transition: '300ms',
  },
  selectedDecorationActive: {
    transform: 'scaleX(1)',
    opacity: 1,
    transition: '300ms',
  },
  wideSeparator: {
    width: '100vw',
    height: 1,
    backgroundColor: Theme.colors.light,
    position: 'absolute',
    bottom: 0,
    zIndex: 0,
  },
  pricingMethodDescription: {
    marginTop: Theme.spacing(10),
    marginBottom: Theme.spacing(10),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(6),
    },
  },
  customOfferWrapper: {
    marginTop: Theme.spacing(6),
    marginBottom: Theme.spacing(6),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(4),
      marginBottom: 0,
    },
    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(4),
      marginRight: Theme.spacing(4),
    },
  },
  sliderWrapper: {
    opacity: 0,
    transform: 'translate3d(0, 30px, 0)',
    transition: '500ms',
    position: 'absolute',
    ...Theme.flex,
    pointerEvents: 'none',
  },
  sliderWrapperVisible: {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
    transition: '500ms',
    pointerEvents: 'auto',
  },
  slider: {
    flex: 1,
    width: '100vw',
  },
  innerWrapper: {
    ...Theme.paddingVertical(12),
    [Theme.media.down('mid')]: {
      ...Theme.paddingVertical(8),
    },
  },
}

