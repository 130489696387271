import React from 'react'
import { DemoDevice, GeneralCardProps } from '@/components'
import { apps } from '../data'
import CardComponents from './components'

const BeconMobileCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position = 'right',
  } = props

  const data = apps['becon-mobile']

  return (
    <CardComponents.AppRow
      elemName={'becon'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      <DemoDevice.DemoRow
        data={data}
        onSelect={onSelect}
      />
    </CardComponents.AppRow>
  )
}

export default BeconMobileCard
