import { React, Image, View } from '@/lib'
import { Tilt } from '@jdion/tilt-react'
import { Theme } from '@/app'
import { Assets } from '@/assets'

const images = [
  Assets.Logos.ClutchTopAppDev,
  Assets.Logos.ClutchTopUX,
  Assets.Logos.ClutchMachineLearning,
]

const Item = (image) => {
  return (
    <Tilt key={image} options={{ scale: 1.1, max: 10, speed: 5000 }}>
      <Image alt={'clutch logo image'} src={image} style={styles.clutchImage}/>
    </Tilt>
  )
}

export const Clutch = () => {
  return (
    <View style={styles.clutchImagesWrapper}>
      <View style={styles.clutchImagesInnerWrapper} variant={`row`}>
        {images.map((image) => Item(image))}
      </View>
    </View>
  )
}

export default Clutch

const styles = {
  clutchImagesWrapper: {
    [Theme.media.down('small')]: {
      display: 'flex',
      ...Theme.center,
    },
  },
  clutchImagesInnerWrapper: {
    [Theme.media.down('small')]: {
      width: '80%',
      justifyContent: 'space-between',
    },
  },
  clutchImage: {
    width: '6vw',
    marginRight: Theme.spacing(5),
    [Theme.media.up('xxlarge')]: {
      width: '5vw',
    },
    [Theme.media.down('xlarge')]: {
      width: '8vw',
    },
    [Theme.media.down('largeish')]: {
      width: '10vw',
    },
    [Theme.media.down('mid')]: {
      width: '12vw',
    },
    [Theme.media.down('small')]: {
      width: '20vw',
      marginRight: Theme.spacing(0),
    },
  },
}
