/* eslint no-console: "off" */
/* eslint no-await-in-loop: "off" */

import { Settings } from '@/app'
import { Logger } from '@/lib'

function appendSlash(url) {
  if (!url.includes('?') && url.charAt(url.length - 1) != '/') {
    url += '/'
  }
  return url
}

export const Fetch = async (params) => {

  Logger.info('Fetch', { url: params.url, params })

  let options = {
    full_url: false,
    json: true,
    multipart: false,
  }
  if (params.hasOwnProperty('options')) {
    options = params.options
  }
  let AUTH_HEADER
  if (options.multipart) {
    AUTH_HEADER = {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    }
  } else {
    AUTH_HEADER = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const url = options.full_url ? params.url : appendSlash(Settings.BASE_URL + params.url)
  const data = params.data && (options.multipart ? params.data : JSON.stringify(params.data))
  const headers = params.hasOwnProperty('headers') ? params.headers : AUTH_HEADER
  const mode = options.no_cors ? 'no-cors' : 'cors'

  try {
    const response = await fetch(url, {
      method: params.method,
      body: data,
      headers: headers,
      mode: mode,
    })
    if (response && response.ok) {
      if (params.hasOwnProperty('successCallback')) {
        if (options.json) {
          try {
            const json = await response.json()
            params.successCallback(json)
          } catch (err) {
            Logger.warn('> > > > > > INTERNAL ERROR < < < < < <', { err })
            params.successCallback()
          }
        } else {
          params.successCallback(response)
        }
      }
    } else {
      const status = response.status
      const bodyText = response?._bodyText
      Logger.warn('> > > > > > SERVER ERROR < < < < < <', { url, status, params, bodyText, text: await response?.text(), response, AUTH_HEADER })
      if (params.hasOwnProperty('failureCallback')) {
        params.failureCallback(response)
      } else if (bodyText) {
        Logger.warn(bodyText)
      }
    }
    return true
  } catch (err) {
    Logger.warn('> > > > > > NETWORK ERROR < < < < < <', { url, err, params, AUTH_HEADER })
    if (params.hasOwnProperty('failureCallback')) {
      params.failureCallback('NETWORK_ERROR')
    }
    return false
  }
}

export default Fetch
