import { View, React } from '@/lib'
import { ShowcaseProps } from '.'
import { MobileComponents, PortfolioCards } from '@/components'

const AppsShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <PortfolioCards.Wrapper>
      <PortfolioCards.BeconMobile onSelect={() => setSelected('becon-mobile')}/>
      <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
      <PortfolioCards.KiteMobile onSelect={() => setSelected('kite-mobile')}/>
      <PortfolioCards.CherryMobile onSelect={() => setSelected('cherry-mobile')}/>
      <PortfolioCards.SkamperMobile onSelect={() => setSelected('skamper-mobile')}/>
      <PortfolioCards.GoQuestMobile onSelect={() => setSelected('go-quest-mobile')}/>
      <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
    </PortfolioCards.Wrapper>
  )
}

export default AppsShowcase
