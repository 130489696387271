import { React, Page } from '@/lib'
import { ServicesEnum } from '@/pages'
import Landing from './Landing'
import ServicesOverview from './ServiceOverview'
import Pricing from './Pricing'
import FAQ from './FAQ'
import Portfolio from './Portfolio'
import ClientsTestimonials from './Testimonials'
import WhoWeAre from './WhoWeAre'
import Team from './Team'
import BlogSlider from './Blog'
import ContactUs from './ContactUs'
import PartnerCompanies from './PartnerCompanies'
import ServicesPreview from './ServicesPreview'

const WebMobileHomepage = ({ data, service }) => {
  return (
    <Page fullWidth service={service}>
      <Landing data={data?.landing} service={service}/>
      <ServicesOverview data={data?.servicesInfo}/>
      <Pricing data={data?.pricing} service={service}/>
      <FAQ data={data?.faq}/>
      <Portfolio service={service} />
      <ClientsTestimonials/>
      <WhoWeAre/>
      <Team/>
      <BlogSlider />
      <ContactUs/>
    </Page>
  )
}

const NextGenAIHomepage = ({ data, service }) => {
  return (
    <Page fullWidth service={service}>
      <Landing data={data?.landing} service={service}/>
      <PartnerCompanies />
      <ServicesOverview data={data?.ServicesOverview}/>
      <Portfolio service={service} data={data?.portfolio}/>
      <FAQ data={data?.faq}/>
      <Pricing data={data?.pricing} service={service}/>
      <ClientsTestimonials/>
      <Team/>
      <BlogSlider />
      <ContactUs/>
    </Page>
  )
}

const DefaultHomepage = ({ data }) => {
  return (
    <Page fullWidth isHomepage>
      <Landing data={data?.landing}/>
      <PartnerCompanies/>
      <ServicesPreview />
      <Portfolio service={'default'} data={data?.portfolio}/>
      <ClientsTestimonials/>
      <FAQ data={data?.faq}/>
      <Pricing data={data?.pricing} service={'default'}/>
      <Team/>
      <BlogSlider />
      <ContactUs/>
    </Page>
  )
}

type HomepageViewProps = {
    service: keyof typeof ServicesEnum
    data: any
}

export const HomepageView = (props: HomepageViewProps) => {
  switch (props?.service) {
    case ServicesEnum.apps:
    case ServicesEnum.web:
      return <WebMobileHomepage {...props} />
    case ServicesEnum.NextGen:
    case ServicesEnum.AI:
      return <NextGenAIHomepage {...props}/>
    default:
      return <DefaultHomepage {...props}/>
  }
}
