export const Misc = {
  'codeleap-astronaut-seo': 'assets/misc/codeleap_astronaut_seo.png',
  'codeleap-astronaut': 'assets/misc/codeleap_astronaut.png',
  'world-map-bg': 'assets/misc/world-map-bg.png',
  'design-step': 'assets/misc/design-step.png',
  'founders_square': 'assets/misc/founders_square.jpg',
  'founders_tablet': 'assets/misc/founders_tablet.jpg',
  'nasa-astronaut-original': 'assets/misc/nasa_astronaut_original.jpg',
  'nasa-astronaut-square-transparent': 'assets/misc/nasa_astronaut_square_transparent.png',
  'nasa-astronaut-square': 'assets/misc/nasa_astronaut.jpg',
  'paperplane-json': 'assets/misc/paperplane.json',
  'rocket_science': 'assets/misc/rocket_science.jpeg',
  'rocket': 'assets/misc/rocket.png',
  'social': 'assets/misc/social.png',
  'primary-arrow-right': 'assets/misc/primary-arrow-right.png',
  'discover': 'assets/misc/discover.png',
  'quotes': 'assets/misc/quotes.png',
}
