import { View, Text, CenterWrapper, React, Button, equals, AnalyticsView, Touchable, Logger } from '@/lib'
import { Theme } from '@/app'
import { FaReact } from 'react-icons/fa'

type ItemDataProps = {
  title: string
  content: string[]
}[]

const data: ItemDataProps = [
  {
    title: 'Long-term collaboration',
    content: [
      `We're not looking to profit from you with a short-term project and then see you walk away.`,
      `We want to become your strategic tech partner and help you reach your business goals.
      Our team will go above and beyond to help you achieve your desired outcomes.`,
    ],
  },
  {
    title: 'Latest technologies, best\u00A0methodologies',
    content: [
      `We're a young team experienced in world-leading startups and tech firms.`,
      `We are hungry for the  most advanced technologies and work with agile methodologies to ensure we're
      delivering value to our customer in every development decision.`,
    ],
  },
  {
    title: 'Good stuff.',
    content: [
      `Let's make something people want.`,
      `Whether you're looking to delight customers with a new mobile app or increase level of automation within your
      company's operations – we love and are proud of making beautiful and high-performing products that just feel awesome to use.`,
    ],
  },
]

const Why = (props) => {
  Logger.deb.perf('render Why')
  return (
    <View style={styles.wrapper} id={props.sectionId}>
      <CenterWrapper contentContainerStyle={styles.centerWrapper}>
        <View style={styles.headerWrapper}>
          <Text variant={'h2'} text={`Why CodeLeap?`}/>
        </View>
        <AnalyticsView style={styles.listWrapper} gaLabel={'Why'}>
          {data.map((item, index) => {
            return (
              <Touchable variant={'plain'} gaLabel={'Why'} gaAction={item.title} key={index}>
                <Text variant={'h6 bold marginBottom:2'} text={item.title}/>
                {item.content.map((text, key) => <Text key={key} variant={'p1 marginBottom:2'} text={text}/>)}
              </Touchable>
            )
          })}
        </AnalyticsView>
        <View variant={'center flex marginTop:12'}>
          <Button
            variant={'primary spacing paddingHorizontal:12'}
            to={'/about'}
            text={'About us'}
            gaLabel={'Why'}
            icon={FaReact}
            iconSide={'right'}
            iconColor={'white'}
            iconSize={24}
          />
        </View>
      </CenterWrapper>
    </View>
  )
}

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Why, areEqual)

const gridLarge = Theme.spacing(12)
const gridMid = Theme.spacing(6)
const gridSmall = Theme.spacing(4)
const gridTiny = Theme.spacing(2)

const styles = {
  wrapper: {
    backgroundColor: 'white',
    minHeight: 700,
    paddingTop: Theme.spacing(12),
    paddingBottom: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(8),
      paddingBottom: Theme.spacing(8),
    },
  },
  centerWrapper: {
    justifyContent: ['space-between'],
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    ...Theme.center,
    display: 'flex',
    marginBottom: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(6),
    },
  },
  listWrapper: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, calc(1/3 * 100% - ${gridLarge}px))`,
    gridGap: gridLarge * 3 / 2,
    [Theme.media.down('xlarge')]: {
      gridTemplateColumns: `repeat(3, calc(1/3 * 100% - ${gridMid}px))`,
      gridGap: gridMid * 3 / 2,
    },
    [Theme.media.down('large')]: {
      gridTemplateColumns: `repeat(3, calc(1/3 * 100% - ${gridSmall}px))`,
      gridGap: gridSmall * 3 / 2,
    },
    [Theme.media.down('mid')]: {
      gridTemplateColumns: `repeat(3, calc(1/3 * 100% - ${gridTiny}px))`,
      gridGap: gridTiny * 3 / 2,
    },
    [Theme.media.down('small')]: {
      gridTemplateColumns: `100%`,
      gridGap: gridSmall,
    },
  },
}
