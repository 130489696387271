import { ViewStyles } from '@/app'

const styles = {
  ...ViewStyles,
  default: {
    display: 'inline-flex',
    cursor: 'pointer',
    textDecorationStyle: 'none',
    transition: '300ms',
  },
  plain: {
    cursor: 'unset',
    display: 'unset',
    textDecorationStyle: 'unset',
  },
  deep: {
    '&:active': {
      transform: 'scale(0.985)',
      transition: '120ms',
    },
    transition: '120ms',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.7,
  },
}

export default styles
