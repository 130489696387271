import Fetch from './Fetch'
import { Analytics, Logger } from '@/lib'
import { Session } from '@/actions'

const submit = async (data, onSuccess = () => null, onFailure = () => null) => {
  const sessionData = await Session.getSessionData()
  const uploadData = {
    ...sessionData,
    ...data,
  }
  Fetch({
    url: 'enquiries/form_submit/',
    method: 'POST',
    data: uploadData,
    options: {
      json: false,
    },
    successCallback: () => {
      const params = {
        category: 'Enquiry',
        action: 'Contact form submit',
        label: 'Submit form (conversion)',
      }
      Analytics.event(params)
      onSuccess()
    },
    failureCallback: () => {
      Logger.error('Failed to submit contact form')
      onFailure()
    },
  })
}

const submitQuote = async (data, onSuccess = () => null, onFailure = () => null) => {
  const sessionData = await Session.getSessionData()
  const uploadData = {
    ...sessionData,
    ...data,
  }
  Fetch({
    url: 'enquiries/quote_form_submit/',
    method: 'POST',
    data: uploadData,
    options: {
      json: false,
    },
    successCallback: () => {
      const params = {
        category: 'Enquiry',
        action: 'Quote form submit',
        label: 'Submit form (conversion)',
      }
      Analytics.event(params)
      onSuccess()
    },
    failureCallback: () => {
      Logger.error('Failed to submit quote form')
      onFailure()
    },
  })
}

export const ContactForm = {
  submit,
  submitQuote,
}

export default ContactForm
