import { Tools } from '@/lib'
import { Demo } from './Demo'
import { DemoRow } from './Row'
import { DemoSlider } from './Slider'
import { Theme } from '@/app'

export const getDemoImageScale = (width, adjust = 0.56) => {
  let actualScale = adjust
  if (width <= Theme.breakpoints.tiny) {
    actualScale = adjust * 0.9
  } else if (width <= Theme.breakpoints.mid) {
    actualScale = adjust * 1.05
  } else if (width <= Theme.breakpoints.large) {
    actualScale =
            adjust *
            Tools.convertRange(
              width,
              [Theme.breakpoints.mid, Theme.breakpoints.large],
              [0.65, 0.85],
            )
  } else if (width <= Theme.breakpoints.xlarge) {
    actualScale =
            adjust *
            Tools.convertRange(
              width,
              [Theme.breakpoints.large, Theme.breakpoints.xlarge],
              [0.65, 0.9],
            )
  } else if (width <= Theme.breakpoints.xxlarge) {
    actualScale =
            adjust *
            Tools.convertRange(
              width,
              [Theme.breakpoints.xlarge, Theme.breakpoints.xxlarge],
              [0.9, 1],
            )
  }
  return actualScale
}

const demoDevice = { width: 427, height: 864 } // iPhone X from react-demoDevice-preview

export const DemoDevice = {
  Demo,
  DemoSlider,
  DemoRow,
  getDemoImageScale,
  width: demoDevice.width,
  height: demoDevice.height,
}

export default DemoDevice
