import { Theme, TextStyles } from '@/app'

const gridLrg = Theme.spacing(4)
const gridReg = Theme.spacing(2)
const gridSml = Theme.spacing(1)
const gridItems = 4
const gridItemsSmall = 2

export const time = 6000

export const styles = {
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: 'black',
    justifyContent: 'center',
    overflow: 'hidden',
    marginBottom: Theme.spacing(12),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(8),
    },
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(4),
    },
  },
  innerWrapper: {
    flex: 1,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    maxHeight: 150,
    maxWidth: 150,
    objectFit: 'contain',
    [Theme.media.down('xlarge')]: {
      maxHeight: 120,
      maxWidth: 120,
    },
    [Theme.media.down('large')]: {
      maxHeight: '68%',
      maxWidth: '68%',
      marginRight: '4%',
      marginBottom: '8%',
    },
  },
  contentWrapper: {
    flex: 1,
    width: '100%',
  },
  grid: {
    flex: 1,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${gridItems}, calc(25% - ${gridLrg}px))`,
    gridGap: gridLrg * gridItems / (gridItems - 1),
    [Theme.media.down('large')]: {
      gridTemplateColumns: `repeat(${gridItems}, calc(25% - ${gridReg}px))`,
      gridGap: gridReg * gridItems / (gridItems - 1),
    },
    [Theme.media.down('mid')]: {
      gridTemplateColumns: `repeat(${gridItemsSmall}, calc(50% - ${gridSml}px))`,
      gridGap: gridSml * gridItemsSmall / (gridItemsSmall - 1),
    },
  },
  itemWrapper: {
    position: 'relative',
    cursor: 'pointer',
    [Theme.media.down('mid')]: {
      position: 'unset',
    },
  },
  itemBackground: {
    background: Theme.colors.darkBackground,
    borderRadius: Theme.values.borderRadius.small,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    overflow: 'hidden',
    padding: Theme.spacing(4),
    [Theme.media.down('large')]: {
      padding: Theme.spacing(2),
    },
    [Theme.media.down('tiny')]: {
      padding: Theme.spacing(1),
    },
  },
  hoverBackground: {
    borderRadius: Theme.values.borderRadius.small,
    background: Theme.colors.primary,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transition: '400ms',
    [Theme.media.down('mid')]: {
      background: '#00000055',
      backdropFilter: 'blur(4px)',
      position: 'fixed',
      pointerEvents: 'none',
      ...Theme.center,
      ...Theme.flex,
    },
  },
  hoverBackgroundActive: {
    transition: '400ms',
    opacity: 1,
    [Theme.media.down('mid')]: {
      pointerEvents: 'auto',
      zIndex: 99999,
    },
  },
  mobileModal: {
    flex: 1,
    background: Theme.colors.primary,
    margin: Theme.spacing(4),
    padding: Theme.spacing(2),
    borderRadius: Theme.values.borderRadius.small,
    transform: 'scale(0.9) translate3d(0, 0, 0)',
    transition: '400ms',
    opacity: 0,
  },
  mobileModalVisible: {
    opacity: 1,
    transform: 'scale(1) translate3d(0, 0, 0)',
    transition: '400ms',
  },
  gone: {
    opacity: 0,
    transition: '100ms',
  },
  ungone: {
    opacity: 1,
    transition: '1200ms',
  },
  itemTitle: {
    ...TextStyles.text.p1,
    ...TextStyles.text.white,
  },
  itemTitleExpandedMobile: {
    ...TextStyles.text.h4,
    ...TextStyles.text.white,
    ...TextStyles.text.bold,
    margin: Theme.spacing(2),
    [Theme.media.down('tiny')]: {
      margin: Theme.spacing(1),
    },
  },
  itemDescription: {
    ...TextStyles.text['p1:responsive'],
    ...TextStyles.text.bold,
    ...TextStyles.text.white,
    margin: Theme.spacing(4),
    [Theme.media.down('xlarge')]: {
      margin: Theme.spacing(1.5),
    },
    [Theme.media.down('large')]: {
      fontWeight: '400',
    },
    [Theme.media.down('mid')]: {
      ...TextStyles.text.p1,
      ...TextStyles.text.bold,
      fontWeight: 'unset',
    },
    [Theme.media.down('tiny')]: {
      margin: Theme.spacing(1),
    },

  },
  seeMoreButtonWrapper: {
    position: 'absolute',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    bottom: 0,
    right: 0,
    paddingRight: Theme.spacing(4),
    paddingBottom: Theme.spacing(2),
    [Theme.media.down('large')]: {
      paddingRight: Theme.spacing(2),
      paddingBottom: Theme.spacing(1.5),
    },
    [Theme.media.down('mid')]: {
      position: 'unset',
      paddingTop: Theme.spacing(2),
    },
  },
  sub1Wrapper: {
    [Theme.media.down('large')]: {
      maxWidth: 720,
    },
    ...Theme.center,
  },
  subtitleText: {
    marginTop: Theme.spacing(8),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(6),
    },
    [Theme.media.down('large')]: {
      marginTop: Theme.spacing(4),
    },
    [Theme.media.down('small')]: {
      maxWidth: '80%',
      ...Theme.center,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  whatWeDoText: {
    marginTop: Theme.spacing(8),
    marginBottom: Theme.spacing(8),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(4),
      marginBottom: Theme.spacing(4),
    },
    [Theme.media.down('large')]: {
      marginTop: Theme.spacing(3),
      marginBottom: Theme.spacing(3),
    },
  },
  topSection: {
    marginTop: Theme.spacing(6),
    marginBottom: Theme.spacing(12),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(10),
      marginBottom: Theme.spacing(10),
    },
    [Theme.media.down('large')]: {
      marginTop: Theme.spacing(8),
      marginBottom: Theme.spacing(8),
    },
  },
}
