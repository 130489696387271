import { Theme } from '@/app'
import { View, Text, CenterWrapper } from '@/lib'
import { isArray } from 'lodash'
import React from 'react'

type SectionProps = React.PropsWithChildren<{
    title?: string
    description?: string | string[]
    wrapperProps?: any
}>

export const Section = (props: SectionProps) => {

  const { title, description, wrapperProps, children } = props

  return (
    <CenterWrapper>
      <View variant={'center'} {...wrapperProps} style={[styles.wrapper, { ...wrapperProps?.style }]}>
        <Text variant={`h2 white`} style={styles.title} text={title}/>
        {isArray(description) ? (
          description.map(item => <Text variant={`p1 textCenter`} style={styles.description} text={item} />)
        ) : (
          <Text variant={`p1 textCenter`} style={styles.description} text={description} />
        )}
        {children}
      </View>
    </CenterWrapper>
  )
}

const styles = {
  wrapper: {
    ...Theme.flex,
    ...Theme.column,
    ...Theme.center,
    paddingTop: Theme.spacing(20),
    paddingBottom: Theme.spacing(20),
    width: '100%',
    [Theme.media.down('largeish')]: {
      paddingTop: Theme.spacing(15),
      paddingBottom: Theme.spacing(15),
    },
  },
  title: {
    marginBottom: Theme.spacing(3),
  },
  description: {
    maxWidth: '70%',
    marginBottom: Theme.spacing(9),
    color: Theme.colors.neutral6,
  },
}
