import { Theme, TextStyles } from '@/app'

const commonPlaintextStyles = {
  ...TextStyles.text.default,
  ...TextStyles.text.inline,
  ...TextStyles.text.light,
  marginBottom: Theme.spacing(1),
  marginTop: Theme.spacing(2),
}

const headerPlaintextStyles = {
  ...TextStyles.text.default,
  ...TextStyles.text.inline,
  ...TextStyles.text.light,
  marginBottom: Theme.spacing(2),
  marginTop: Theme.spacing(3),
}

const plainText = {
  ...TextStyles.text.default,
  ...TextStyles.compatibility.p1,
  ...TextStyles.text.p1,
  ...TextStyles.text.light,
  ...TextStyles.text.inline,
  '& h1': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...TextStyles.compatibility.h1,
    ...TextStyles.text.h1,
    ...TextStyles.text.primary,
    ...TextStyles.text.bold,
    textDecorationLine: 'none !important',
    textDecorationStyle: 'none !important',
  },
  '& h2': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...TextStyles.compatibility.h2,
    ...TextStyles.text.h2,
    ...TextStyles.text.primary,
    ...TextStyles.text.bold,
    textDecorationLine: 'none !important',
    textDecorationStyle: 'none !important',
  },
  '& h3': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...TextStyles.compatibility.h3,
    ...TextStyles.text.h3,
    ...TextStyles.text.bold,
  },
  '& h4': {
    ...commonPlaintextStyles,
    ...headerPlaintextStyles,
    ...TextStyles.compatibility.h4,
    ...TextStyles.text.h4,
    ...TextStyles.text.bold,
  },
  '& h5': {
    ...commonPlaintextStyles,
    ...TextStyles.compatibility.h5,
    ...TextStyles.text.h5,
    ...TextStyles.text.bold,
  },
  '& h6': {
    ...commonPlaintextStyles,
    ...TextStyles.compatibility.h6,
    ...TextStyles.text.h6,
  },
  '& p': {
    ...commonPlaintextStyles,
    ...TextStyles.compatibility.p1,
    ...TextStyles.text.p1,
    ...TextStyles.text.lightGrey,
    lineHeight: '1.6 !important',
    marginTop: 0,
  },
  '& a': {
    ...commonPlaintextStyles,
    ...TextStyles.compatibility.p1,
    ...TextStyles.text.p1,
    ...TextStyles.text.link,
    marginTop: 0,
  },
  '& .inline': {
    ...TextStyles.text.inline,
  },
}

export default {
  plainText,
}
