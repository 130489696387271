const commonTweenStyles = {
  position: 'relative',
}

const animationDistance = '1vh'

const fadeOut = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, ${0}, 0)`,
      opacity: 1,
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, -${animationDistance}, 0)`,
      opacity: 0.2,
    },
  },
  paused: true,
  ease: 'power1.in',
}

const fadeOut2 = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, ${0}, 0)`,
      opacity: 1,
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, -20vh, 0)`,
      opacity: 0,
    },
  },
  paused: true,
  ease: 'power1.in',
}

const exitOpacity = {
  from: {
    css: {
      opacity: 1,
    },
  },
  to: {
    css: {
      opacity: 0,
    },
  },
  paused: true,
  ease: 'power1.in',
}

const opacityInOut = {
  from: {
    css: {
      opacity: 0,
    },
  },
  to: {
    css: {
      opacity: 1,
    },
  },
  paused: true,
}

const opacityIn = {
  from: {
    css: {
      opacity: 0,
    },
  },
  to: {
    css: {
      opacity: 1,
    },
  },
  paused: true,
}

const fadeIn = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, ${animationDistance}, 0)`,
      opacity: 0.1,
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, ${0}, 0)`,
      opacity: 1,
    },
  },
  paused: true,
  ease: 'power1.out',
}

const fadeInOutParallax = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, ${animationDistance}, 0)`,
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: `translate3d(0, -${animationDistance}, 0)`,
    },
  },
  paused: true,
  ease: 'slow(0.4, 1, false)',
}

const fadeInOutOpacity = {
  from: {
    css: {
      ...commonTweenStyles,
      opacity: 0,
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      opacity: 1,
    },
  },
  paused: true,
  ease: 'slow(0.4, 1, true)',
}

const unveal = {
  from: {
    css: {
      transform: `translate3d(-5vw, ${animationDistance}, 0) skewX(0deg) scaleX(1.3) scaleY(1.5)`,
    },
  },
  to: {
    css: {
      transform: `translate3d(100vw, -${animationDistance}, 0) skewX(-80deg) scaleX(1.3) scaleY(1.5)`,
    },
  },
  paused: true,
}

const unvealMobile = {
  from: {
    css: {
      transform: `translate3d(-5vw, ${animationDistance}, 0) skewX(-30deg) scaleX(1.5) scaleY(1.5)`,
    },
  },
  to: {
    css: {
      transform: `translate3d(150vw, -${animationDistance}, 0) skewX(-60deg) scaleX(1.5) scaleY(1.5)`,
    },
  },
  paused: true,
}

const parallaxBackground = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, -10vh, 0)',
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, 10vh, 0)',
    },
  },
  paused: true,
}

const parallaxReverse = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, 15vh, 0)',
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, -15vh, 0)',
    },
  },
  paused: true,
}

const parallaxOut = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, 0, 0)',
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, -15vh, 0)',
    },
  },
  paused: true,
}

const fixedBackgroundParallax = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, -12vh, 0)',
    },
  },
  to: {
    css: {
      transform: 'translate3d(0, 12vh, 0)',
      ...commonTweenStyles,
    },
  },
  paused: true,
  ease: 'slow(0.01, 0.3, false)',
}

const fixedBackgroundParallaxMobile = {
  from: {
    css: {
      ...commonTweenStyles,
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
    },
  },
  paused: true,
  ease: 'slow(0.01, 0.3, false)',
}

const fixedBackgroundOpacity = {
  from: {
    css: {
      ...commonTweenStyles,
      opacity: 0.1,
      transform: 'scale(0.95) translate3d(0, 0, 0)',
    },
  },
  to: {
    css: {
      opacity: 0.7,
      transform: 'scale(1) translate3d(0, 0, 0)',
      ...commonTweenStyles,
    },
  },
  paused: true,
  ease: x => ((Math.sin(2 * Math.PI * (x - (1/4))) + 1) / 2),
}

const fixedBackgroundFullOpacity = {
  from: {
    css: {
      ...commonTweenStyles,
      opacity: 0.1,
      transform: 'scale(0.95) translate3d(0, 0, 0)',
    },
  },
  to: {
    css: {
      opacity: 1,
      transform: 'scale(1) translate3d(0, 0, 0)',
      ...commonTweenStyles,
    },
  },
  paused: true,
  ease: x => ((Math.sin(2 * Math.PI * (x - (1/4))) + 1) / 2),
}

const bottomGradient = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, 50vh, 0)',
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, 0, 0)',
    },
  },
  paused: true,
}

const bottomGradientParallax = {
  from: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, 0, 0)',
    },
  },
  to: {
    css: {
      ...commonTweenStyles,
      transform: 'translate3d(0, -15vh, 0)',
    },
  },
  paused: true,
  ease: 'power3.out',
}

export default {
  commonTweenStyles,
  animationDistance,
  fadeOut,
  fadeOut2,
  exitOpacity,
  opacityInOut,
  opacityIn,
  fadeIn,
  fadeInOutParallax,
  fadeInOutOpacity,
  unveal,
  unvealMobile,
  parallaxBackground,
  parallaxReverse,
  parallaxOut,
  fixedBackgroundParallax,
  fixedBackgroundParallaxMobile,
  fixedBackgroundOpacity,
  fixedBackgroundFullOpacity,
  bottomGradient,
  bottomGradientParallax,
}
