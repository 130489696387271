import { Theme } from '@/app'
import { React, View, Text, Image, Tools } from '@/lib'
import { Section } from './Section'
import { Assets } from '@/assets'
import { Reveal } from '@/components'

export type TestimonialItem = {
  testimonial: string
  name: string
  app: string
}

type TestimonialProps = {
  data: TestimonialItem
}

const Testimonial = (props: TestimonialProps) => {

  const { height } = Theme.hooks.size()

  const isMobile = Theme.hooks.down('mid')
  const downLarge = Theme.hooks.down('large')
  const limitedPerformance = Tools.useLimitedPerformance()

  if (!props.data) return null

  return (
    <Section>

      <Reveal
        variant={'unveal fadeInOut'}
        offset={height * (downLarge ? -0.35 : -0.5)}
        duration={height * 1.25}
        disabled={limitedPerformance == 1 || isMobile}
      >

        <View variant={'row'}>

          <View style={styles.quotesWrapper}>
            <Image source={Assets.Misc.quotes} style={styles.quotes}/>
          </View>

          <View variant={'marginLeft:6'}>
            <Text variant={`h4 white`} style={styles.testimonial} text={props.data.testimonial}/>

            <Text variant={`h5 white marginTop:6 marginBottom:1`} text={props.data.name}/>
            <Text variant={`p1`} style={styles.founderNameText} text={`${props.data.app} founder`}/>
          </View>
        </View>

      </Reveal>

    </Section>
  )
}

const styles = {
  testimonial: {
    maxWidth: '90%',
  },
  quotesWrapper: {
    width: 200,
  },
  quotes: {
    width: '100%',
  },
  founderNameText: {
    color: Theme.colors.neutral6,
  },
}

export default Testimonial
