import { Theme } from '@/app'
import { React, Image, View } from '@/lib'
import DemoDevice, { getDemoImageScale } from '.'
import ReactDevicePreview from 'react-device-preview'
import { styles } from './styles'
import './devices.css'

export const Demo = (props) => {

  const { image, alt, position, style, adjust, imageStyle, device, scale } = props

  const { width } = Theme.hooks.size()

  const actualScale = getDemoImageScale(width, adjust)

  const wrapperStyle = {
    width: DemoDevice.width * actualScale,
    height: DemoDevice.height * actualScale,
  }

  return (
    <View style={[styles.deviceWrapper, wrapperStyle, style]}>
      <ReactDevicePreview
        device={device || 'iphonex'}
        scale={scale ? scale : actualScale}
        position={position || 'portrait'}
      >
        <Image alt={alt} loading={'eager'} src={image} style={imageStyle}/>
      </ReactDevicePreview>
    </View>
  )
}
