import { Theme } from '@/app'
import { React, Icon, View, Text } from '@/lib'
import { Section } from './Section'
import { Tilt } from '@jdion/tilt-react'
import { Reveal } from '@/components'

export type ChallengeItem = {
  description?: string
  items: {
    icon: string
    title: string
    description: string
  }[]
}

type ChallengeProps = {
  data: ChallengeItem
}

const Challenge = (props: ChallengeProps) => {

  const { height } = Theme.hooks.size()

  const isMobile = Theme.hooks.down('mid')

  const gridClassname = `grid${props?.data?.items?.length}x${props?.data?.items?.length}`

  if (!props.data) return null

  return (
    <Reveal
      variant={'fadeInOut'}
      offset={height * -0.35}
      duration={height * 1.1}
      disabled={isMobile}
    >
      <div className={'willChangeTransform'}>
        <Section title='Challenge' description={props.data.description} wrapperProps={{ style: { paddingTop: 0 }}}>
          <View variant={'row'} style={styles[gridClassname]}>
            {props?.data?.items?.map?.((item) => {
              return (
                <Tilt key={item.title} options={{ scale: 1.02, max: 4, speed: 2000 }} style={styles.cardWrapper}>
                  <View style={styles.iconWrapper}>
                    <Icon path={item.icon} style={styles.icon}/>
                  </View>
                  <Text variant={`h5 white marginBottom:2`} text={item.title}/>
                  <Text variant={`p1 white marginBottom:2`} text={item.description} style={styles.description}/>
                </Tilt>
              )
            })}
          </View>
        </Section>
      </div>
    </Reveal>
  )
}

const gridGap = Theme.spacing(2)

const styles = {
  grid3x3: {
    display: 'grid',
    gridTemplateColumns: `calc(33% - ${gridGap}px) calc(33% - ${gridGap}px) calc(33% - ${gridGap}px)`,
    gridGap: (gridGap * 3) / 2,
    [Theme.media.down('xlarge')]: {
      gridTemplateColumns: `calc(33% - ${gridGap}px) calc(33% - ${gridGap}px) calc(33% - ${gridGap}px)`,
      gridGap: (gridGap * 3) / 2,
    },
    [Theme.media.down('large')]: {
      gridTemplateColumns: `calc(33% - ${gridGap}px) calc(33% - ${gridGap}px) calc(33% - ${gridGap}px)`,
      gridGap: (gridGap * 3) / 2,
    },
    [Theme.media.down('largeish')]: {
      gridTemplateColumns: `100%`,
      gridGap: gridGap,
    },
  },
  grid2x2: {
    display: 'grid',
    gridTemplateColumns: `calc(50% - ${gridGap}px) calc(50% - ${gridGap}px)`,
    gridGap: (gridGap * 3) / 2,
    [Theme.media.down('xlarge')]: {
      gridTemplateColumns: `calc(50% - ${gridGap}px) calc(50% - ${gridGap}px)`,
      gridGap: (gridGap * 3) / 2,
    },
    [Theme.media.down('large')]: {
      gridTemplateColumns: `calc(50% - ${gridGap}px) calc(50% - ${gridGap}px)`,
      gridGap: (gridGap * 3) / 2,
    },
    [Theme.media.down('largeish')]: {
      gridTemplateColumns: `100%`,
      gridGap: gridGap,
    },
  },
  cardWrapper: {
    backgroundColor: '#101010',
    padding: Theme.spacing(5),
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: Theme.values.borderRadius.small,
  },
  iconWrapper: {
    ...Theme.flex,
    ...Theme.center,
    backgroundColor: '#2E2E2E',
    marginBottom: Theme.spacing(4),
    width: 48,
    height: 48,
    borderRadius: Theme.values.borderRadius.smallish,
  },
  icon: {
    width: 32,
    height: 32,
  },
  description: {
    color: Theme.colors.neutral6,
  },
}

export default Challenge

