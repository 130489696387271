import { React, View, TextProps } from '@/lib'
import { Section } from '../Section'
import { SplittedCard } from './cards'
import { MainCard } from './cards/MainCard'
import { Theme } from '@/app'

type TextArea = {
  icon: string
  title: string
  description: string
  titleProps?: Partial<TextProps>
  descriptionProps?: Partial<TextProps>
}

type CardItemProps = {
  image?: string
  device?: string
  isSplitted?: boolean
  textArea?: TextArea
  devicePosition?: 'right' | 'left'
  CustomDisplay?: () => void
  items?: Omit<FeatureItem, 'items'>[]
}

type StylesProps = {
  titleColor?: string
  descriptionColor?: string
  iconContainerVariant?: string
}

export type FeatureItem = {
  styles?: StylesProps
  cards: CardItemProps[]
}

type FeaturesProps = {
  data: FeatureItem
}

const Features = (props: FeaturesProps) => {

  const isLargeish = Theme.hooks.down('largeish')

  if (!props.data) return null

  const CurrentCard = ({ item, styles: _styles }: { item: CardItemProps, styles: StylesProps }) => {

    if (item.isSplitted) {

      const Card = isLargeish ? MainCard : SplittedCard

      return (
        <View style={styles.splittedCardsWrapper}>
          {item.items.map((feature, index) => <Card item={feature} index={index} styles={_styles} />)}
        </View>
      )
    }

    return <MainCard item={item} styles={_styles} />

  }

  return (
    <Section>
      {props?.data?.cards?.map?.((feature) => <CurrentCard item={feature} styles={props?.data?.styles} />)}
    </Section>
  )

}

const styles = {
  splittedCardsWrapper: {
    ...Theme.flex,
    ...Theme.fullWidth,
    ...Theme.row,
    [Theme.media.down('largeish')]: {
      ...Theme.column,
    },
  },
}
export default Features
