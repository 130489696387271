import { Theme } from '@/app'

const styles = {
  wrapper: {
    position: 'relative',
  },
  unvealCover: {
    backgroundColor: `black`,
    flex: 4,
    width: '120%',
    height: '100%',
    [Theme.media.down('mid')]: {
      flex: 2,
    },
  },
  unvealGradient: {
    flex: 1,
    background: 'linear-gradient(90deg, transparent, black)',
    transform: 'translate3d(3px, 0, 0)',
  },
  unvealWrapper: {
    position: 'absolute',
    top: 0,
    left: '1%',
    right: 0,
    bottom: 0,
    width: '150%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  bottomGradientTweenWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  bottomGradientWrapper: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  bottomGradient: {
    position: 'absolute',
    background: 'linear-gradient(180deg, transparent, black)',
    left: 0,
    right: 0,
    bottom: 0,
    height: '50vh',
  },
}

export default styles
