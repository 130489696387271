/* eslint-disable no-console */
import { Logger, React, View } from '@/lib'
import { useRef } from '@codeleap/common'
import { Theme } from '@/app'
import { Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import TweenProps from './tweenProps'
import styles from './styles'
import { Tools } from '@/lib'

type RevealProps = React.PropsWithChildren<{
  variant: string
  debug?: any
  offset?: number | string
  duration?: number
  amount?: string
  stopProgressAt?: number
  indicators?: any
  tweenProps?: Object
  disabled?: boolean
}>

export const Reveal = (props: RevealProps) => {

  const elementId = useRef(Tools.makeRandomClassId())

  const {
    children,
    variant,
    debug,
    offset,
    duration,
    amount,
    stopProgressAt,
    indicators,
    tweenProps,
    disabled,
    ...otherProps
  } = props

  const variants = variant.split(' ')

  const { height } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  const revealSceneProps = {
    offset: offset != undefined ? offset : height * -0.4,
    duration: duration != undefined ? duration : height * 0.9,
    indicators: indicators != undefined ? indicators : debug,
  }

  const getUnvealProgress = (progress) => {
    return Tools.convertRange(progress, [0, 1], [0, 1])
  }

  const getOpacityInOutProgress = (progress) => {
    const minOpacity = 0
    const maxOpacity = 1
    const opacity = Math.min(Math.max(1 - (Math.abs(progress - 0.5) * 2), minOpacity) * 2, maxOpacity)
    return Tools.convertRange(opacity, [0, 0.8], [0, 1])
  }

  const getFadeInProgress = (progress) => {
    return Tools.convertRange(progress, [0, 0.3], [0, 1])
  }

  const getFadeOutProgress = (progress) => {
    return Tools.convertRange(progress, [0.7, 1], [0, 1])
  }

  const getFadeOut2Progress = (progress) => {
    return Tools.convertRange(progress, [0, 1], [0, 1])
  }

  const renderUnveal = (progress) => {
    if (!variants?.includes('unveal')) return null
    const unvealProgress = getUnvealProgress(progress)
    if (debug) Logger.log({ unvealProgress })
    const defaultProps = isMobile ? TweenProps.unvealMobile : TweenProps.unveal
    return (
      <Tween {...defaultProps} {...tweenProps} totalProgress={unvealProgress}>
        <View style={styles.unvealWrapper}>
          <View style={styles.unvealGradient}></View>
          <View style={styles.unvealCover}></View>
        </View>
      </Tween>
    )
  }

  const renderFadeOut = (progress, content) => {
    if (!variants?.includes('fadeOut')) return content
    const fadeOutProgress = getFadeOutProgress(progress)
    if (debug) Logger.log({ fadeOutProgress })
    return (
      <div>
        <Tween {...TweenProps.fadeOut} {...tweenProps} totalProgress={fadeOutProgress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderFadeOut2 = (progress, content) => {
    if (!variants?.includes('fadeOut2')) return content
    const fadeOut2Progress = getFadeOut2Progress(progress)
    if (debug) Logger.log({ fadeOut2Progress })
    return (
      <div>
        <Tween {...TweenProps.fadeOut2} {...tweenProps} totalProgress={fadeOut2Progress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderExitOpacity = (progress, content) => {
    if (!variants?.includes('exitOpacity')) return content
    return (
      <div>
        <Tween {...TweenProps.exitOpacity} {...tweenProps} totalProgress={progress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderOpacityInOut = (progress, content) => {
    if (!variants?.includes('opacityInOut')) return content
    const opacityInOutProgress = getOpacityInOutProgress(progress)
    if (debug) Logger.log({ opacityInOutProgress })
    return (
      <div>
        <Tween {...TweenProps.opacityInOut} {...tweenProps} totalProgress={opacityInOutProgress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderOpacityIn = (progress, content) => {
    if (!variants?.includes('opacityIn')) return content
    return (
      <div>
        <Tween {...TweenProps.opacityIn} {...tweenProps} totalProgress={progress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderFadeIn = (progress, content) => {
    if (!variants?.includes('fadeIn')) return content
    const fadeInProgress = getFadeInProgress(progress)
    if (debug) Logger.log({ fadeInProgress })
    return (
      <div>
        <Tween {...TweenProps.fadeIn} {...tweenProps} totalProgress={fadeInProgress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderFadeInOut = (progress, content) => {
    if (!variants?.includes('fadeInOut')) return content
    if (debug) Logger.log({ progress, debug: 'fadeInOut' })
    return (
      <div>
        <Tween {...TweenProps.fadeInOutParallax} {...tweenProps?.fade} totalProgress={progress}>
          <div className={'willChange'}>
            <Tween {...TweenProps.fadeInOutOpacity} {...tweenProps?.opacity} totalProgress={progress}>
              {content}
            </Tween>
          </div>
        </Tween>
      </div>
    )
  }

  const renderParallaxBackground = (progress, content) => {
    if (!variants?.includes('parallaxBackground')) return content
    const tmpProps = {
      from: {
        css: {
          ...TweenProps.commonTweenStyles,
          transform: `translate3d(0, -${amount}, 0)`,
        },
      },
      to: {
        css: {
          ...TweenProps.commonTweenStyles,
          transform: `translate3d(0, ${amount}, 0)`,
        },
      },
    }
    const parallaxBackgroundProps = amount ? tmpProps : {}

    const totalProgress = progress > stopProgressAt ? stopProgressAt : progress

    return (
      <div>
        <Tween {...TweenProps.parallaxBackground} {...parallaxBackgroundProps} {...tweenProps} totalProgress={totalProgress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderParallaxReverse = (progress, content) => {
    if (!variants?.includes('parallaxReverse')) return content
    const tmpProps = {
      from: {
        css: {
          ...TweenProps.commonTweenStyles,
          transform: `translate3d(0, ${amount}, 0)`,
        },
      },
      to: {
        css: {
          ...TweenProps.commonTweenStyles,
          transform: `translate3d(0, -${amount}, 0)`,
        },
      },
      ease: 'none',
    }
    const parallaxReverseProps = amount ? tmpProps : {}
    const totalProgress = progress > stopProgressAt ? stopProgressAt : progress
    return (
      <div>
        <Tween {...TweenProps.parallaxReverse} {...parallaxReverseProps} {...tweenProps} totalProgress={totalProgress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderParallaxOut = (progress, content) => {
    if (!variants?.includes('parallaxOut')) return content
    const tmpProps = {
      from: {
        css: {
          ...TweenProps.commonTweenStyles,
          transform: `translate3d(0, 0, 0)`,
        },
      },
      to: {
        css: {
          ...TweenProps.commonTweenStyles,
          transform: `translate3d(0, -${amount}, 0)`,
        },
      },
    }
    const parallaxOutProps = amount ? tmpProps : {}

    return (
      <div>
        <Tween {...TweenProps.parallaxOut} {...parallaxOutProps} {...tweenProps} totalProgress={progress}>
          {content}
        </Tween>
      </div>
    )
  }

  const renderFixedBackground = (progress, content) => {
    if (!variants?.includes('fixedBackground')) return content
    const parallaxDefaultProps = isMobile ? TweenProps.fixedBackgroundParallaxMobile : TweenProps.fixedBackgroundParallax
    const fixedBackgroundOpacityProps = otherProps?.fixedBackgroundOpacity ? otherProps?.fixedBackgroundOpacity : TweenProps.fixedBackgroundOpacity
    if (debug) Logger.log({ progress, debug: 'fixedBackground' })
    return (
      <div>
        <Tween {...parallaxDefaultProps} {...fixedBackgroundOpacityProps} {...tweenProps} totalProgress={progress}>
          <div className={'willChange'}>
            <Tween {...fixedBackgroundOpacityProps} {...tweenProps} totalProgress={progress}>
              {content}
            </Tween>
          </div>
        </Tween>
      </div>
    )
  }

  const renderBottomGradient = (progress, content) => {
    if (!variants?.includes('bottomGradient')) return content

    if (debug) Logger.log({ progress, debug: 'bottomGradient' })
    return (
      <div>
        {content}
        <View style={styles.bottomGradientTweenWrapper}>
          <Tween {...TweenProps.bottomGradient} {...tweenProps} totalProgress={progress}>
            <div className={'willChange'}>
              <View style={styles.bottomGradientWrapper}>
                <View style={styles.bottomGradient}></View>
              </View>
            </div>
          </Tween>
        </View>
      </div>
    )
  }

  const renderContent = (progress) => {
    return renderFadeIn(progress,
      renderFadeOut(progress,
        renderFadeOut2(progress,
          renderExitOpacity(progress,
            renderOpacityInOut(progress,
              renderFadeInOut(progress,
                renderOpacityIn(progress,
                  renderParallaxBackground(progress,
                    renderParallaxReverse(progress,
                      renderParallaxOut(progress,
                        renderFixedBackground(progress,
                          renderBottomGradient(progress,
                            children,
                          ),
                        ),
                      ),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    )
  }

  if (disabled) return children

  return (
    <Scene {...otherProps} {...revealSceneProps} triggerElement={`#${elementId.current}`}>
      {(progress) => {
        const content = renderContent(progress)
        return (
          <View id={elementId.current} style={styles.wrapper} variant={`${debug ? 'debug' : ''}`}>
            {content}
            {renderUnveal(progress)}
          </View>
        )
      }}
    </Scene>
  )
}

export default Reveal
