import React from 'react'
import { Image, Tools, View } from '@/lib'
import { Theme } from '@/app'
import { DemoDevice, GeneralCardProps, Reveal } from '@/components'
import { Assets } from '@/assets'
import { apps } from '../data'
import CardComponents from './components'

const GoQuestWebCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position = 'right',
  } = props

  const { height } = Theme.hooks.size()

  const isLargeish = Theme.hooks.down('largeish')
  const upLarge = Theme.hooks.up('large')

  const isMobile = Theme.hooks.down('mid')

  const limitedPerformance = Tools.useLimitedPerformance()

  const data = apps['go-quest-web']

  return (
    <CardComponents.AppRow
      elemName={'goQuest'}
      background={data.background}
      position={position}
    >
      <View style={styles.wrapper}>
        <CardComponents.AppDescription data={data} position={position}/>
        {isLargeish ? (
          <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
        ) : (
          <View style={styles.imagesWrapper}>
            <View>
              <Reveal
                variant={'parallaxReverse'}
                amount={'20vh'}
                offset={height * -(upLarge ? 0.5 : 0.2)}
                duration={height * 1.1}
                stopProgressAt={upLarge ? 0.6 : 0.45}
                disabled={isMobile || limitedPerformance > 0}
              >
                <div className={'willChangeTransform'}>
                  <View style={styles.tabletWrapper}>
                    <Image
                      loading={'eager'}
                      src={Assets.Portfolio.GoQuest.web[upLarge ? 'background-tablet-1' : 'mockup']}
                      style={styles.image}
                    />
                  </View>
                </div>
              </Reveal>
            </View>
            {upLarge ? (
              <Reveal
                variant={'parallaxBackground'}
                amount={'20vh'}
                offset={height * -0.5}
                duration={height * 1.1}
                stopProgressAt={0.35}
                disabled={isMobile || limitedPerformance > 0}
              >
                <div className={'willChangeTransform'}>
                  <View style={styles.secondTabletWrapper}>
                    <Image
                      loading={'eager'}
                      src={Assets.Portfolio.GoQuest.web['background-tablet-2']}
                      style={styles.image}
                    />
                  </View>
                </div>
              </Reveal>
            ) : null}
          </View>
        )}
      </View>
    </CardComponents.AppRow>
  )
}

const styles = {
  wrapper: {
    [Theme.media.up('mid')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  imagesWrapper: {
    [Theme.media.up('large')]: {
      display: 'flex',
      minWidth: '50%',
      justifyContent: 'flex-end',
    },
  },
  tabletWrapper: {
    width: `calc(0.3 * ${Theme.values.portfolioCard.width})`,
    height: `calc(0.7 * ${Theme.values.portfolioCard.height})`,
    backgroundColor: Theme.colors.white,
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    right: -160,
    [Theme.media.up('large')]: {
      position: 'absolute',
    },
    [Theme.media.down('large')]: {
      width: `calc(0.6 * ${Theme.values.portfolioCard.width})`,
    },
    [Theme.media.down('largeish')]: {
      height: `calc(0.6 * ${Theme.values.portfolioCard.height})`,
    },
  },
  secondTabletWrapper: {
    width: `calc(0.3 * ${Theme.values.portfolioCard.width})`,
    height: `calc(0.7 * ${Theme.values.portfolioCard.height})`,
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    backgroundColor: Theme.colors.white,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'fill',
  },
}

export default GoQuestWebCard
