import { React, View } from '@/lib'
import { Theme } from '@/app'

type CheckBoxProps = {
  selected: boolean
}

export const CheckBox = (props: CheckBoxProps) => {
  const { selected } = props
  return (
    <View style={styles.wrapper}>
      <View style={[styles.innerWrapper, selected && styles.innerWrapperActive]}>
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    display: 'flex',
    width: 12,
    height: 12,
    padding: 4,
    borderRadius: Theme.values.borderRadius.small,
    borderWidth: 2,
    borderColor: Theme.colors.darkish,
    borderStyle: 'solid',
  },
  innerWrapper: {
    borderRadius: Theme.values.borderRadius.small / 2,
    flex: 1,
    width: '100%',
    height: '100%',
    background: Theme.colors.primary,
    opacity: 0,
    transform: 'scale(0)',
    transition: '180ms cubic-bezier(0.34, 1.56, 0.64, 1)',
  },
  innerWrapperActive: {
    opacity: 1,
    transform: 'scale(1)',
    transition: '180ms cubic-bezier(0.34, 1.56, 0.64, 1)',
  },
}

export default CheckBox
