/* eslint-disable no-console */
import { Logger, Page, React } from '@/lib'
import { onMount, onUpdate } from '@codeleap/common'
import { AppStatus } from '@/actions'
import { Theme } from '@/app'
import { useLocation } from 'react-use'
import animateScrollTo from 'animated-scroll-to'
import { HomepageView } from '@/scenes'
import { FaqItemProps, LandingItemProps, PricingItemProps, ServiceOverviewItemProps } from './services/_types'

const defaultScrollOptions = {
  cancelOnUserAction: true,
  easing: (t) => --t * t * t + 1,
  elementToScroll: null,
  horizontalOffset: 0,
  maxDuration: 12000,
  minDuration: 2500,
  speed: 800,
  verticalOffset: 0,
}

const instaScrollOptions = {
  maxDuration: 0,
  minDuration: 0,
  speed: 0,
}

type BaseHomepageProps = {
  service?: string
  data?: {
    landing: LandingItemProps,
    ServiceOverview: ServiceOverviewItemProps,
    pricing: PricingItemProps,
    faq: FaqItemProps[],
  }
}

export const ServicesEnum = {
  AI: 'ai',
  NextGen: 'next-gen',
  web: 'web',
  apps: 'mobile',
}

export const BaseHomepage = (props: BaseHomepageProps) => {

  const {
    data,
  } = props

  const location = useLocation()

  const { height } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  onMount(() => {
    AppStatus.setActivity(null)
    defaultScrollOptions.elementToScroll = window
    // HACK dumb hack because otherwise anchor scroll from other pages doesn't work -.-
  })

  onUpdate(() => {
    if (location.trigger != 'load') {
      scrollToHash()
    }
  }, [location])

  function getScrollParams(hash, options, offset = 0) {
    const element = document.getElementById(hash.substring(1))
    let scrollToPos = null
    let distance = null
    try {
      const rect = element.getBoundingClientRect()
      const topDistance =
        rect.top -
        (options?.headerMargin ? Theme.values.maxHeaderMenuHeight : 0)
      const viewportCenterDistance =
        (rect.top + rect.bottom) / 2 -
        height / 2 -
        (options?.headerMargin ? Theme.values.maxHeaderMenuHeight : 0)
      distance = options?.center ? viewportCenterDistance : topDistance
      scrollToPos = distance + window.scrollY + offset
    } catch (err) {
      Logger.warn({ err, location })
    }
    return [scrollToPos, distance]
  }

  const scrollToHash = (hash = location.hash, options = {}) => {
    const lastChar = location.href.charAt(location.href.length - 1)
    const isHome = location.href.slice(0, -1) == location.origin
    const shouldScrollTop =
      isHome || lastChar == '#' || hash == '#top' || hash == '#home'
    const animationDistanceThreshold = height * 2.5
    if (shouldScrollTop) {
      if (window.scrollY > animationDistanceThreshold) {
        animateScrollTo(0, { ...defaultScrollOptions, ...instaScrollOptions })
      } else {
        animateScrollTo(0, { ...defaultScrollOptions })
      }
    } else if (hash) {
      if (hash == '#portfolio') {
        const [scrollToPos] = getScrollParams(hash, {}, props?.service === ServicesEnum.web || props?.service === ServicesEnum.apps ? 600 : 0)
        // not really sure why this 600 is needed but it is a quick fix :D
        animateScrollTo(scrollToPos, {
          ...defaultScrollOptions,
          ...options,
        })
      } else if (hash == '#ServiceOverview') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#partnerCompanies') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#services') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#pricing' && props?.service === ServicesEnum.web || props?.service === ServicesEnum.apps) {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile }, 180)
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else {
        const [scrollToPos, distance] = getScrollParams(hash)
        if (distance < animationDistanceThreshold) {
          options = { ...options, ...defaultScrollOptions }
        }
        if (scrollToPos) {
          const offset = hash === '#contact' ? -50 : 0
          // just a little adjust to center better the form when clicking contact in header nav bar
          animateScrollTo(scrollToPos + offset, {
            ...defaultScrollOptions,
            ...instaScrollOptions,
            ...options,
          })
        }
      }
    }
  }

  return (
    <HomepageView data={data} service={props?.service}/>
  )
}

export default BaseHomepage

