import { Theme } from '@/app'
import { Assets } from '@/assets'
import { React, Text, Image, View, CenterWrapper, Button } from '@/lib'
import { DemoDevice, PortfolioItemProps } from '../Portfolio'

export type LandingItem = PortfolioItemProps

type LandingProps = {
  data: LandingItem
}

const renderParagraph = (i, k) => {
  const tiny = Theme.hooks.down('tiny')
  const variants = `${tiny ? 'p1:responsive marginBottom:2' : 'p1 marginBottom:3'} white`
  return <Text key={k} variant={variants} text={i}/>
}

const appStoreLogos = [
  Assets.Logos.AppStore,
  Assets.Logos.PlayStore,
]

const Landing = (props: LandingProps) => {

  const { data } = props

  if (!data) return null

  return (
    <View style={styles.wrapper}>
      <Image source={data.background} style={styles.image}/>

      <CenterWrapper>

        <View style={styles.textAreaWrapper}>
          <View>

            <Image source={props.data.logo} style={[styles.appLogo, props?.data?.extraStyles?.logo]} alt={`${props.data.logo} app logo`}/>
            <Text variant={'p1:responsive thin neutralWhite uppercase marginVertical:1'} text={props.data.subtitle}/>
            <Text variant={'h3 white marginVertical:2'} text={props.data.title}/>

            <View style={styles.descriptionWrapper}>
              {props.data.body.map(renderParagraph)}
            </View>

            <Button
              variant={'inverted uppercase'}
              text={'Get a quote'}
              to={'/get-a-quote'}
              style={styles.getAQuoteButton}
            />

            <View variant={'flex marginTop:3'} style={styles.appStoreLogosWrapper}>
              {!props?.data?.doNotShowAppStoreLogos && appStoreLogos?.map((item) => (
                <Image
                  source={item}
                  key={item}
                  style={styles.appStoreLogos}
                  alt={`${item} logo`}
                />
              ))}
            </View>

          </View>
        </View>

        <View style={styles.deviceWrapper}>
          {props?.data?.display?.()}
        </View>

      </CenterWrapper>

    </View>
  )
}

const styles = {
  wrapper: {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    [Theme.media.down('mid')]: {
      height: 500 + DemoDevice.height * 0.8,
    },
  },
  image: {
    width: '100vw',
    height: '100%',
    objectFit: 'cover',
    position: 'relative',
  },
  textAreaWrapper: {
    position: 'absolute',
    [Theme.media.up('mid')]: {
      top: 0,
      height: '100vh',
      maxWidth: '50%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: Theme.spacing(5),
      ...Theme.justifyCenter,
    },
    [Theme.media.down('mid')]: {
      width: '100%',
      top: `calc(${Theme.values.headerMenuHeight} + 32px)`,
      height: 400,
    },
  },
  descriptionWrapper: {
    maxWidth: '50%',
    [Theme.media.up('xxlarge')]: {
      maxWidth: '40%',
    },
  },
  appStoreLogosWrapper: {
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(2),
    },
  },
  appStoreLogos: {
    height: 40,
    width: 40,
    marginBottom: Theme.spacing(3),
    marginRight: Theme.spacing(2),
  },
  appLogo: {
    height: 60,
    width: 60,
    marginBottom: Theme.spacing(3),
  },
  companiesLogo: {
    height: 80,
    width: 80,
    marginBottom: Theme.spacing(2),
    marginRight: Theme.spacing(2),
    [Theme.media.down('large')]: {
      width: 40,
      height: 40,
    },
  },
  deviceWrapper: {
    position: 'absolute',
    [Theme.media.up('mid')]: {
      height: '100%',
      top: 0,
      width: '50%',
      right: 0,
      ...Theme.flex,
      ...Theme.alignCenter,
    },
    [Theme.media.down('mid')]: {
      left: 0,
      top: 600,
      width: '100%',
    },
  },
  getAQuoteButton: {
    [Theme.media.down('mid')]: {
      minWidth: `calc(100% - ${Theme.spacing(8)}px)`,
    },
    [Theme.media.down('small')]: {
      minWidth: `calc(100% - ${Theme.spacing(4)}px)`,
    },
  },
}

export default Landing
