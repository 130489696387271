import { Text, React, Touchable } from '@/lib'

export type LinkProps = {
  text?: string
  variant?: string
  to?: string
  href?: string
  onPress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  gaLabel?: string
  openNewTab?: boolean
  gaAction?: string
  children?: React.ReactNode
  msg?: string
}

export const Link = (rawProps: LinkProps) => {

  const { to, href, onPress, gaLabel, openNewTab, gaAction, children, ...props } = rawProps

  const textProps = {
    ...props,
    variant: `link ${props.variant || ''}`,
  }

  const touchableProps = children && props

  const content = props.text || props.msg ? <Text {...textProps} /> : children

  return (
    <Touchable
      {...touchableProps}
      gaLabel={gaLabel}
      gaAction={gaAction}
      openNewTab={openNewTab}
      to={to}
      href={href}
      onPress={onPress}
    >
      {content}
    </Touchable>
  )
}

export default Link
