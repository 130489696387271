import { React, View, Image, Text } from '@/lib'
import { Section } from './Section'
import { Theme } from '@/app'
import { motion } from 'framer-motion'
import { Reveal } from '@/components'
import { useState } from 'react'
import { Tilt } from '@jdion/tilt-react'

export type SolutionItem = {
  image: string
  description: string
  items: {
    title: string
    description: string
  }[]
}

type SolutionProps = {
  data: SolutionItem
}

const Solution = (props: SolutionProps) => {

  const isMobile = Theme.hooks.down('mid')

  const [selected, setSelected] = useState(props?.data?.items?.length ? props?.data?.items[0] : null)

  const { height } = Theme.hooks.size()

  if (!props.data) return null

  return (
    <Section
      title={'Solution'}
      description={props.data.description}
    >
      <View style={styles.wrapper}>
        <Reveal
          variant={'parallaxReverse'}
          amount={'15vh'}
          offset={height * -0.45}
          duration={height * 1.2}
          disabled={isMobile}
          stopProgressAt={0.5}
        >
          <div className='willChangeTransform'>
            <Tilt options={{ scale: 1.01, max: 1, speed: 2000 }}>
              <Image source={props.data.image} style={styles.image}/>
            </Tilt>
          </div>
        </Reveal>

        <View style={styles.contentWrapper}>
          {props?.data?.items?.map?.((item, index) => {

            const [hover, setHover] = useState(false)

            const isSelectedItem = item === selected

            const selectedTitleColor = isSelectedItem ? Theme.colors.white : (hover ? Theme.colors.neutral3 : Theme.colors.neutral5)
            const selectedDescriptionColor = isSelectedItem ? Theme.colors.neutral4 : Theme.colors.neutral5
            const borderStyles = isSelectedItem ? styles.selectedBorder : styles.border
            const isLastItem = index === props.data.items.length - 1

            return (
              <motion.div
                style={{ ...styles.itemWrapper, marginBottom: Theme.spacing(isLastItem ? 0 : 6) }}
                onClick={() => setSelected(item)}
                onHoverStart={() => setHover(true)}
                onHoverEnd={() => setHover(false)}
                animate={{
                  transform: isSelectedItem ? `scale(1.01)` : `scale(1)`,
                  opacity: isSelectedItem || hover ? 1 : 0.7,
                  transition: '2000ms',
                }}
              >
                <View style={borderStyles} variant={'marginRight:6'}/>
                <View>
                  <Text variant={`h5 white marginBottom:2`}
                    style={{ color: selectedTitleColor }}
                    text={item.title}
                  />
                  <Text
                    variant={`p1`}
                    text={item.description}
                    style={{ color: selectedDescriptionColor, visibility: isSelectedItem || hover ? 'visible' : 'hidden' }}
                  />
                </View>
              </motion.div>
            )
          })}
        </View>

      </View>
    </Section>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    paddingBottom: Theme.spacing(35),
    [Theme.media.down('mid')]: {
      flexDirection: 'column-reverse',
      paddingBottom: Theme.spacing(10),
    },
  },
  image: {
    width: 600,
    height: 600,
    marginRight: Theme.spacing(12),
    borderRadius: Theme.values.borderRadius.largeish,
    [Theme.media.down('xlarge')]: {
      width: 500,
      height: 500,
    },
    [Theme.media.down('large')]: {
      width: 400,
      height: 400,
    },
    [Theme.media.down('mid')]: {
      marginRight: Theme.spacing(0),
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      marginTop: Theme.spacing(8),
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    cursor: 'pointer',
    ...Theme.alignCenter,
  },
  border: {
    borderWidth: 2,
    borderRadius: Theme.values.borderRadius.round,
    borderStyle: 'solid',
    borderColor: Theme.colors.primarySolid,
    height: '100%',
  },
  selectedBorder: {
    borderWidth: 5,
    borderRadius: Theme.values.borderRadius.round,
    borderStyle: 'solid',
    borderColor: Theme.colors.primarySolid,
    height: '100%',
  },
}

export default Solution
