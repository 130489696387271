import { View, React } from '@/lib'
import { ShowcaseProps } from '.'
import { MobileComponents, PortfolioCards } from '@/components'

const WebShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <PortfolioCards.Wrapper>
      <PortfolioCards.KiteWeb onSelect={() => setSelected('kite-web')}/>
      <PortfolioCards.BeconWeb onSelect={() => setSelected('becon-web')}/>
      <PortfolioCards.AWSCloudroom onSelect={() => setSelected('cloudroom-web')}/>
      <PortfolioCards.AWSGirlsTechSeries onSelect={() => setSelected('aws-girls-tech-series-web')}/>
      <PortfolioCards.GoQuestWeb onSelect={() => setSelected('go-quest-web')}/>
      <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
    </PortfolioCards.Wrapper>
  )
}

export default WebShowcase
