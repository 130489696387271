import React from 'react'
import { Image, Tools, View } from '@/lib'
import { Reveal, DemoDevice, GeneralCardProps } from '@/components'
import { Theme } from '@/app'
import { Assets } from '@/assets'
import CardComponents from './components'
import { apps } from '../data'

const AutoImagingCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position = 'right',
  } = props

  const { height } = Theme.hooks.size()

  const isMobile = Theme.hooks.down('mid')
  const limitedPerformance = Tools.useLimitedPerformance()

  const data = apps['autoimaging-mobile']

  return (
    <CardComponents.AppRow
      elemName={'autoimaging'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      {isMobile ? (
        <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
      ) : (
        <>
          <Reveal
            variant={'parallaxReverse'}
            amount={'15vh'}
            offset={height * -0.3}
            duration={height * 1.1}
            stopProgressAt={0.5}
            disabled={limitedPerformance > 0 || isMobile}
          >
            <div className={'willChangeTransform'}>
              <View style={styles.tabletWrapper}>
                <Image
                  loading={'eager'}
                  src={Assets.Portfolio.Autoimaging.tablet}
                  style={styles.image}
                />
              </View>
            </div>
          </Reveal>
          <Reveal
            variant={'parallaxBackground'}
            amount={'25vh'}
            offset={height * -0.5}
            duration={height * 1.5}
            stopProgressAt={0.3}
            disabled={limitedPerformance > 0 || isMobile}
          >
            <div className={'willChangeTransform'}>
              <View style={styles.squareImageWrapper}>
                <Image
                  loading={'eager'}
                  src={Assets.Portfolio.Autoimaging.square}
                  style={styles.image}
                />
              </View>
            </div>
          </Reveal>
        </>
      )}
    </CardComponents.AppRow>
  )
}

const styles = {
  tabletWrapper: {
    width: `calc(0.55 * ${Theme.values.portfolioCard.width})`,
    height: `calc(0.7 * ${Theme.values.portfolioCard.height})`,
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
  },
  squareImageWrapper: {
    width: `calc(0.2 * ${Theme.values.portfolioCard.width})`,
    height: `calc(0.4 * ${Theme.values.portfolioCard.height})`,
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    backgroundColor: Theme.colors.white,
    position: 'absolute',
    left: '-250px',
    [Theme.media.down('large')]: {
      left: '-200px',
    },
    [Theme.media.down('largeish')]: {
      left: '-220px',
      width: `calc(0.25 * ${Theme.values.portfolioCard.width})`,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'fill',
  },
}

export default AutoImagingCard
