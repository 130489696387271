import React from 'react'
import { DemoDevice, GeneralCardProps } from '@/components'
import { apps } from '../data'
import CardComponents from './components'

const SkamperMobileCard = (props: GeneralCardProps) => {

  const {
    position = 'right',
    onSelect,
  } = props

  const data = apps['skamper-mobile']

  return (
    <CardComponents.AppRow
      elemName={'skamper'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      <DemoDevice.DemoRow
        data={data}
        onSelect={onSelect}
        position={'left'}
      />
    </CardComponents.AppRow>
  )
}

export default SkamperMobileCard
