import ReactDevicePreview from 'react-device-preview'
import { React, Image } from '@/lib'

type DeviceDemoProps = {
    scale: number
    device: string
}

export const Device = (props: DeviceDemoProps) => {

  const {
    scale,
    device,
  } = props

  return (
    <ReactDevicePreview
      device={'iphonex'}
      scale={scale}
      position={'portrait'}
    >
      <Image source={device} style={styles.device}/>
    </ReactDevicePreview>
  )
}

const styles = {
  device: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
}
