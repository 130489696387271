import { Text, React, Touchable, getStyles, Tools } from '@/lib'
import { ButtonStyles, Theme } from '@/app'

export type ButtonProps = {
  text?: string
  msg?: string
  icon?: React.ComponentType<{ size: number; color: string }>
  iconSide?: 'left' | 'right'
  iconColor?: string
  iconSize?: number
  variant?: string
  to?: string
  onPress?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
  gaAction?: string
  gaLabel?: string
  loading?: boolean
  disabled?: boolean
  openNewTab?: boolean
  children?: never
}

export const Button = (props: ButtonProps) => {

  const {
    style,
    textStyle,
    icon,
    iconSide,
    iconColor,
    iconSize,
    gaAction,
    gaLabel,
    loading,
    ...otherProps
  } = props
  const text = props.text || null
  const msg = props.msg || null

  let color = iconColor || Theme.colors.primary

  Tools.rejectProps({ children: props.children })

  const content = []
  let variants = props.variant

  const isSmall = variants?.toLowerCase().includes('small')

  const size = iconSize || (isSmall ? 24 : 30)

  if (props.icon && (props.text || props.msg)) variants = `iconText ${variants}`
  if (props.icon) variants = `icon ${variants}`
  if (props.loading) variants = `loading ${variants}`
  if (props.disabled) variants = `${variants} disabled`
  if (props.disabled || variants?.toLowerCase().includes('white')) color = 'white'

  const styles = getStyles(variants, ButtonStyles)

  const getTextIconStyle = (): React.CSSProperties => {
    if (icon && iconSide == 'left') {
      return { marginLeft: Theme.spacing(2) }
    }
    if (icon && iconSide == 'right') {
      return { marginRight: Theme.spacing(2) }
    }
    return {}
  }

  const keyString = `button-${icon}-${size}-${color}-${iconSide}-${text}`

  icon &&
    !iconSide &&
    content.push(
      <props.icon key={`${keyString}-0`} size={size} color={color}/>,
    )

  if (icon && iconSide == 'left') {
    content.push(
      <props.icon key={`${keyString}-1`} size={size} color={color}/>,
    )
  }

  if (text || msg) {
    content.push(
      <Text
        key={`button-${props.variant}-${text}`}
        style={[styles.text, textStyle, getTextIconStyle()]}
        text={text}
        msg={msg}
      />,
    )
  }

  if (icon && iconSide == 'right') {
    content.push(
      <props.icon key={`${keyString}-2`} size={size} color={color}/>,
    )
  }

  return (
    <Touchable
      style={[styles.wrapper, style]}
      {...otherProps}
      disabled={props.disabled || loading}
      variant={variants}
      gaAction={gaAction}
      gaLabel={gaLabel}
    >
      {content}
    </Touchable>
  )
}

export default Button
