/** @jsx jsx */
/* eslint no-restricted-imports: 'off' */
import { jsx } from '@emotion/react'
import { React, getStyles, Media, Tools, Logger } from '@/lib'
import { Theme, ViewStyles } from '@/app'
import { forwardRef, Ref } from 'react'

export type CustomViewProps = {
  variant?: string
  style?: React.CSSProperties
  onHover?: (hover: boolean) => void
  onPress?: () => void
  to?: string
  is?: keyof typeof Theme.breakpoints
  not?: keyof typeof Theme.breakpoints
  up?: keyof typeof Theme.breakpoints
  down?: keyof typeof Theme.breakpoints
  ignoreWarnings?: boolean
  debug?: boolean
  children?: React.ReactNode
}

export type ViewProps = CustomViewProps & React.HTMLAttributes<HTMLDivElement>

export const View = forwardRef<HTMLDivElement, ViewProps>((rawProps, ref: Ref<HTMLDivElement>) => {

  const {
    to,
    onPress,
    onHover,
    onClick,
    style,
    children,
    is,
    not,
    up,
    down,
    ignoreWarnings,
    ...props
  } = rawProps

  let { variant } = rawProps

  if (!ignoreWarnings) Tools.rejectProps({ onPress, to, onClick })

  const platformMediaQuery = Media.renderToPlatformQuery({ is, not, up, down })
  const shouldRenderToPlatform = Media.shouldRenderToPlatform({ is, not, up, down })

  if (props.debug) {
    Logger.log({ rawProps, props, shouldRenderToPlatform, platformMediaQuery })
  }
  if (!shouldRenderToPlatform) return null

  const handleHover = (hover: boolean) => {
    if (onHover) onHover(hover)
  }

  if (props.debug) {
    variant = variant + ' debug'
  }

  const styles = getStyles(variant, { ViewStyles })

  const divStyles = [
    styles.ViewStyles,
    style,
    platformMediaQuery,
  ]

  if (props.debug) {
    Logger.log({ rawProps, props, divStyles })
  }

  return (
    <div
      {...props}
      ref={ref}
      css={divStyles}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {children}
    </div>
  )
})

export default View

View.whyDidYouRender = false
