import { Theme } from '@/app'

export const styles = {
  portfolioModalWrapper: {
    borderRadius: Theme.values.borderRadius.small,
    background: Theme.colors.primary,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transition: '400ms',
    [Theme.media.down('mid')]: {
      background: '#00000055',
      backdropFilter: 'blur(4px)',
      position: 'fixed',
      pointerEvents: 'none',
      ...Theme.center,
      ...Theme.flex,
    },
  },
  portfolioModalWrapperActive: {
    transition: '400ms',
    opacity: 1,
    [Theme.media.down('mid')]: {
      pointerEvents: 'auto',
      zIndex: 99999,
    },
  },
  portfolioModal: {
    flex: 1,
    background: Theme.colors.primary,
    margin: Theme.spacing(4),
    padding: Theme.spacing(4),
    [Theme.media.down('tiny')]: {
      margin: Theme.spacing(3),
      padding: Theme.spacing(3),
    },
    borderRadius: Theme.values.borderRadius.small,
    transform: 'scale(0.9) translate3d(0, 0, 0)',
    transition: '400ms',
    opacity: 0,
  },
  portfolioModalActive: {
    opacity: 1,
    transform: 'scale(1) translate3d(0, 0, 0)',
    transition: '400ms',
  },
  seeMoreButtonWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    bottom: 0,
    right: 0,
    position: 'unset',
    marginTop: Theme.spacing(2),
  },
  mobileCtaWrapper: {
    marginTop: Theme.spacing(2),
    opacity: 0.8,
  },
  buttonWrapper: {
    flex: 1,
    display: 'flex',
    marginTop: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      marginTop: 0,
      marginBottom: Theme.spacing(2),
    },
  },
}
