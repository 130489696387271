/* eslint-disable max-len */
import { Theme } from '@/app'
import { React, View, Text, CenterWrapper, Icon, Image } from '@/lib'
import { DefaultTechStackData, TechStackData } from './data'
import { Tilt } from '@jdion/tilt-react'
import { isArray } from 'lodash'

export type TechStackItem = {
  items?: TechStackData
  iconColor?: string
  image?: string
  description?: string | string[]
  ExcludeFromStack?: string[]
}

type TechStackProps = {
  data: TechStackItem
}

const Stack = ({ stack, color }) => {

  const title = stack[0]
  const items = stack[1]

  const renderItem = (item) => (
    <View variant={'row alignCenter'} style={styles.itemWrapper}>
      <Icon path={item.icon} style={{ width: 30, height: 30, color }}/>
      <Text variant={`p1 marginLeft:1`} text={item.name} style={styles.neutral6Text}/>
    </View>
  )

  return (
    <Tilt options={{ scale: 1.05, max: 4, speed: 2000 }} key={title} style={styles.stackWrapper}>
      <View style={styles.stackInnerWrapper}>
        <View style={styles.stackItemTitle}>
          <Text variant={`p1 white bold`} text={title}/>
        </View>
        <View style={styles.itemsWrapper}>
          {items?.map?.((item) => renderItem(item))}
        </View>
      </View>
    </Tilt>
  )
}

const TechStack = (props: TechStackProps) => {

  const data = props?.data?.items ? props?.data?.items : DefaultTechStackData

  const stacks = Object.entries(data)

  const description = props?.data?.description ? props?.data?.description : (
    `The tech chosen was our speciality,  enabling fast development of performant user interfaces and a reliable infrastructure. Bearable is now available for both iOS and Android:`
  )

  const isLargeish = Theme.hooks.down('largeish')

  const shouldShowImage = props?.data?.image

  const imageDisplayedStyles = {
    descriptionWrapper: {
      width: isLargeish ? '100%' : '50%',
      display: 'block',
      justifyContent: 'flex-start',
    },
    description: {
      maxWidth: '100%',
    },
  }

  const nonDisplayedImageStyles = {
    descriptionWrapper: {
      width: '100%%',
      display: 'flex',
      justifyContent: 'center',
    },
    description: {
      maxWidth: '70%',
    },
  }

  const imageStyles = shouldShowImage ? imageDisplayedStyles : nonDisplayedImageStyles

  return (
    <CenterWrapper>
      <View style={styles.wrapper} variant={'center'}>

        <Text variant={`h2 white`} style={styles.title} text={'Tech Stack'}/>

        <View style={styles.headerRowWrapper}>
          {shouldShowImage ? (
            <View style={styles.imageWrapper}>
              <Image source={props?.data?.image} style={styles.image}/>
            </View>
          ) : null}
          <View style={imageStyles.descriptionWrapper}>
            {isArray(description) ? (
              description.map(item => <Text variant={`p1 textCenter marginBottom:2`} style={[imageStyles.description, styles.neutral6Text]} text={item} />)
            ) : (
              <Text variant={`p1 textCenter`} style={[imageStyles.description, styles.neutral6Text]} text={description} />
            )}
          </View>
        </View>

        <View style={styles.stacksWrapper}>
          {stacks.map((item) => <Stack stack={item} color={props?.data?.iconColor} />)}
        </View>

      </View>
    </CenterWrapper>
  )
}

const styles = {
  wrapper: {
    ...Theme.flex,
    ...Theme.column,
    ...Theme.center,
    paddingTop: Theme.spacing(20),
    paddingBottom: Theme.spacing(20),
    width: '100%',
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(10),
      paddingBottom: Theme.spacing(10),
    },
  },
  headerRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [Theme.media.down('largeish')]: {
      flexDirection: 'column-reverse',
    },
  },
  imageWrapper: {
    width: '50%',
    marginRight: Theme.spacing(6),
    [Theme.media.down('largeish')]: {
      marginTop: Theme.spacing(4),
      width: '100%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: Theme.values.borderRadius.largeish,
  },
  title: {
    marginBottom: Theme.spacing(3),
  },
  description: {
    marginTop: Theme.spacing(3),
    marginBottom: Theme.spacing(4),
  },
  stacksWrapper: {
    marginTop: Theme.spacing(6),
    width: '100%',
  },
  stackWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      backgroundColor: Theme.colors.black,
    },
  },
  stackInnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#101010',
    padding: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
      ...Theme.justifyCenter,
      padding: Theme.spacing(2),
    },
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [Theme.media.down('mid')]: {
      flexWrap: 'wrap',
      marginTop: Theme.spacing(3),
    },
  },
  itemWrapper: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Theme.colors.neutral6,
    padding: Theme.spacing(2),
    borderRadius: Theme.values.borderRadius.smallish,
    marginRight: Theme.spacing(4),
    [Theme.media.down('largeish')]: {
      padding: Theme.spacing(1),
    },
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
      marginRight: Theme.spacing(2),
    },
  },
  stackItemTitle: {
    [Theme.media.up('mid')]: {
      ...Theme.flex,
      ...Theme.alignCenter,
    },
  },
  neutral6Text: {
    color: Theme.colors.neutral6,
  },
}

export default TechStack

export * from './data'
