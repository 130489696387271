import { Settings } from '@/app'
import { detect } from 'detect-browser'
import { Logger } from '@/lib'
import { useUserAgent } from '@oieduardorabelo/use-user-agent'

export const getStyles = (variant, styleSheets, debug = false) => {
  variant = variant && variant.trim().split(' ')
  const result = {}
  for (const s in styleSheets) {
    result[s] = [styleSheets[s].default]
    if (debug) Logger.log({ result, styleSheets, 'styleSheets[s]': styleSheets[s] })
    for (const v in variant) {
      if (styleSheets[s][variant[v]]) {
        result[s].push(styleSheets[s][variant[v]])
      } else {
        try {
        } catch (err) {
          Logger.warn(err)
        }
      }
    }
  }
  return result
}

export const isLowBrowserCompatibility = () => {
  const initialBrowser = detect()
  let uastring = ''
  try {
    uastring = window.navigator.userAgent
  } catch (err) {
    uastring = ''
  }
  const userAgent = useUserAgent(uastring)

  const browserName = (userAgent?.browser?.name || initialBrowser?.name || 'not_available').toLowerCase()
  const platformName = (userAgent?.os?.name || initialBrowser?.os || 'not_available').toLowerCase()

  let acceptable = []

  if (platformName.includes('ios')) {
    // ios is okay
    acceptable = ['chrome', 'crios', 'fxios', 'ios', 'mobile safari', 'safari', 'firefox']
  } else if (platformName.includes('android')) {
    // android sucks
    acceptable = ['chrome']
  } else {
    // desktop is best
    acceptable = ['chrome', 'firefox', 'safari', 'opera', 'edge']
  }

  if (!Settings.IS_BROWSER || acceptable.includes(browserName)) {
    return false
  } else {
    return true
  }
}

export const StyleTools = {
  getStyles,
  isLowBrowserCompatibility,
}
