import { IconContainerStyles, Theme } from '@/app'
import { React, Icon, View, getStyles } from '@/lib'

export const IconContainer = (props) => {

  const styles = getStyles(props.variant, IconContainerStyles)

  return (
    <View style={styles.wrapper}>
      <Icon path={props?.icon} style={styles.icon} />
    </View>
  )
}

