import { Cookies } from '@/lib'
import isEqual from 'lodash/isEqual'

const manager = {
}

export const ABTestManagerCookiesKey = 'ABTestManager.manager'

const init = () => {
  const managerStoredCookies = Cookies.get(ABTestManagerCookiesKey)
  let parsedManagerCookiesJSON = null

  if (!managerStoredCookies) {
    Cookies.set(ABTestManagerCookiesKey, JSON.stringify(manager))
    return null
  }

  try {
    parsedManagerCookiesJSON = JSON.parse(managerStoredCookies)
  } catch (e) {
    parsedManagerCookiesJSON = managerStoredCookies
  }

  if (!isEqual(Object.keys(manager), Object.keys(parsedManagerCookiesJSON))) {
    const newManager = {
      ...manager,
      ...parsedManagerCookiesJSON,
    }
    Cookies.set(ABTestManagerCookiesKey, newManager)
  }
}

export const ABTestManager = {
  init,
}

export default ABTestManager
