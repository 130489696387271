/* eslint-disable no-console */
import { View, React } from '@/lib'
import { ShowcaseProps } from '.'
import { MobileComponents, PortfolioCards } from '@/components'

const DefaultShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <PortfolioCards.Wrapper>
      <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
      <PortfolioCards.AWSGirlsTechSeries onSelect={() => setSelected('aws-girls-tech-series-web')}/>
      <PortfolioCards.KiteGeneral onSelect={() => setSelected('kite')}/>
      <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
    </PortfolioCards.Wrapper>
  )
}

export default DefaultShowcase
