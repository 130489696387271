import { View, React, equals } from '@/lib'
import { Theme } from '@/app'

export const CenterWrapper = (props) => {
  const { id, style, contentContainerStyle, centerContent = true, children } = props

  const wrapperStyles = centerContent ? [styles.wrapper, style] : [style]
  const innerWrapperStyles = centerContent ? [styles.innerWrapper, contentContainerStyle] : [contentContainerStyle]

  return (
    <View id={id} style={wrapperStyles}>
      <View style={innerWrapperStyles}>
        {children}
      </View>
    </View>
  )
}

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(CenterWrapper, areEqual)

const styles = {
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    width: '100%',
  },
  innerWrapper: {
    flex: 1,
    display: 'flex',
    width: '100%',
    maxWidth: Theme.values.maxContentWidth,
    [Theme.media.down('xxlarge')]: {
      paddingLeft: Theme.safeHorizontalPaddings().xxlarge,
      paddingRight: Theme.safeHorizontalPaddings().xxlarge,
    },
    [Theme.media.down('large')]: {
      paddingLeft: Theme.safeHorizontalPaddings().large,
      paddingRight: Theme.safeHorizontalPaddings().large,
    },
    [Theme.media.down('largeish')]: {
      paddingLeft: Theme.safeHorizontalPaddings().largeish,
      paddingRight: Theme.safeHorizontalPaddings().largeish,
    },
    [Theme.media.down('mid')]: {
      paddingLeft: Theme.safeHorizontalPaddings().mid,
      paddingRight: Theme.safeHorizontalPaddings().mid,
    },
    [Theme.media.down('small')]: {
      paddingLeft: Theme.safeHorizontalPaddings().small,
      paddingRight: Theme.safeHorizontalPaddings().small,
    },
  },
}
