/* eslint no-restricted-imports: 'off' */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment, FC, RefObject } from 'react'
import { React, Analytics, equals, Logger, View, Text } from '@/lib'
import { onUpdate, useRef } from '@codeleap/common'
import { Theme } from '@/app'
import useTimeout from 'beautiful-react-hooks/useTimeout'
import useViewportSpy from 'beautiful-react-hooks/useViewportSpy'

interface AnalyticsViewProps {
  variant?: string
  gaLabel: string
  gaAction?: string
  reportOnEnter?: boolean
  onChangeVisibility?: (isVisible: boolean) => void
  debug?: boolean
  isActive?: boolean
  children?: React.ReactNode
}

const debugDefault = false
const minimumDuration = 1000

export const AnalyticsView: FC<AnalyticsViewProps> = (rawProps) => {
  const {
    variant,
    gaLabel,
    gaAction,
    reportOnEnter,
    onChangeVisibility,
    debug = debugDefault,
    isActive = true,
    ...props
  } = rawProps

  const isMobile = Theme.hooks.down('mid')
  const visibleElement: RefObject<HTMLElement> = useRef<HTMLElement>(null!)
  const lastVisible = useRef(null as Date | null)
  const mounted = useRef(false)
  const isVisible = useViewportSpy(visibleElement, {
    threshold: isMobile ? 0.5 : 0.9,
  })

  const reportVisible = isActive && isVisible
  const reportLabel = gaLabel
  const reportAction = gaAction || 'section view'

  const variants = `${variant} ${
    debug ? (reportVisible ? 'debugGreen' : 'debugOrange') : ''
  }`

  onUpdate(() => {
    if (reportVisible) {
      if (debug) {
        Logger.logger.green('Enter viewport', {
          reportAction,
          reportLabel,
          reportOnEnter,
          mounted,
        })
      }
      lastVisible.current = new Date()
      if (onChangeVisibility) onChangeVisibility(true)
      if (reportOnEnter && mounted.current) {
        sendEnterViewportReport()
      }
    } else if (lastVisible.current) {
      const t1 = new Date()
      const t2 = lastVisible.current
      const diff = t1.getTime() - t2.getTime()
      lastVisible.current = null
      if (debug) logger.yellow('Exit viewport', { reportAction, reportLabel, diff })
      if (onChangeVisibility) onChangeVisibility(false)
      sendTimedReport(diff)
    }
  }, [reportVisible])

  useTimeout(() => {
    if (!mounted.current) mounted.current = true
  }, 1000)

  function sendEnterViewportReport() {
    const params = {
      label: reportLabel,
      action: `enter viewport ${gaAction || ''}`,
    }
    if (debug) logger.red('Send enter viewport report', { gaAction, reportLabel })
    Analytics.enterViewport(params)
  }

  function sendTimedReport(value: number) {
    if (value > minimumDuration) {
      if (debug) logger.red('Send timed report', { reportAction, reportLabel, value })
      const params = {
        label: reportLabel,
        action: reportAction,
        value,
      }
      Analytics.sectionView(params)
    } else {
      if (debug) {
        logger.cyan('View too quick, probably just scrolling', {
          reportAction,
          reportLabel,
          value,
        })
      }
    }
  }
  if (debug) {
    Logger.log({ reportVisible, isVisible, gaLabel, gaAction, props, rawProps })
  }

  if (debug) {
    return (
      <Fragment>
        <View ref={visibleElement} {...props} variant={variants}>
          <Text
            style={[
              styles.analyticsViewLabel,
              reportVisible && styles.analyticsViewLabelVisible,
            ]}
          >
            {gaLabel} {gaAction} - {reportVisible ? 'visible' : 'not visible'}
          </Text>
          {props.children}
        </View>
      </Fragment>
    )
  } else {
    return <View ref={visibleElement} {...props} variant={variants} />
  }
}

AnalyticsView.whyDidYouRender = true

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(AnalyticsView, areEqual)
const styles = {
  analyticsViewLabel: {
    position: 'absolute',
    left: 0,
    backgroundColor: 'rgba(255, 200, 0, 0.8)',
    zIndex: 999999,
  },
  analyticsViewLabelVisible: {
    backgroundColor: 'rgba(100, 200, 0, 0.8)',
  },
}
