/* eslint no-restricted-imports: 'off' */
import { React, getStyles, Analytics, Logger } from '@/lib'
import { useRef } from '@codeleap/common'
import { TouchableStyles } from '@/app'
import { Link as GatsbyLink } from 'gatsby'
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Ref } from 'react'

const miniumHoverDurationForReporting = 500

export type TouchableProps = {
  variant?: string
  style?: React.CSSProperties
  to?: string
  href?: string
  disabled?: boolean
  onPress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onHover?: (hover: boolean) => void
  gaAction?: string
  gaLabel?: string
  openNewTab?: boolean
  children?: React.ReactNode
}

const TouchableCP = (props: TouchableProps, ref: Ref<HTMLDivElement>) => {

  const {
    children,
    onPress,
    to,
    href,
    disabled,
    style,
    onHover,
    gaAction,
    gaLabel,
    openNewTab,
    ...otherProps
  } = props

  let { variant } = props
  const lastHover = useRef<Date | null>(null)
  const linkTarget = href || to

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disabled) {
      Analytics.click({ action: gaAction || linkTarget, label: gaLabel })
      if (openNewTab && linkTarget) {
        Logger.log('Touchable pressed! <Touchable/> opening new tab - event propagation not allowed', { props, otherProps })
        window.open(linkTarget)
        stopAllPropagation(event)
      } else if (onPress && linkTarget) {
        Logger.log('Touchable pressed! <Touchable/> firing onPress and to or href - event propagation allowed', { props, otherProps })
        onPress(event)
      } else if (onPress) {
        Logger.log('Touchable pressed! <Touchable/> firing onPress', { props, otherProps })
        onPress(event)
        stopAllPropagation(event)
      } else if (linkTarget?.startsWith('/#')) {
        Logger.log('Touchable pressed! <Touchable/> hashtag click fired', { props, otherProps })
        // prevents default scroll behaviour when clicking anchors on homepage
        const hash = linkTarget.substring(1)
        const pathname = window.location.pathname
        const isHome = pathname === '/'
        if (isHome) {
          stopAllPropagation(event)
          if (window.history.pushState) {
            window.history.pushState(null, null, hash)
          } else {
            window.location.hash = hash
          }
        }
      } else {
        Logger.log('Touchable pressed! No action defined in <Touchable/>, maybe to or href', { props, otherProps })
      }
    } else {
      Logger.info('Click disabled <Touchable/>')
    }
  }

  const stopAllPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
      event.stopPropagation()
    } catch {
      Logger.log('Failed event.stopPropagation()')
    }
    try {
      event.preventDefault()
    } catch {
      Logger.log('Failed event.preventDefault()')
    }
    try {
      event.nativeEvent.stopImmediatePropagation()
    } catch {
      Logger.log('Failed event.nativeEvent.stopImmediatePropagation()')
    }
  }

  const handleHover = (hover: boolean) => {
    if (onHover) onHover(hover)
    if (hover) {
      lastHover.current = new Date()
    } else if (lastHover.current) {
      const t1 = new Date()
      const t2 = lastHover.current
      const diff = t1.getTime() - t2.getTime()
      lastHover.current = null
      if (diff > miniumHoverDurationForReporting) {
        const params = {
          action: gaAction || href || to,
          label: gaLabel,
          value: diff,
        }
        Analytics.hover(params)
      }
    }
  }

  if (disabled) variant = `${variant} disabled`

  const styles = getStyles(variant, { TouchableStyles })

  const divStyles = [
    styles.TouchableStyles,
    style,
  ]

  let TouchableElement: React.ElementType = 'div'
  if (linkTarget) {
    if (!to?.startsWith('http') && !href) {
      TouchableElement = GatsbyLink
    } else {
      TouchableElement = 'a'
    }
  }

  return (
    <TouchableElement
      ref={ref as any}
      to={to}
      href={href || to}
      css={divStyles}
      onClick={handleClick}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      {...otherProps}
    >
      {children}
    </TouchableElement>
  )
}

export const Touchable = React.forwardRef(TouchableCP)

export default Touchable
