/* eslint no-restricted-imports: 'off' */
import { Logger, Tools, getStyles } from '@/lib'
import { StaticQuery, graphql } from 'gatsby'
import { default as GatsbyImage } from 'gatsby-image/withIEPolyfill'
import { ImageStyles } from '@/app'
/** @jsx jsx */
import { jsx } from '@emotion/react'

const dynamicAssetPrefixes = ['http', 'https', 'blob']

export type ImageProps = {
  src?: string
  source?: string
  style?: React.CSSProperties
  img?: boolean
  alt?: string
  debug?: boolean
  objectFit?: string
  objectPosition?: string
  variant?: string
}

export const Image = (props: ImageProps) => {
  const source = Tools.parseSourceUrl(props)
  if (!props.alt) {
    Logger.log('missing alt property in img', { source })
  }
  const isStatic = props.img != true && !dynamicAssetPrefixes.filter(i => source?.startsWith?.(i)).length
  if (isStatic) {
    const styles = props?.style && props.style
    return (
      <StaticQuery
        query={staticFluidQuery}
        render={(data) => {
          const image = data.images.edges.find(n => n.node.relativePath.includes(source))
          if (!image) return null
          if (props.debug) {
            Logger.deb.green({ image, data, props })
          }
          return (
            <GatsbyImage
              {...props}
              objectFit={props.objectFit || styles?.objectFit || 'contain'}
              objectPosition={props?.objectPosition}
              style={null}
              css={styles}
              fluid={image.node.childImageSharp.fluid}
            />
          )
        }}
      />
    )
  } else {
    const styles = getStyles(props.variant, ImageStyles)
    return (
      <img
        {...props}
        img={null}
        style={null}
        src={source}
        css={[styles.image, props.style]}
      />
    )
  }
}

const staticFluidQuery = graphql`
query {
  images: allFile {
    edges {
      node {
        relativePath
        name
        childImageSharp {
          fluid(maxWidth: 1800, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`

export default Image
