/* eslint global-require: 'off', no-restricted-imports: 'off' */

import { Settings } from '@/app'
import { React, Analytics, Cookies, url as parseUrl, Logger } from '@/lib'
import wrapWithProvider from './rootWrapperProvider'
import { Session } from '@/actions'

export const wrapRootElement = wrapWithProvider

const asciiLogo = `
   ___          _        __                  
  / __/\___   __| | ___  / /  ___  __ _ _ __  
 / /  / _ \\ / _\` |/ _ \\\/ \/  \/ _ \\/ _\` | '_ \\ 
/ /__| (_) | (_| |  __/ /__|  __/ (_| | |_) |
\\____/\\___\/ \\__,_|\\__/\\____/\\___|\\__,_| .__/ 
                                      |_|    
`

export const onClientEntry = () => {
  console.log(asciiLogo)
  console.log(`Hello developer! Interested in working with us? Check https://codeleap.co.uk/careers/`)
  Logger.log('Gatsby launch')
  const isStaff = Cookies.get('Session.isStaff') == 'true'
  const noVisitorsAlertUrl = ['lost_found']
  const url = parseUrl()
  const preventVistorsAlert = noVisitorsAlertUrl.includes(url.pathname.replaceAll('/', ''))
  if (Settings.IS_DEVELOPMENT) {
    Logger.log('Development mode')
    if (Settings.WARN_WHY_DID_YOU_RENDER) {
      Logger.log({ WARN_WHY_DID_YOU_RENDER: Settings.WARN_WHY_DID_YOU_RENDER })
      const whyDidYouRender = require('@welldone-software/why-did-you-render')
      whyDidYouRender(React, {
        trackAllPureComponents: false,
        logOnDifferentValues: false,
        trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
      })
    }
    if (Settings.GOOGLE_ANALYTICS_ID && !isStaff) {
      Analytics.init(Settings.GOOGLE_ANALYTICS_ID, true, preventVistorsAlert) // NOTE test mode
    }
  } else {
    if (Settings.GOOGLE_ANALYTICS_ID && !isStaff) {
      Analytics.init(Settings.GOOGLE_ANALYTICS_ID, false, preventVistorsAlert)
    }
    Logger.log('Production build')
  }
  setTimeout(() => {
    Session.init()
  }, 1000)
}
