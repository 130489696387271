import { graphql } from 'gatsby'
import './post.css'
import Img from 'gatsby-image'
import { React, Image, View, Text, Page, SEO, Touchable, Link } from '@/lib'
import { Theme, TextStyles } from '@/app'

/** @jsx jsx */
import { jsx } from '@emotion/react'
import authorMeta from './authorMeta'

const Post = ({ data }) => {
  const meta = data.markdownRemark.frontmatter
  const author = authorMeta[meta.author]
  return (
    <Page fullWidth whiteBackground>
      <SEO article author={author.name} title={meta.title} description={meta.subtitle} image={meta.image.childImageSharp.fluid.src}/>
      <View style={styles.wrapper}>
        <View style={[styles.content, styles.postHeader]}>
          <View style={styles.backlinkWrapper}>
            <Touchable gaLabel={'BlogPost'} to={'/blog'}>
              <Text variant={'grey'} text={'← All posts'}/>
            </Touchable>
          </View>
          <Text style={styles.titleText} text={meta.title}/>
          <View style={styles.authorMetaRow}>
            <Image src={author.image} style={styles.avatar}/>
            <View>
              <Text style={styles.profileText} text={author.name}/>
              <View>
                <Text style={styles.previewInfoText} text={`${data.markdownRemark.timeToRead} min read`}/>
              </View>
            </View>
          </View>
        </View>
        {!meta.hideImageInPost && <Img fluid={meta.image.childImageSharp.fluid} style={styles.backgroundImage} alt={meta.title}/>}
        <View style={[styles.content, styles.postContentWrapper]}>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} css={styles.postContentText}/>
          <Text variant={'inline'}>
            Interested in learning more?
            {' '}
            <Link
              variant={'link'}
              gaLabel={'BlogPost'}
              gaAction={`/contact from ${meta.title}`}
              to={'/contact'}
              text={'Contact us'}/>
            {' '}or{' '}
            <Link
              variant={'link'}
              gaLabel={'BlogPost'}
              gaAction={`/get-a-quote from ${meta.title}`}
              to={'/get-a-quote'}
              text={'get a quote now!'} />
          </Text>
        </View>
      </View>
    </Page>
  )
}

export default Post

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        author
        date
        subtitle
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`

const styles = {
  wrapper: {
    marginBottom: Theme.spacing(4),
  },
  content: {
    paddingLeft: Theme.spacing(2),
    paddingRight: Theme.spacing(2),
  },
  postHeader: {
    maxWidth: Theme.values.maxTextContentWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: Theme.spacing(3),
    marginTop: Theme.values.headerMenuMargin,
  },
  postContentWrapper: {
    maxWidth: Theme.values.maxTextContentWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: Theme.spacing(3),
    '& a': {
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
      '&:hover': {
        color: 'blue',
      },
    },
  },
  profileText: {
    ...TextStyles.text.default,
    ...TextStyles.compatibility.h6,
    ...TextStyles.text.h6,
    marginBottom: Theme.spacing(0.5),
    flexDirection: 'column',
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: Theme.values.borderRadius.round,
    marginRight: Theme.spacing(2),
  },
  postContentText: {
    ...TextStyles.text.default,
    ...TextStyles.compatibility.p1,
    ...TextStyles.text.p1,
    '& p': {
      marginTop: 0,
      marginBottom: Theme.spacing(4),
      lineHeight: 2,
    },
    '& blockquote': {
      '& p': {
        marginBottom: Theme.spacing(2),
      },
      marginBottom: Theme.spacing(4),
    },
    '& li': {
      marginBottom: Theme.spacing(2),
      lineHeight: 2,
    },
    '& h1': {
      ...TextStyles.text.darkGrey,
    },
    '& h2': {
      ...TextStyles.text.darkGrey,
    },
    '& h3': {
      ...TextStyles.text.darkGrey,
    },
    '& h4': {
      ...TextStyles.text.darkGrey,
    },
    flexDirection: 'column',
  },
  backlinkWrapper: {
    display: 'flex',
    cursor: 'pointer',
  },
  titleText: {
    ...TextStyles.text.default,
    ...TextStyles.compatibility.h3,
    ...TextStyles.text.h3,
    ...TextStyles.text.primary,
    marginTop: Theme.spacing(2),
  },
  authorMetaRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Theme.spacing(2),
  },
  profileImage: {
    marginRight: Theme.spacing(1),
  },
  previewInfoText: {
    fontSize: 14,
    color: '#777',
  },
  backgroundImage: {
    width: '100vw',
    height: 400,
    objectFit: 'cover',
  },
}
