// import { Theme } from '@/app'

const image = {
  default: {
    // backgroundColor: '#eee',
  },
}

export default {
  image,
}
