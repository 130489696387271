import { Assets } from '@/assets'

export const getButtonData = ({ service }) => {

  return [
    { text: 'HOME', to: '/' },
    {
      text: 'SERVICES',
      to: '/#services',
      items: [
        {
          title: 'Mobile apps',
          description: 'Cross-platform mobile apps for seamless user experiences',
          icon: Assets.Icons.Services.Web,
          to: '/services/mobile',
        },
        {
          title: 'Next-gen',
          description: 'Innovative solutions to bring your product to the next level',
          icon: Assets.Icons.Services.NextGen,
          to: '/services/next-gen',
        },
        {
          title: 'Websites',
          description: 'From simple websites to data-heavy integrated web solutions',
          icon: Assets.Icons.Services.Web,
          to: '/services/web',
        },
        {
          title: 'AI solutions',
          description: 'Enhance business outcomes and drive innovation',
          icon: Assets.Icons.Services.AI,
          to: '/services/ai',
        },

      ],
    },
    { text: 'PORTFOLIO', to: service ? `/services/${service}/#portfolio` : '/#portfolio' },
    { text: 'PRICING', to: service ? `/services/${service}/#pricing` : '/#pricing' },
    { text: 'CONTACT', to: service ? `/services/${service}/#contact` : '/#contact' },
    { text: 'BLOG', to: '/blog/' },
    { text: 'GET A QUOTE', to: '/get-a-quote' },
  ]

}
