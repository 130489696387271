import { Text, React, View, Touchable, AnalyticsView } from '@/lib'
import { Theme } from '@/app'
import posed from 'react-pose'
import { useState } from '@codeleap/common'
import { FaqItemProps } from '@/pages/services/_types'

type ItemProps = FaqItemProps & { index: number }

export const FAQItem = (props: ItemProps) => {

  const { title, content, index } = props
  const [expanded, setExpanded] = useState(false)

  const handleChange = () => {
    setExpanded(!expanded)
  }

  const renderParagraph = (txt, idx) => {
    return <Text key={idx} variant={'marginTop:2 p1 inline'}>{txt}</Text>
  }

  const number = index >= 9 ? index + 1 : `0${index + 1}`

  return (
    <View style={styles.wrapper}>
      {index > 0 && <View style={styles.separator}/>}
      <Touchable onPress={handleChange} gaLabel={'FAQ'} gaAction={`${title} ${expanded ? 'opened' : 'closed'}`}>
        <View style={styles.innerWrapper}>
          <View style={styles.summary}>
            <View style={styles.indexWrapper}>
              <Text variant={'h6 thin'} text={number}/>
            </View>
            <Text variant={'h6 bold'} text={title}/>
          </View>
          <ExpandingSection pose={expanded ? 'show' : 'init'} style={styles.expandingSection}>
            <AnalyticsView gaLabel={'FAQ'} gaAction={title}>
              {content.map((i, k) => renderParagraph(i, k))}
            </AnalyticsView>
          </ExpandingSection>
        </View>
      </Touchable>
    </View>
  )
}

export default FAQItem

const transition = {
  duration: 250,
}

const ExpandingSection = posed.div({
  init: {
    height: 0,
    marginTop: 'inherit',
    transition,
  },
  show: {
    height: 'auto',
    transition,
  },
})

const styles = {
  wrapper: {
    marginBottom: Theme.spacing(3),
  },
  innerWrapper: {
    marginTop: Theme.spacing(0.5),
    marginBottom: Theme.spacing(0.5),
  },
  summary: {
    ...Theme.flex,
    alignItems: 'center',
  },
  indexWrapper: {
    minWidth: Theme.spacing(8),
  },
  expandingSection: {
    overflow: 'hidden',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#777',
    marginBottom: Theme.spacing(3),
  },
}
