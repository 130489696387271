import { Assets } from '@/assets'
import { React, Image, View } from '@/lib'

const astronaut = Assets.Misc['nasa-astronaut-square']
const astronautTransparent = Assets.Misc['nasa-astronaut-square-transparent']

type NasaAstronautProps = {
  transparent?: boolean
}

export const NasaAstronaut = (props: NasaAstronautProps) => {
  const { transparent = true } = props
  const file = transparent ? astronautTransparent : astronaut
  return (
    <View>
      <Image alt={'CodeLeap - Nasa Astronaut'} src={file} style={styles.astronaut}/>
    </View>
  )
}

export default NasaAstronaut

const styles = {
  astronaut: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 300,
    height: 300,
    objectFit: 'cover',
  },
}
