import { Theme } from '@/app'

export const styles = {
  deviceWrapper: {
    marginRight: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      marginRight: 0,
    },
  },
  demoRowWrapper: {
    ...Theme.flex,
    ...Theme.center,
    flex: 1,
    width: '100%',
    position: 'relative',
  },
  demoRow: {
    ...Theme.row,
    position: 'absolute',
    left: 0,
    [Theme.media.down('mid')]: {
      position: 'unset',
      ...Theme.center,
      flexDirection: 'column',
    },
  },
  demoRowLeft: {
    position: 'absolute',
    left: 'unset',
    right: 0,
    [Theme.media.down('mid')]: {
      position: 'unset',
    },
  },
}
