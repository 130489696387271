import { AppDescription } from './Description'
import { AppRow } from './Row'

export * from './Description'
export * from './Row'

export const CardComponents = {
  AppRow,
  AppDescription,
}

export default CardComponents
