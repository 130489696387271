/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Image, Logger } from '@/lib'

export const Icon = ({ path, name = '', style = {}, log, ...otherProps }) => {

  const { size: stylesSize, width, height, color: stylesColor, ...otherStyles } = (otherProps?.forceStyle ?? style)
  const { size: propsSize, color: propsColor } = otherProps

  const styles = {
    ...otherStyles,
    height: !propsSize ? stylesSize ?? height : propsSize,
    width: !propsSize ? stylesSize ?? width : propsSize,
    color: propsColor ?? stylesColor,
  }

  if (typeof path == 'object' && Boolean(path?.default)) {
    path = path.default
  }

  if (typeof path === 'function') {
    if (log) {
      Logger.log('Icon style for ' + name, otherStyles)
    }

    const forceStroke = {
      '& > *': {
        stroke: `${styles.color} !important`,
      },
    }

    const Component = path

    return <Component
      size={propsSize}
      color={propsColor}
      {...otherProps}
      css={[styles, forceStroke]}
    />
  }

  const appliedStyles = {
    height: styles.height,
    width: styles.width,
    fontSize: styles.height || styles.width,
    tintColor: styles.color || null,
    color: styles.color || null,
  }

  if (log) {
    Logger.log('Icon style for ' + name, appliedStyles)
  }

  return <Image source={path} css={appliedStyles}/>

}

export default Icon

